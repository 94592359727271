import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import RestoreIcon from '@mui/icons-material/Restore';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import StopIcon from '@mui/icons-material/Stop';
import { Box, IconButton, List, ListItem, ListItemText, Paper, Stack, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import { useUpdateParcelMutation } from 'api/parcel';
import BaseDialog from 'components/common/BaseDialog';
import ConfirmDialog from 'components/common/ConfirmDialog';
import DataForm from 'components/common/form/rjsf/DataForm';
import useParcelFormData from 'components/common/form/rjsf/useParcelFormData';
import { getTriggers, useEvalTriggerConditions, useTriggers } from 'components/common/hooks/useTriggers';
import { LoadingButton } from 'components/common/styled';
import { findChildParcelsInOrder } from 'helpers/utils';

export const PARCEL_TRIGGER_ICON_MAP = {
    add: <AddIcon />,
    play: <PlayArrowIcon />,
    stop: <StopIcon />,
    pause: <PauseIcon />,
    restart: <RestartAltIcon />,
    done: <DoneIcon />,
    generate: <NoteAddIcon />,
    cancel: <CloseIcon />,
    restore: <RestoreIcon />,
    event_busy: <EventBusyIcon />,
    event_repeat: <EventRepeatIcon />,
    more_time: <MoreTimeIcon />,
    edit_calendar: <EditCalendarIcon />,
    schedule_send: <ScheduleSendIcon />,
    default: <DoneIcon />,
};

export function useParcelTriggerAction({ trigger, parcel }) {
    const [updateParcel, { isLoading }] = useUpdateParcelMutation();

    const handleUpdate = (triggerData) => {
        // NOTE: currently we are ignoring the trigger type & action keys - assume update action
        const updateData = {};
        _.forEach(trigger.payload, (updateValue, updatePath) => {
            if (typeof updateValue === 'string') {
                // Handle simple string updates
                _.set(updateData, updatePath, updateValue);
                return; // continue
            }

            const pathData = { ...updateValue };

            // Pass in collected data from trigger form
            if (updatePath === 'trigger' && triggerData) {
                pathData['data'] = triggerData;
            }

            _.set(updateData, updatePath, pathData);
        });

        return submitUpdate(updateData);
    };

    const submitUpdate = async (updateData) => {
        const updatePayload = {
            parcelId: parcel.id,
            parcelData: updateData,
        };

        const { data } = await updateParcel(updatePayload);
        if (data) {
            return true;
        } else {
            console.warn(`Failed to submit update for trigger`);
            return false;
        }
    };

    return [handleUpdate, { isLoading }];
}

export const ParcelTriggerForm = ({ trigger, parcel, formContext = {}, handleSuccess = null }) => {
    const [handleUpdate, { isLoading }] = useParcelTriggerAction({ trigger, parcel });
    const {
        data: formData,
        setData: setFormData,
        schema: formSchema,
        isLoading: isLoadingFormData,
        isError: isLoadingFormError,
        isReady: isFormReady,
    } = useParcelFormData(parcel.order_id, parcel.id, _.get(trigger, 'form'));

    const handleSubmit = () => {
        handleUpdate(formData).then((success) => {
            if (success) {
                handleSuccess();
            }
        });
    };

    const handleFormChange = (data) => {
        setFormData({ ...data.formData });
    };

    if (!trigger) {
        return null;
    }

    if (isLoadingFormError) {
        return <div>Error loading form data</div>;
    }

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Stack spacing={1}>
                <Typography variant="h5" color="text.primary">
                    {trigger.text}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    {trigger.description}
                </Typography>
            </Stack>

            {isLoadingFormData || !isFormReady ? (
                <div>Loading ...</div>
            ) : (
                <DataForm
                    data={formData || {}}
                    schema={formSchema || {}}
                    onChange={handleFormChange}
                    onSubmit={handleSubmit}
                    formContext={formContext}
                >
                    <Stack direction="row-reverse" spacing={2} sx={{ pt: 6 }}>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            loading={isLoading}
                            disableElevation
                            sx={{
                                minWidth: '160px',
                            }}
                        >
                            Submit
                        </LoadingButton>
                    </Stack>
                </DataForm>
            )}
        </Stack>
    );
};

ParcelTriggerForm.propTypes = {
    parcel: PropTypes.object.isRequired,
    trigger: PropTypes.object.isRequired,
    formContext: PropTypes.object, // TODO
    handleSuccess: PropTypes.func,
};

export const ConfirmTriggerIconButton = ({
    parcel,
    trigger,
    isActive,
    icon = null,
    buttonProps = {},
    dialogProps = {},
    tooltipProps = {},
}) => {
    const [handleUpdate, { isLoading }] = useParcelTriggerAction({ trigger, parcel });

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    const defaultTooltipProps = {
        title: trigger?.text,
        enterDelay: 300,
        placement: 'bottom',
    };

    // TODO - change if trigger has form

    const defaultDialogProps = {
        title: `${_.startCase(trigger?.text)}: ${parcel?.name}`,
        subTitle: null,
        body: `Are you sure you want to do this?`, // TODO
    };

    const defaultButtonProps = {
        size: 'small',
    };

    if (!isActive || !trigger) {
        return null;
    }

    return (
        <Box>
            <Tooltip {...defaultTooltipProps} {...tooltipProps}>
                <span>
                    <IconButton
                        onClick={() => setShowConfirmDialog(true)}
                        disabled={isLoading}
                        {...defaultButtonProps}
                        {...buttonProps}
                    >
                        {icon || PARCEL_TRIGGER_ICON_MAP[_.get(trigger, 'button.icon', 'default')]}
                    </IconButton>
                </span>
            </Tooltip>

            <ConfirmDialog
                open={!!showConfirmDialog}
                handleConfirm={() => handleUpdate()}
                handleClose={() => setShowConfirmDialog(false)}
                {...defaultDialogProps}
                {...dialogProps}
            />
        </Box>
    );
};

ConfirmTriggerIconButton.propTypes = {
    parcel: PropTypes.object.isRequired,
    trigger: PropTypes.object.isRequired,
    isActive: PropTypes.bool,
    icon: PropTypes.node,
    buttonProps: PropTypes.object,
    dialogProps: PropTypes.object,
    tooltipProps: PropTypes.object,
};

export const FormTriggerIconButton = ({
    parcel,
    trigger,
    isActive,
    icon = null,
    buttonProps = {},
    dialogProps = {},
    tooltipProps = {},
    formContext = {},
}) => {
    const [showFormDialog, setShowFormDialog] = useState(false);

    const defaultTooltipProps = {
        title: trigger?.text,
        enterDelay: 300,
        placement: 'bottom',
    };

    const defaultButtonProps = {
        size: 'small',
    };

    if (!isActive || !trigger) {
        return null;
    }

    return (
        <Box>
            <Tooltip {...defaultTooltipProps} {...tooltipProps}>
                <span>
                    <IconButton onClick={() => setShowFormDialog(true)} {...defaultButtonProps} {...buttonProps}>
                        {icon || PARCEL_TRIGGER_ICON_MAP[_.get(trigger, 'button.icon', 'default')]}
                    </IconButton>
                </span>
            </Tooltip>

            <BaseDialog
                open={!!showFormDialog}
                setOpen={setShowFormDialog}
                DialogProps={{
                    maxWidth: 'sm',
                    fullWidth: true,
                }}
            >
                <Stack spacing={2} sx={{ pt: 1, width: '100%' }}>
                    <ParcelTriggerForm
                        parcel={parcel}
                        trigger={trigger}
                        formContext={formContext}
                        handleSuccess={() => setShowFormDialog(false)}
                    />
                </Stack>
            </BaseDialog>
        </Box>
    );
};

FormTriggerIconButton.propTypes = {
    parcel: PropTypes.object.isRequired,
    trigger: PropTypes.object.isRequired,
    isActive: PropTypes.bool,
    icon: PropTypes.node,
    buttonProps: PropTypes.object,
    dialogProps: PropTypes.object,
    tooltipProps: PropTypes.object,
    formContext: PropTypes.object, // TODO
};

export const DynamicTriggerIconButton = ({ trigger, ...rest }) => {
    // Either shows the confirm trigger dialog or the trigger form dialog
    // Determine if there is form data in trigger
    const hasFormData = _.get(trigger, 'form', null);
    if (hasFormData) {
        // Show form dialog button
        return <FormTriggerIconButton trigger={trigger} {...rest} />;
    }

    // Show confirm dialog button
    return <ConfirmTriggerIconButton trigger={trigger} {...rest} />;
};

DynamicTriggerIconButton.propTypes = {
    parcel: PropTypes.object.isRequired,
    trigger: PropTypes.object.isRequired,
    isActive: PropTypes.bool,
    icon: PropTypes.node,
    buttonProps: PropTypes.object,
    dialogProps: PropTypes.object,
    tooltipProps: PropTypes.object,
};

export const ParcelTriggerIconButton = ({ parcel, trigger, icon = null, buttonProps = {}, showTooltip = false }) => {
    const [handleUpdate, { isLoading }] = useParcelTriggerAction({ trigger, parcel });

    const buttonIcon = icon || PARCEL_TRIGGER_ICON_MAP[_.get(trigger, 'button.icon', 'default')];
    const buttonVariant = buttonProps.variant || _.get(trigger, 'button.variant', 'contained');
    const buttonColor = buttonProps.color || _.get(trigger, 'button.color', 'default');
    const buttonTooltip = showTooltip ? trigger.text : '';

    return (
        <Tooltip title={buttonTooltip} placement="bottom" enterDelay={300}>
            <LoadingButton
                color={buttonColor}
                variant={buttonVariant}
                onClick={() => handleUpdate()}
                loading={isLoading}
                disableElevation
                {...buttonProps}
                sx={{
                    padding: (theme) => theme.spacing(1),
                    minWidth: (theme) => theme.spacing(3),
                    ..._.get(buttonProps, 'sx', {}),
                }}
            >
                {buttonIcon}
            </LoadingButton>
        </Tooltip>
    );
};

ParcelTriggerIconButton.propTypes = {
    trigger: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
    icon: PropTypes.node,
    buttonProps: PropTypes.object,
    showTooltip: PropTypes.bool,
};

export const ParcelTriggerButton = ({ trigger, parcel, buttonProps = {} }) => {
    const [handleUpdate, { isLoading }] = useParcelTriggerAction({ trigger, parcel });
    const showTrigger = useEvalTriggerConditions({ dataEntity: parcel, trigger });

    if (!showTrigger) {
        return null;
    }

    return (
        <LoadingButton
            variant="contained"
            onClick={() => handleUpdate()}
            loading={isLoading}
            disableElevation
            {...buttonProps}
        >
            {trigger.text}
        </LoadingButton>
    );
};

ParcelTriggerButton.propTypes = {
    trigger: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
    buttonProps: PropTypes.object,
};

function TriggerListItem({ parcel, trigger }) {
    const showTrigger = useEvalTriggerConditions({ dataEntity: parcel, trigger });

    if (!showTrigger) {
        return null;
    }

    return (
        <ListItem
            disableGutters
            secondaryAction={
                <ParcelTriggerIconButton
                    parcel={parcel}
                    trigger={trigger}
                    buttonProps={{ color: 'default', variant: 'outlined' }}
                />
            }
        >
            <ListItemText primary={trigger.text} secondary={trigger.description} />
        </ListItem>
    );
}

const TriggerList = ({ parcel }) => {
    const { triggers, activeTriggers } = useTriggers(parcel);

    if (!triggers || triggers.length === 0 || activeTriggers.length === 0) {
        return null;
    }

    return (
        <List disablePadding spacing={2}>
            {_.map(triggers, (trigger, triggerKey) => {
                return <TriggerListItem key={triggerKey} parcel={parcel} trigger={trigger} />;
            })}
        </List>
    );
};

const ParcelTriggerList = ({ order, parcel }) => {
    const childParcels = useMemo(() => {
        if (order) {
            return findChildParcelsInOrder(order, parcel.id);
        }

        return [];
    }, [order, parcel.id]);

    const parcelActiveTriggerMap = useMemo(() => {
        // Check the main parcel and each child parcel for active triggers
        const activeTriggerMap = {};

        _.forEach([parcel, ...childParcels], (parcelEntity) => {
            const { activeTriggers } = getTriggers(parcelEntity);
            if (activeTriggers && activeTriggers.length > 0) {
                activeTriggerMap[parcelEntity.id] = activeTriggers;
            }
        });

        return activeTriggerMap;
    });

    return (
        <Paper variant="outlined" sx={{ padding: (theme) => theme.spacing(3) }}>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{ paddingBottom: (theme) => theme.spacing(3) }}
            >
                <Typography variant="sectionHeader">Triggers</Typography>
            </Stack>

            {_.keys(parcelActiveTriggerMap).length === 0 && (
                <Typography variant="body1" color="text.secondary" fontStyle="italic">
                    No active triggers
                </Typography>
            )}

            <TriggerList parcel={parcel} />

            {_.map(childParcels, (childParcel) => (
                <TriggerList key={childParcel.id} parcel={childParcel} />
            ))}
        </Paper>
    );
};

ParcelTriggerList.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
};

export default ParcelTriggerList;
