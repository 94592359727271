import { Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { useGetOrderMembersQuery } from 'api/order';
import useAssignmentListData from 'components/common/hooks/useAssignmentListData';
import { ASSIGNMENT_MEMBER_PARTY } from 'helpers/constants';
import NewAssignmentForm from './NewAssignmentForm';

const CreateAssignment = ({ orderId, handleSubmit }) => {
    const {
        order,
        activeAssignmentContractParcels,
        lastActiveAssignmentContractParcel,
        isError,
        isLoading,
        isFetching,
    } = useAssignmentListData(orderId);

    const { data: orderMembers, isError: orderMembersError, isLoading: orderMembersLoading } = useGetOrderMembersQuery(
        orderId
    );

    const availableOrderMembers = useMemo(() => {
        const excludeOrderMemberIds = [];
        _.forEach(activeAssignmentContractParcels, (activeAssignmentContractParcel) => {
            _.forEach(activeAssignmentContractParcel.members, (assignmentMember) => {
                if (activeAssignmentContractParcel.id === lastActiveAssignmentContractParcel.id) {
                    // Only exclude assignor party members from the last active assignment contract
                    if (assignmentMember.party === ASSIGNMENT_MEMBER_PARTY.assignor) {
                        excludeOrderMemberIds.push(assignmentMember.order_member_id);
                    }
                } else {
                    // Exclude all members from other previous active assignment contracts
                    excludeOrderMemberIds.push(assignmentMember.order_member_id);
                }
            });
        });

        console.log('excludeOrderMemberIds', excludeOrderMemberIds);

        return _.filter(orderMembers, (orderMember) => !excludeOrderMemberIds.includes(orderMember.id));
    }, [activeAssignmentContractParcels, lastActiveAssignmentContractParcel, orderMembers]);

    console.log('availableOrderMembers', availableOrderMembers);

    return (
        <Stack spacing={4} sx={{ width: '480px' }}>
            <Stack direction="column" spacing={2}>
                <Typography variant="h4">Add Assignment</Typography>
            </Stack>

            <NewAssignmentForm
                order={order}
                associatedAssignmentContractParcel={lastActiveAssignmentContractParcel}
                orderMembers={availableOrderMembers}
                handleSubmit={handleSubmit}
            />
        </Stack>
    );
};

CreateAssignment.propTypes = {
    orderId: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default CreateAssignment;
