import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { useUpdateActionMutation } from 'api/action';
import { useGetAssignmentMembersQuery } from 'api/assignment';
import { useGetLienStatusesQuery } from 'api/lien';
import { useGetOrderMembersQuery } from 'api/order';
import ActionButton from 'components/Action/ActionButton';
import ActionModal from 'components/Action/ActionModal';
import { AssignmentMemberRoleSnapshot } from 'components/AssignmentList/components/AssignmentContractListItem';
import { Button } from 'components/common/styled';
import { generateOrderMemberIdMap } from 'components/Order/Members/utils';
import {
    ACTION_STATUS,
    FUNDING_PARCEL_ACTION_TYPE,
    PARCEL_STATUS,
    PARCEL_TYPE,
    POST_CLOSING_PARCEL_ACTION_TYPE,
    TRANSACTION_TYPE,
} from 'helpers/constants';
import {
    USDollar,
    findChildParcelsInOrder,
    formatAddress,
    formatISODate,
    getRelevantClosingChildParcels,
} from 'helpers/utils';
import { parcelDueDateBackgroundColor } from './ParcelCardDueDate';

const IGNORE_PARCEL_DUE_DATE = [
    PARCEL_TYPE.balancing,
    PARCEL_TYPE.cda,
    PARCEL_TYPE.closing_buyer,
    PARCEL_TYPE.closing_borrower,
    PARCEL_TYPE.closing_seller,
    PARCEL_TYPE.funding,
    PARCEL_TYPE.post_closing,
    PARCEL_TYPE.keys,
];

const ParcelContentNotification = ({ text }) => {
    return (
        <Typography
            // fontSize="12px"
            // fontWeight="400"
            variant="body1"
            fontStyle="italic"
            // textTransform="uppercase"
            textAlign="center"
            color="text.secondary"
        >
            {text}
        </Typography>
    );
};

const GenericContentLabel = ({ label }) => {
    return (
        <Typography fontSize="12px" fontWeight="400" color="text.secondary">
            {label}:
        </Typography>
    );
};

const GenericContentValue = ({ value }) => {
    return (
        <Typography
            fontSize="12px"
            fontWeight="400"
            sx={{
                whiteSpace: 'pre-line',
                textAlign: 'right',
            }}
        >
            {value}
        </Typography>
    );
};

const GenericContentRow = ({ label, value }) => {
    return (
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
            <GenericContentLabel label={label} />
            <GenericContentValue value={value} />
        </Stack>
    );
};

const ParcelDueDateRow = ({ parcel }) => {
    if (!parcel.required_complete_date || _.includes(IGNORE_PARCEL_DUE_DATE, parcel.type)) {
        return null;
    }

    return <GenericContentRow label="Due" value={formatISODate(parcel.required_complete_date)} />;
};

const ETAContentRow = ({ parcel, label, value }) => {
    return (
        <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
            sx={{
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: (theme) => parcelDueDateBackgroundColor(parcel, theme),
                borderRadius: (theme) => theme.spacing(0.5),
                padding: (theme) => theme.spacing(0.5, 1),
            }}
        >
            <GenericContentLabel label={label} />
            <GenericContentValue value={value} />
        </Stack>
    );
};

const GenericOrderedParcelContent = ({ order, parcel, prefix = null }) => {
    const additionalData = parcel.additional_data || {};
    const orderedDate = additionalData[`${prefix ? prefix : parcel.type}_ordered_date`];
    const receivedDate = additionalData[`${prefix ? prefix : parcel.type}_received_date`];
    const etaDate = additionalData[`${prefix ? prefix : parcel.type}_eta`];

    // TODO check for update_requested
    // if update_requested is true, check if orderedDate is after receivedDate
    // if it is show "Update Ordered" instead of "Ordered" and hide "Received"
    // if it is not, show all original dates and include "Update Requested"

    // if (parcel.current_status.status === PARCEL_STATUS.not_started) {
    //     return (
    //         <Stack spacing={1}>
    //             <Typography fontSize="12px" fontWeight="500" textTransform="uppercase" textAlign="center" color="text.secondary">Survey Not Started</Typography>
    //         </Stack>
    //     )
    // }

    return (
        <Stack spacing={1}>
            {orderedDate && <GenericContentRow label="Ordered" value={formatISODate(orderedDate)} />}
            {receivedDate && <GenericContentRow label="Received" value={formatISODate(receivedDate)} />}
            {etaDate && !receivedDate && <GenericContentRow label="ETA" value={formatISODate(etaDate)} />}
            {/* {etaDate && !receivedDate && <ETAContentRow parcel={parcel} label="ETA" value={formatISODate(etaDate)} />} */}
        </Stack>
    );
};

const EarnestMoneyParcelContent = ({ order, parcel }) => {
    const rawEarnestMoney = _.get(parcel, 'additional_data.earnest_money_amount');
    const earnestMoney = rawEarnestMoney > 0 ? USDollar.format(rawEarnestMoney) : null;

    const rawOptionFee = _.get(parcel, 'additional_data.option_fee_amount');
    const optionFee = rawOptionFee > 0 ? USDollar.format(rawOptionFee) : null;

    const optionPeriodEndDate = _.get(parcel, 'additional_data.option_period_end_date');
    const formattedOptionPeriodEndDate = optionPeriodEndDate
        ? formatISODate(parcel.additional_data.option_period_end_date)
        : '—';

    return (
        <Stack spacing={1}>
            <ParcelDueDateRow parcel={parcel} />
            <GenericContentRow label="Earnest Money" value={earnestMoney ? earnestMoney : '—'} />
            <GenericContentRow label="Option Fee" value={optionFee ? optionFee : '—'} />
            <Stack direction="row" spacing={1} alignItems="flex-start" justifyContent="space-between">
                <GenericContentLabel label="Option Fee Period" />
                <Stack alignItems="flex-end">
                    <GenericContentValue value={formattedOptionPeriodEndDate} />
                    <GenericContentValue value={`(${parcel.additional_data.option_period} Days)`} />
                </Stack>
            </Stack>

            {parcel?.current_status.status === PARCEL_STATUS.complete && (
                <GenericContentRow label="Completed" value={formatISODate(parcel.current_status.created_datetime)} />
            )}
        </Stack>
    );
};

const AssignmentContractParcelContent = ({ order, parcel, orderMembers }) => {
    // TODO package this logic together in hook partials
    const { data: assignmentMembers } = useGetAssignmentMembersQuery({ orderId: order.id, assignmentId: parcel.id });

    const orderMemberMap = useMemo(() => generateOrderMemberIdMap(orderMembers || []), [orderMembers]);

    const rawEarnestMoney = _.get(parcel, 'additional_data.earnest_money_amount');
    const earnestMoney = rawEarnestMoney > 0 ? USDollar.format(rawEarnestMoney) : null;

    const earnestMoneyDueDate = _.get(parcel, 'additional_data.earnest_money_due_date');
    const formattedEarnestMoneyDueDate = earnestMoneyDueDate ? formatISODate(earnestMoneyDueDate) : '—';

    const earnestMoneyReceivedDate = _.get(parcel, 'additional_data.earnest_money_received_date');
    const formattedEarnestMoneyReceivedDate = earnestMoneyReceivedDate ? formatISODate(earnestMoneyReceivedDate) : '—';

    const latestActiveActionId = _.get(parcel, 'active_actions[0]');
    const latestActiveAction = latestActiveActionId ? parcel.action_map[latestActiveActionId] : null;

    return (
        <Stack spacing={1} sx={{ width: '100%' }}>
            <Stack sx={{ marginBottom: '8px !important' }}>
                <Button
                    component={Link}
                    to={`/order/${order.id}/assignment/${parcel.id}`}
                    variant="text"
                    color="default"
                    fullWidth
                    sx={{
                        borderRadius: (theme) => theme.spacing(1),
                    }}
                >
                    <AssignmentMemberRoleSnapshot
                        assignmentMembers={assignmentMembers}
                        orderMemberMap={orderMemberMap}
                        // invertAlignment={true}
                    />
                </Button>
            </Stack>

            <ParcelDueDateRow parcel={parcel} />
            <GenericContentRow label="Earnest Money" value={earnestMoney ? earnestMoney : '—'} />

            {!earnestMoneyReceivedDate && (
                <GenericContentRow label="Earnest Money Due Date" value={formattedEarnestMoneyDueDate} />
            )}

            {!earnestMoneyReceivedDate && (
                <GenericContentRow label="Earnest Money Received Date" value={formattedEarnestMoneyReceivedDate} />
            )}

            {parcel?.current_status.status === PARCEL_STATUS.complete && (
                <GenericContentRow label="Completed" value={formatISODate(parcel.current_status.created_datetime)} />
            )}

            {parcel?.current_status.status === PARCEL_STATUS.canceled && (
                <GenericContentRow label="Cancelled" value={formatISODate(parcel.current_status.created_datetime)} />
            )}

            {latestActiveAction && (
                <Stack spacing={3}>
                    <Box />
                    <ActionButton
                        action={latestActiveAction}
                        orderId={order.id}
                        type="contained"
                        loadingButtonProps={{
                            fullWidth: true,
                        }}
                    />
                </Stack>
            )}
        </Stack>
    );
};

const AssignmentParcelContent = ({ order, parcel }) => {
    // TODO get some data directly from parcel's additional data
    const { data: orderMembers, isError: orderMembersError, isLoading: orderMembersLoading } = useGetOrderMembersQuery(
        order.id
    );

    // Get all child parcels
    const assignmentContracts = findChildParcelsInOrder(order, parcel.id);

    return (
        <Stack spacing={1}>
            <ParcelDueDateRow parcel={parcel} />

            {assignmentContracts.length === 0 && <ParcelContentNotification text="Not Currently Assigned" />}

            <Stack direction="column" spacing={3} alignItems="flex-start" divider={<Divider sx={{ width: '100%' }} />}>
                {_.map(assignmentContracts, (assignmentContract) => {
                    if (assignmentContract.current_status.status === PARCEL_STATUS.canceled) {
                        return null;
                    }

                    return (
                        <AssignmentContractParcelContent
                            key={assignmentContract.id}
                            order={order}
                            parcel={assignmentContract}
                            orderMembers={orderMembers}
                        />
                    );
                })}
            </Stack>

            {parcel?.current_status.status === PARCEL_STATUS.complete && (
                <GenericContentRow
                    label="All Completed"
                    value={formatISODate(parcel.current_status.created_datetime)}
                />
            )}
        </Stack>
    );
};

const TaxCertParcelContent = ({ order, parcel }) => {
    const [overdueTax, setOverdueTax] = useState(null);
    const [priorYearTax, setPriorYearTax] = useState(null);

    useEffect(() => {
        const overdue = parcel.additional_data.overdue_tax_amount;
        const priorYear = parcel.additional_data.prior_year_tax_amount;
        setOverdueTax(overdue && overdue > 0 ? USDollar.format(overdue) : null);
        setPriorYearTax(priorYear && priorYear > 0 ? USDollar.format(priorYear) : null);
    }, [parcel]);

    return (
        <Stack spacing={1}>
            <ParcelDueDateRow parcel={parcel} />
            <GenericOrderedParcelContent order={order} parcel={parcel} />

            {parcel?.current_status.status === PARCEL_STATUS.complete && (
                <>
                    <GenericContentRow label="Overdue" value={overdueTax ? overdueTax : '—'} />
                    <GenericContentRow label="Prior Year" value={priorYearTax ? priorYearTax : '—'} />
                </>
            )}
        </Stack>
    );
};

const HOAParcelContent = ({ order, parcel }) => {
    const hoaRequired = _.get(parcel, 'additional_data.hoa_required');

    if (parcel?.current_status.status === PARCEL_STATUS.waiting_for_tax_cert) {
        return (
            <Stack spacing={1}>
                <ParcelContentNotification text="Waiting for Tax Cert" />
            </Stack>
        );
    }

    if (parcel?.current_status.status === PARCEL_STATUS.complete && hoaRequired === false) {
        return (
            <Stack spacing={1}>
                <ParcelContentNotification text="Not Required" />
            </Stack>
        );
    }

    return (
        <Stack spacing={1}>
            <ParcelDueDateRow parcel={parcel} />
            <GenericOrderedParcelContent order={order} parcel={parcel} />

            {parcel?.current_status.status === PARCEL_STATUS.complete && (
                <GenericContentRow label="Completed" value={formatISODate(parcel.current_status.created_datetime)} />
            )}
        </Stack>
    );
};

const TitleExamParcelContent = ({ order, parcel }) => {
    const title_commitment_date = _.get(parcel, 'additional_data.title_commitment_date');
    const objection_date = _.get(parcel, 'additional_data.objection_date');
    const title_request_date = _.get(parcel, 'additional_data.title_request_date');
    return (
        <Stack spacing={1}>
            <ParcelDueDateRow parcel={parcel} />
            <GenericOrderedParcelContent order={order} parcel={parcel} />

            {_.includes([PARCEL_STATUS.received, PARCEL_STATUS.complete], parcel?.current_status.status) && (
                <>
                    <GenericContentRow
                        label="Title Commitment"
                        value={title_commitment_date ? formatISODate(title_commitment_date) : '—'}
                    />
                    <GenericContentRow label="Objection" value={objection_date ? formatISODate(objection_date) : '—'} />
                </>
            )}
            {parcel?.current_status.status === PARCEL_STATUS.complete && (
                <GenericContentRow
                    label="Title Request"
                    value={title_request_date ? formatISODate(title_request_date) : '—'}
                />
            )}
        </Stack>
    );
};

const TitleParcelContent = ({ order, parcel }) => {
    const {
        data: lienStatusMap,
        isError: lienStatusMapError,
        isLoading: lienStatusMapLoading,
    } = useGetLienStatusesQuery();

    const [titleCount, setTitleCount] = useState(0);
    const [clearedCount, setClearedCount] = useState(0);

    useEffect(() => {
        const terminalLienStatuses = _.get(lienStatusMap, '_terminal', []);
        const newTitleCount = order.liens.length;
        const newClearedCount = _.filter(order.liens, (lien) => {
            return _.includes(terminalLienStatuses, lien.status);
        }).length;
        setTitleCount(newTitleCount);
        setClearedCount(newClearedCount);
    }, [order, lienStatusMap]);

    return (
        <Stack spacing={1}>
            <ParcelDueDateRow parcel={parcel} />
            {titleCount === 0 ? (
                <ParcelContentNotification text="No Title Issues Recorded" />
            ) : (
                <GenericContentRow label="Title Issues Cleared" value={`${clearedCount} / ${titleCount}`} />
            )}

            {parcel?.current_status.status === PARCEL_STATUS.complete && (
                <GenericContentRow label="Completed" value={formatISODate(parcel.current_status.created_datetime)} />
            )}
        </Stack>
    );
};

const HomeWarrantyParcelContent = ({ order, parcel }) => {
    const [credit, setCredit] = useState(null);

    const homeWarrantyRequired = _.get(parcel, 'additional_data.home_warranty_required');

    // NOTE: currently the home warranty parcel only tracks ordered date, not received or eta

    useEffect(() => {
        const additionalData = parcel.additional_data || {};

        const creditValue = additionalData[`${parcel.type}_credit`];
        setCredit(creditValue && creditValue > 0 ? USDollar.format(creditValue) : null);
    }, [order, parcel]);

    if (parcel?.current_status.status === PARCEL_STATUS.complete && homeWarrantyRequired === false) {
        return (
            <Stack spacing={1}>
                <ParcelContentNotification text="Not Required" />
            </Stack>
        );
    }

    return (
        <Stack spacing={1}>
            <ParcelDueDateRow parcel={parcel} />

            <GenericContentRow label="Credit" value={credit ? credit : '—'} />

            <GenericOrderedParcelContent order={order} parcel={parcel} />

            {parcel?.current_status.status === PARCEL_STATUS.complete && (
                <GenericContentRow label="Completed" value={formatISODate(parcel.current_status.created_datetime)} />
            )}
        </Stack>
    );
};

const DeedParcelContent = ({ order, parcel }) => {
    const deedRequired = _.get(parcel, 'additional_data.deed_required');

    if (parcel?.current_status.status === PARCEL_STATUS.not_started) {
        return (
            <Stack spacing={1}>
                <ParcelContentNotification text="Not Started" />
            </Stack>
        );
    }

    if (parcel?.current_status.status === PARCEL_STATUS.complete && deedRequired === false) {
        return (
            <Stack spacing={1}>
                <ParcelContentNotification text="Not Required" />
            </Stack>
        );
    }

    return (
        <Stack spacing={1}>
            <ParcelDueDateRow parcel={parcel} />

            <GenericOrderedParcelContent order={order} parcel={parcel} />

            {parcel?.current_status.status === PARCEL_STATUS.complete && (
                <GenericContentRow label="Completed" value={formatISODate(parcel.current_status.created_datetime)} />
            )}
        </Stack>
    );
};

const CDAParcelContent = ({ order, parcel }) => {
    const cdaRequired = _.get(parcel, 'additional_data.cda_required');
    const listingRequestedDate = _.get(parcel, 'additional_data.listing_cda_requested_date');
    const listingReceivedDate = _.get(parcel, 'additional_data.listing_cda_received_date');
    const buyerRequestedDate = _.get(parcel, 'additional_data.buyer_cda_requested_date');
    const buyerReceivedDate = _.get(parcel, 'additional_data.buyer_cda_received_date');

    if (parcel?.current_status.status === PARCEL_STATUS.not_started) {
        return (
            <Stack spacing={1}>
                <ParcelContentNotification text="Not Started" />
            </Stack>
        );
    }

    if (parcel?.current_status.status === PARCEL_STATUS.complete && cdaRequired === false) {
        return (
            <Stack spacing={1}>
                <ParcelContentNotification text="Not Required" />
            </Stack>
        );
    }

    return (
        <Stack spacing={1}>
            <ParcelDueDateRow parcel={parcel} />

            {buyerRequestedDate && !buyerReceivedDate && (
                <GenericContentRow label="Requested (Selling)" value={formatISODate(buyerRequestedDate)} />
            )}

            {buyerReceivedDate && (
                <GenericContentRow label="Received (Selling)" value={formatISODate(buyerReceivedDate)} />
            )}

            {listingRequestedDate && !listingReceivedDate && (
                <GenericContentRow label="Requested (Listing)" value={formatISODate(listingRequestedDate)} />
            )}

            {listingReceivedDate && (
                <GenericContentRow label="Received (Listing)" value={formatISODate(listingReceivedDate)} />
            )}

            {parcel?.current_status.status === PARCEL_STATUS.complete && (
                <GenericContentRow label="Completed" value={formatISODate(parcel.current_status.created_datetime)} />
            )}
        </Stack>
    );
};

const SurveyParcelContent = ({ order, parcel }) => {
    const [surveyStatus, setSurveyStatus] = useState(null);

    const surveyRequired = _.get(parcel, 'additional_data.survey_required');

    useEffect(() => {
        const additionalData = parcel.additional_data || {};
        const existingSurveyValue = additionalData['existing_survey'];
        setSurveyStatus(existingSurveyValue === true ? 'Existing' : existingSurveyValue === false ? 'New' : '—');
    }, [order, parcel]);

    if (parcel.current_status.status === PARCEL_STATUS.not_started) {
        return (
            <Stack spacing={1}>
                <ParcelContentNotification text="Not Started" />
            </Stack>
        );
    }

    if (parcel.current_status.status === PARCEL_STATUS.complete && surveyRequired === false) {
        return (
            <Stack spacing={1}>
                <ParcelContentNotification text="Not Required" />
            </Stack>
        );
    }

    const showSurveyStatus =
        !_.includes([PARCEL_STATUS.not_started, PARCEL_STATUS.in_progress], parcel.current_status.status) &&
        surveyRequired === true &&
        surveyStatus;

    const showGenericParcelContent = !_.includes(
        [PARCEL_STATUS.not_started, PARCEL_STATUS.in_progress],
        parcel.current_status.status
    );

    return (
        <Stack spacing={1}>
            <ParcelDueDateRow parcel={parcel} />

            {showSurveyStatus && <GenericContentRow label="Survey Status" value={surveyStatus} />}

            {showGenericParcelContent && <GenericOrderedParcelContent order={order} parcel={parcel} />}

            {parcel?.current_status.status === PARCEL_STATUS.complete && (
                <GenericContentRow label="Completed" value={formatISODate(parcel.current_status.created_datetime)} />
            )}
        </Stack>
    );
};

const BalancingParcelContent = ({ order, parcel }) => {
    const [itemCount, setItemCount] = useState(0);
    const [balancedCount, setBalancedCount] = useState(0);

    useEffect(() => {
        // Get all balancing child parcels and count their active actions vs total actions
        let newItemCount = 0;
        let newBalancedCount = 0;

        _.forEach(order.parcels, (order_parcel) => {
            if (order_parcel.parent_parcel_id === parcel.id) {
                const totalParcelActions = order_parcel.actions.length;
                const activeParcelActions = order_parcel.active_actions.length;
                newItemCount += totalParcelActions;
                newBalancedCount += totalParcelActions - activeParcelActions;
            }
        });

        setItemCount(newItemCount);
        setBalancedCount(newBalancedCount);
    }, [order, parcel]);

    return (
        <Stack spacing={1}>
            {parcel.current_status.status === PARCEL_STATUS.not_started ? (
                <ParcelContentNotification text="Not Started" />
            ) : (
                <GenericContentRow label="Items Balanced" value={`${balancedCount} / ${itemCount}`} />
            )}

            {parcel?.current_status.status === PARCEL_STATUS.complete && (
                <GenericContentRow label="Completed" value={formatISODate(parcel.current_status.created_datetime)} />
            )}
        </Stack>
    );
};

const ActionChecklistRow = ({ label, value, parcel, order, actionType }) => {
    const [updateAction] = useUpdateActionMutation();
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [prevValue, setPrevValue] = useState(value); // Store prev value to determine changes
    const [localValue, setLocalValue] = useState(value); // Store local value to allow immediate ui updates while waiting for order data refresh

    const activeAction = useMemo(() => {
        let newActiveAction = null;
        _.forEach(parcel.active_actions, (actionId) => {
            const foundAction = parcel.action_map[actionId];
            if (foundAction.type === actionType) {
                newActiveAction = foundAction;
            }
        });

        return newActiveAction;
    }, [parcel, actionType]);

    // Determine if action is complete
    const isComplete = _.get(activeAction, 'status') === ACTION_STATUS.complete;

    // Determine if action is a simple 'mark as done' action or if it requires additional data
    const hasAdditionalDataRequirement = !_.isEmpty(_.get(activeAction, 'additional_data_schema.json_schema', {}));

    const handleUpdateAction = (updateData) => {
        setLoading(true);

        // Optimistically update local value to 'complete' to show checkmark immediately
        // NOTE: we manage this locally instead of in rtk query because this value is derived by parent component
        setLocalValue('complete');

        return submitActionUpdate(updateData).then((response) => {
            setLoading(false);

            if (!response) {
                // If action update fails, revert local value back to previous value
                setLocalValue(value);
            }
        });
    };

    const submitActionUpdate = async (updateData) => {
        const updateActionData = { ...updateData };

        const { data, error } = await updateAction({
            actionId: activeAction.id,
            actionData: updateActionData,
        });

        if (data && !error) {
            return true;
        } else {
            console.warn(`Failed to complete action ${activeAction.id}`);
            return false;
        }
    };

    const handleClick = () => {
        if (isComplete || !activeAction) {
            return;
        }

        if (hasAdditionalDataRequirement) {
            setIsOpen(true);
        } else if (value === 'incomplete') {
            handleUpdateAction({
                status: ACTION_STATUS.complete,
            });
        }
    };

    if (prevValue !== value) {
        // Update local value if value prop changes
        setPrevValue(value);
        setLocalValue(value);
    }

    return (
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
            <Typography
                fontSize="12px"
                fontWeight="400"
                color={(theme) => (value === 'incomplete' ? theme.palette.text.primary : theme.palette.text.secondary)}
            >
                {label}
            </Typography>
            <span>
                {localValue === 'notStarted' && (
                    <IconButton disabled sx={{ mr: '-10px' }}>
                        <IndeterminateCheckBoxOutlinedIcon
                            sx={{
                                fontSize: '18px',
                                color: (theme) => theme.palette.text.secondary,
                            }}
                        />
                    </IconButton>
                )}
                {localValue === 'incomplete' && (
                    <IconButton onClick={() => handleClick()} disabled={loading} sx={{ mr: '-10px' }}>
                        {loading ? (
                            <CheckBoxOutlinedIcon
                                sx={{
                                    fontSize: '18px',
                                    color: (theme) => theme.palette.text.primary,
                                }}
                            />
                        ) : (
                            <CheckBoxOutlineBlankIcon
                                sx={{
                                    fontSize: '18px',
                                    color: (theme) => theme.palette.text.primary,
                                }}
                            />
                        )}
                    </IconButton>
                )}
                {localValue === 'complete' && (
                    <IconButton disabled sx={{ mr: '-10px' }}>
                        <CheckBoxIcon
                            sx={{
                                fontSize: '18px',
                                color: (theme) => theme.palette.text.primary,
                            }}
                        />
                    </IconButton>
                )}
            </span>

            {hasAdditionalDataRequirement && (
                <ActionModal
                    action={activeAction}
                    orderId={order.id}
                    isOpen={isOpen}
                    isSubmitting={loading}
                    handleClose={() => setIsOpen(false)}
                    handleUpdateAction={handleUpdateAction}
                />
            )}
        </Stack>
    );
};

const FundingParcelContent = ({ order, parcel }) => {
    const fundingActionStatus = useMemo(() => {
        // 3 possible states for each line item:
        //      1. complete (checkmark & 'active' text color)
        //      2. incomplete (no checkmark & 'active' text color)
        //      3. not started (no checkmark & 'inactive' text color)

        const newFundingActionStatus = {};

        _.forEach(FUNDING_PARCEL_ACTION_TYPE, (actionType) => {
            newFundingActionStatus[actionType] = 'notStarted';
        });

        _.forEach(parcel.complete_actions, (complete_action_id) => {
            const completeAction = parcel.action_map[complete_action_id];
            newFundingActionStatus[completeAction.type] = 'complete';
        });

        _.forEach(parcel.active_actions, (active_action_id) => {
            const activeAction = parcel.action_map[active_action_id];
            newFundingActionStatus[activeAction.type] = 'incomplete';
        });

        // NOTE: if the parcel is run through multiple times, there could be multiple of each action type
        // By running through complete actions and then active actions, we can ensure that the most recent action is reflected
        return newFundingActionStatus;
    }, [order, parcel]);

    if (parcel.current_status.status === PARCEL_STATUS.not_started) {
        return (
            <Stack spacing={1}>
                <ParcelContentNotification text="Not Started" />
            </Stack>
        );
    }

    return (
        <Stack spacing={1}>
            <ActionChecklistRow
                label="Funding Approval Sent"
                value={fundingActionStatus[FUNDING_PARCEL_ACTION_TYPE.send_for_funding_approval]}
                parcel={parcel}
                order={order}
                actionType={FUNDING_PARCEL_ACTION_TYPE.send_for_funding_approval}
            />
            <ActionChecklistRow
                label="Funding Approved"
                value={fundingActionStatus[FUNDING_PARCEL_ACTION_TYPE.funding_approval]}
                parcel={parcel}
                order={order}
                actionType={FUNDING_PARCEL_ACTION_TYPE.funding_approval}
            />
            <ActionChecklistRow
                label="Lender Funds"
                value={fundingActionStatus[FUNDING_PARCEL_ACTION_TYPE.receive_lender_funds]}
                parcel={parcel}
                order={order}
                actionType={FUNDING_PARCEL_ACTION_TYPE.receive_lender_funds}
            />
            <ActionChecklistRow
                label="Buyer Funds"
                value={fundingActionStatus[FUNDING_PARCEL_ACTION_TYPE.receive_buyer_funds]}
                parcel={parcel}
                order={order}
                actionType={FUNDING_PARCEL_ACTION_TYPE.receive_buyer_funds}
            />
            <ActionChecklistRow
                label="Wires (Setup)"
                value={fundingActionStatus[FUNDING_PARCEL_ACTION_TYPE.setup_wires]}
                parcel={parcel}
                order={order}
                actionType={FUNDING_PARCEL_ACTION_TYPE.setup_wires}
            />
            <ActionChecklistRow
                label="Wires (Approved)"
                value={fundingActionStatus[FUNDING_PARCEL_ACTION_TYPE.approve_wires]}
                parcel={parcel}
                order={order}
                actionType={FUNDING_PARCEL_ACTION_TYPE.approve_wires}
            />
            <ActionChecklistRow
                label="Checks Sent"
                value={fundingActionStatus[FUNDING_PARCEL_ACTION_TYPE.send_checks]}
                parcel={parcel}
                order={order}
                actionType={FUNDING_PARCEL_ACTION_TYPE.send_checks}
            />

            {parcel?.current_status.status === PARCEL_STATUS.complete && (
                <GenericContentRow label="Completed" value={formatISODate(parcel.current_status.created_datetime)} />
            )}
        </Stack>
    );
};

const PostClosingParcelContent = ({ order, parcel }) => {
    const [postClosingActionStatus, setPostClosingActionStatus] = useState({});

    useEffect(() => {
        // 3 possible states for each line item:
        // 1. complete (checkmark & 'active' text color)
        // 2. incomplete (no checkmark & 'active' text color)
        // 3. not started (no checkmark & 'inactive' text color)

        const newPostClosingActionStatus = {};

        _.forEach(POST_CLOSING_PARCEL_ACTION_TYPE, (actionType) => {
            newPostClosingActionStatus[actionType] = 'notStarted';
        });

        _.forEach(parcel.complete_actions, (complete_action_id) => {
            const completeAction = parcel.action_map[complete_action_id];
            newPostClosingActionStatus[completeAction.type] = 'complete';
        });

        _.forEach(parcel.active_actions, (active_action_id) => {
            const activeAction = parcel.action_map[active_action_id];
            newPostClosingActionStatus[activeAction.type] = 'incomplete';
        });

        setPostClosingActionStatus(newPostClosingActionStatus);
    }, [order, parcel]);

    if (parcel.current_status.status === PARCEL_STATUS.waiting_for_post_funding) {
        return (
            <Stack spacing={1}>
                <ParcelContentNotification text="Waiting for Funding" />
            </Stack>
        );
    }

    return (
        <Stack spacing={1}>
            {order.transaction_type === TRANSACTION_TYPE.purchase && (
                <ActionChecklistRow
                    label="Confirm and Send 1099"
                    value={postClosingActionStatus[POST_CLOSING_PARCEL_ACTION_TYPE.confirm_1099_status]}
                    parcel={parcel}
                    order={order}
                    actionType={POST_CLOSING_PARCEL_ACTION_TYPE.confirm_1099_status}
                />
            )}
            <ActionChecklistRow
                label="eRecord"
                value={postClosingActionStatus[POST_CLOSING_PARCEL_ACTION_TYPE.record_documents]}
                parcel={parcel}
                order={order}
                actionType={POST_CLOSING_PARCEL_ACTION_TYPE.record_documents}
            />
            <ActionChecklistRow
                label="Lender Package"
                value={postClosingActionStatus[POST_CLOSING_PARCEL_ACTION_TYPE.lender_package]}
                parcel={parcel}
                order={order}
                actionType={POST_CLOSING_PARCEL_ACTION_TYPE.lender_package}
            />
            <ActionChecklistRow
                label="Refund Recording"
                value={postClosingActionStatus[POST_CLOSING_PARCEL_ACTION_TYPE.refund_recording]}
                parcel={parcel}
                order={order}
                actionType={POST_CLOSING_PARCEL_ACTION_TYPE.refund_recording}
            />
            <ActionChecklistRow
                label="Finalize Title Policy"
                value={postClosingActionStatus[POST_CLOSING_PARCEL_ACTION_TYPE.finalize_title_policy]}
                parcel={parcel}
                order={order}
                actionType={POST_CLOSING_PARCEL_ACTION_TYPE.finalize_title_policy}
            />
            <ActionChecklistRow
                label="Send Policy to Westcor"
                value={postClosingActionStatus[POST_CLOSING_PARCEL_ACTION_TYPE.send_westcor_policy]}
                parcel={parcel}
                order={order}
                actionType={POST_CLOSING_PARCEL_ACTION_TYPE.send_westcor_policy}
            />
            <ActionChecklistRow
                label="Receive Westcor Policy"
                value={postClosingActionStatus[POST_CLOSING_PARCEL_ACTION_TYPE.receive_signed_westcor_policy]}
                parcel={parcel}
                order={order}
                actionType={POST_CLOSING_PARCEL_ACTION_TYPE.receive_signed_westcor_policy}
            />
            <ActionChecklistRow
                label="Send Final Title Documents to Lender"
                value={postClosingActionStatus[POST_CLOSING_PARCEL_ACTION_TYPE.send_lender_title_documents]}
                parcel={parcel}
                order={order}
                actionType={POST_CLOSING_PARCEL_ACTION_TYPE.send_lender_title_documents}
            />

            {parcel?.current_status.status === PARCEL_STATUS.complete && (
                <GenericContentRow label="Completed" value={formatISODate(parcel.current_status.created_datetime)} />
            )}
        </Stack>
    );
};

const KeysParcelContent = ({ order, parcel }) => {
    const [needsTransfer, setNeedsTransfer] = useState(null);
    const [sellerTransferMethod, setSellerTransferMethod] = useState(null);
    const [sellerTransferTime, setSellerTransferTime] = useState(null);
    const [sellerTransferLocation, setSellerTransferLocation] = useState(null);
    const [buyerTransferMethod, setBuyerTransferMethod] = useState(null);
    const [buyerTransferTime, setBuyerTransferTime] = useState(null);
    const [buyerTransferLocation, setBuyerTransferLocation] = useState(null);

    useEffect(() => {
        const additionalData = parcel.additional_data;
        if (additionalData.needs_transfer === true) {
            setNeedsTransfer(true);
        } else if (additionalData.needs_transfer === false) {
            setNeedsTransfer(false);
        } else {
            setNeedsTransfer(null);
        }

        // Seller method/time (enums: pickup, dropoff)
        if (additionalData.seller_transfer_method) {
            setSellerTransferMethod(additionalData.seller_transfer_method);
            setSellerTransferTime(
                additionalData.seller_transfer_time ? parseISO(additionalData.seller_transfer_time) : null
            );
            setSellerTransferLocation(additionalData.seller_transfer_location);
        }

        // Buyer method/time (enums: pickup, dropoff)
        if (additionalData.buyer_transfer_method) {
            setBuyerTransferMethod(additionalData.buyer_transfer_method);
            setBuyerTransferTime(
                additionalData.buyer_transfer_time ? parseISO(additionalData.buyer_transfer_time) : null
            );
            setBuyerTransferLocation(additionalData.buyer_transfer_location);
        }
    }, [order, parcel]);

    if (needsTransfer === false) {
        return (
            <Stack spacing={1}>
                <ParcelContentNotification text="Not Required" />
            </Stack>
        );
    }

    if (needsTransfer === null) {
        return (
            <Stack spacing={1}>
                <ParcelContentNotification text="Not Started" />
            </Stack>
        );
    }

    return (
        <Stack spacing={4}>
            <ParcelDueDateRow parcel={parcel} />

            <Stack direction="row" spacing={1} alignItems="flex-start" justifyContent="space-between">
                <GenericContentLabel label="Seller" />
                <Stack alignItems="flex-end" spacing={1}>
                    <GenericContentValue value={sellerTransferMethod ? _.startCase(sellerTransferMethod) : '—'} />
                    <GenericContentValue value={sellerTransferTime ? format(sellerTransferTime, 'P p') : '—'} />
                    <GenericContentValue
                        value={sellerTransferLocation ? formatAddress(sellerTransferLocation, true) : '—'}
                    />
                </Stack>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="flex-start" justifyContent="space-between">
                <GenericContentLabel label="Buyer" />
                <Stack alignItems="flex-end" spacing={1}>
                    <GenericContentValue value={buyerTransferMethod ? _.startCase(buyerTransferMethod) : '—'} />
                    <GenericContentValue value={buyerTransferTime ? format(buyerTransferTime, 'P p') : '—'} />
                    <GenericContentValue
                        value={buyerTransferLocation ? formatAddress(buyerTransferLocation, true) : '—'}
                    />
                </Stack>
            </Stack>

            {parcel?.current_status.status === PARCEL_STATUS.complete && (
                <GenericContentRow label="Completed" value={formatISODate(parcel.current_status.created_datetime)} />
            )}
        </Stack>
    );
};

const ClosingParcelContent = ({ order, parcel }) => {
    const [schedulingParcel, setSchedulingParcel] = useState(null);
    const [signingParcel, setSigningParcel] = useState(null);
    // const [signingPartyType, setSigningParcelType] = useState(null);
    const [signingMethod, setSigningMethod] = useState(null);
    const [signingTime, setSigningTime] = useState(null);
    const [signingLocation, setSigningLocation] = useState(null);

    useEffect(() => {
        if (order && parcel) {
            // Get all closing child parcels
            const closingChildParcels = getRelevantClosingChildParcels(order, parcel);
            const additionalData = parcel.additional_data;

            setSchedulingParcel(closingChildParcels.scheduling);
            setSigningParcel(closingChildParcels.signing);
            // setSigningParcelType(additionalData.signing_party_type);
            // setSigningMethod(additionalData.signing_method);
            // setSigningTime(additionalData.signing_time ? parseISO(additionalData.signing_time) : null);
            // setSigningLocation(additionalData.signing_location);
        }
    }, [order, parcel]);

    if (!schedulingParcel) {
        return (
            <Stack spacing={1}>
                <ParcelContentNotification text="Not Scheduled" />
            </Stack>
        );
    }

    if (schedulingParcel.current_status.status !== PARCEL_STATUS.complete) {
        return (
            <Stack spacing={1}>
                <ParcelContentNotification text="Waiting to Schedule Closing" />
            </Stack>
        );
    }

    return (
        <Stack spacing={4}>
            <ParcelDueDateRow parcel={parcel} />

            <Stack direction="row" spacing={1} alignItems="flex-start" justifyContent="space-between">
                <GenericContentLabel label="Scheduling" />
                <Stack alignItems="flex-end" spacing={1}>
                    <GenericContentValue value={signingMethod ? _.startCase(signingMethod) : '—'} />
                    <GenericContentValue value={signingTime ? format(signingTime, 'P p') : ''} />
                    {signingLocation && <GenericContentValue value={formatAddress(signingLocation, true)} />}
                </Stack>
            </Stack>

            <GenericContentRow
                label="Signing"
                value={signingParcel ? _.startCase(signingParcel.current_status.status) : '—'}
            />

            {parcel?.current_status.status === PARCEL_STATUS.complete && (
                <GenericContentRow label="Completed" value={formatISODate(parcel.current_status.created_datetime)} />
            )}
        </Stack>
    );
};

const parcelContentMap = {
    [PARCEL_TYPE.earnest_money]: EarnestMoneyParcelContent,
    [PARCEL_TYPE.tax_cert]: TaxCertParcelContent,
    [PARCEL_TYPE.hoa]: HOAParcelContent,
    [PARCEL_TYPE.assignment]: AssignmentParcelContent,
    [PARCEL_TYPE.title_exam]: TitleExamParcelContent,
    [PARCEL_TYPE.title_clearing]: TitleParcelContent,
    [PARCEL_TYPE.home_warranty]: HomeWarrantyParcelContent,
    [PARCEL_TYPE.deed]: DeedParcelContent,
    [PARCEL_TYPE.cda]: CDAParcelContent,
    [PARCEL_TYPE.survey]: SurveyParcelContent,
    [PARCEL_TYPE.balancing]: BalancingParcelContent,
    [PARCEL_TYPE.closing_buyer]: ClosingParcelContent,
    [PARCEL_TYPE.closing_seller]: ClosingParcelContent,
    [PARCEL_TYPE.closing_borrower]: ClosingParcelContent,
    [PARCEL_TYPE.funding]: FundingParcelContent,
    [PARCEL_TYPE.keys]: KeysParcelContent,
    [PARCEL_TYPE.post_closing]: PostClosingParcelContent,
};

function ParcelCardContent({ order, parcel }) {
    const ParcelContent = parcelContentMap[parcel.type];

    if (!ParcelContent) {
        // Ignore any parcels that are not explicitly handled
        return null;
    }

    return <ParcelContent order={order} parcel={parcel} />;
}

ParcelCardContent.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
};

export default ParcelCardContent;
