import PropTypes from 'prop-types';

import { useGetOrderQuery } from 'api/order';
import ParcelNoteList from 'components/Order/Parcel/components/ParcelNoteList';

const SigningSessionNoteList = ({ orderId, parcel }) => {
    const { data: order, isLoading: orderLoading } = useGetOrderQuery(orderId);

    return (
        <ParcelNoteList
            order={order}
            parcel={parcel}
            paperProps={{
                variant: 'elevation',
                elevation: 0,
                sx: {
                    b: 0,
                },
            }}
        />
    );
};

SigningSessionNoteList.propTypes = {
    orderId: PropTypes.string.isRequired,
    parcel: PropTypes.object.isRequired,
};

export default SigningSessionNoteList;
