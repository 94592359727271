import AddIcon from '@mui/icons-material/Add';
import { IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import { useGetOrderMembersQuery } from 'api/order';
import { generateOrderMemberIdMap } from 'components/Order/Members/utils';
import AssignmentContractListItem from './AssignmentContractListItem';
import CreateAssignmentModal from './CreateAssignmentModal';

function AssignmentContractList({ order, assignmentContractParcels }) {
    const { data: orderMembers, isError: orderMembersError, isLoading: orderMembersLoading } = useGetOrderMembersQuery(
        order.id
    );
    const orderMemberMap = useMemo(() => generateOrderMemberIdMap(orderMembers || []), [orderMembers]);

    const [openCreateAssignmentModal, setOpenCreateAssignmentModal] = useState(false);

    // TODO sort assignmentContract by created date and members to create 'chain' of assignments

    // TODO handle error/undefined

    return (
        <Paper
            variant="outlined"
            sx={{
                padding: (theme) => theme.spacing(0),
                '& .assignment-list-item': {
                    pl: 3,
                    pr: 3,
                    pt: 3,
                    pb: 3,
                },
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{ padding: (theme) => theme.spacing(3) }}
            >
                <Typography variant="sectionHeader">Contracts</Typography>

                <Tooltip title="Add Contract" enterDelay={300} placement="bottom">
                    <span>
                        <IconButton onClick={() => setOpenCreateAssignmentModal(true)} size="small">
                            <AddIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            </Stack>

            {_.get(assignmentContractParcels, 'length', []) === 0 ? (
                <Stack sx={{ padding: (theme) => theme.spacing(0, 3, 3) }}>
                    <Typography variant="body1" color="text.secondary" fontStyle="italic">
                        No active contracts
                    </Typography>
                </Stack>
            ) : (
                <Stack spacing={0}>
                    {_.map(assignmentContractParcels, (assignmentContractParcel) => {
                        return (
                            <AssignmentContractListItem
                                key={assignmentContractParcel.id}
                                orderId={order.id}
                                assignment={assignmentContractParcel}
                                orderMemberMap={orderMemberMap || {}}
                            />
                        );
                    })}
                </Stack>
            )}

            <CreateAssignmentModal
                order={order}
                open={openCreateAssignmentModal}
                handleClose={() => setOpenCreateAssignmentModal(false)}
            />
        </Paper>
    );
}

AssignmentContractList.propTypes = {
    order: PropTypes.object.isRequired,
    assignmentContractParcels: PropTypes.array.isRequired,
};

export default AssignmentContractList;
