import { Box, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { useCreateParcelMemberMutation } from 'api/parcelMember';
import ParcelMemberCheckboxField from './ParcelMemberCheckboxField';

import { LoadingButton } from 'components/common/styled';

const CreateParcelMemberForm = ({ parcel, parcelMembers, orderMembers, handleSubmit }) => {
    const [createParcelMember, { isLoading }] = useCreateParcelMemberMutation();

    const [newParcelMemberData, setNewParcelMemberData] = useState([]);

    const handleParcelMembersChange = (members) => {
        setNewParcelMemberData(
            _.map(members, (member) => {
                return {
                    ...member,
                    parcel_id: parcel.id,
                };
            })
        );
    };

    const handleCreateParcelMember = (event) => {
        event.preventDefault();

        return submitCreateParcelMember().then((response) => {
            if (response) {
                handleSubmit();
            } else {
                // TODO handle error state
            }
        });
    };

    const submitCreateParcelMember = async () => {
        const { data } = await createParcelMember({
            orderId: parcel.order_id,
            parcelId: parcel.id,
            memberData: newParcelMemberData,
        });

        if (data) {
            return true;
        } else {
            console.warn(`Failed to create parcel member for parcel ${parcel.id}`);
            return false;
        }
    };

    return (
        <form onSubmit={handleCreateParcelMember}>
            <Stack spacing={4}>
                <Stack spacing={1}>
                    {_.isEmpty(orderMembers) ? (
                        <Typography variant="body1" color="text.secondary" fontStyle="italic">
                            No available members
                        </Typography>
                    ) : (
                        <Typography variant="body1" color="text.secondary">
                            Select Order Members
                        </Typography>
                    )}

                    <ParcelMemberCheckboxField
                        parcelMembers={newParcelMemberData}
                        setParcelMembers={handleParcelMembersChange}
                        orderMembers={orderMembers}
                    />
                </Stack>

                <Box>
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ width: '100%' }}
                    >
                        <Box />
                        <LoadingButton
                            type="submit"
                            color="primary"
                            variant="contained"
                            loading={isLoading}
                            disableElevation
                            sx={{
                                minWidth: '160px',
                            }}
                            disabled={newParcelMemberData.length === 0}
                        >
                            Create
                        </LoadingButton>
                    </Stack>
                </Box>
            </Stack>
        </form>
    );
};

CreateParcelMemberForm.propTypes = {
    parcel: PropTypes.object.isRequired,
    parcelMembers: PropTypes.array.isRequired,
    orderMembers: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default CreateParcelMemberForm;
