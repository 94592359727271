import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import DataForm from 'components/common/form/rjsf/DataForm';

// TODO work to integrate this into the create lien modal as well
const EditLienAdditionalData = ({ lien, lienAdditionalData, setLienAdditionalData }) => {
    const [jsonSchema, setJsonSchema] = useState();
    const [uiSchema, setUiSchema] = useState();

    useEffect(() => {
        if (lien) {
            const newLienCopy = JSON.parse(JSON.stringify(lien));
            setLienAdditionalData(newLienCopy.additional_data);

            const baseLienSchema = lien.additional_data_schema.json_schema;
            if (_.isEmpty(baseLienSchema)) {
                setJsonSchema(null);
            } else {
                setJsonSchema({
                    ...baseLienSchema,
                    // Override required fields list so that we can submit the form without filling all fields
                    // We do this here instead of in the backend because the backend validator uses the required list to ensure that the core interface doesn't change
                    required: [],
                });
            }

            // Merge in custom ui override
            setUiSchema({
                ...lien.additional_data_schema.ui_schema,
                'ui:submitButtonOptions': {
                    norender: true, // this form is nested and submit is handled at a higher level
                },
            });
        }
    }, [lien]);

    const handleChange = (event) => {
        const { formData } = event;

        setLienAdditionalData(_.isEmpty(formData) ? null : formData);
    };

    if (!jsonSchema) {
        return null;
    }

    return (
        <DataForm
            data={lienAdditionalData || {}}
            schema={{
                json_schema: jsonSchema,
                ui_schema: uiSchema,
            }}
            onChange={handleChange}
            onSubmit={(e) => console.log('submit', e)}
            // onSubmit={handleSubmit}
            onError={(e) => console.log('errors', e)}
        >
            {/* <Box
                sx={{
                    textAlign: 'right',
                    paddingTop: (theme) => theme.spacing(3),
                }}
            >
                <LoadingButton
                    color="primary"
                    type="submit"
                    variant="contained"
                    sx={{
                        minWidth: '120px',
                    }}
                    loading={isLoading}
                    disabled={_.keys(updatePayload).length === 0}
                    disableElevation
                >
                    Update
                </LoadingButton>
            </Box> */}
        </DataForm>
    );
};

EditLienAdditionalData.propTypes = {
    lien: PropTypes.object.isRequired,
    lienAdditionalData: PropTypes.object, // not required as it can be null
    setLienAdditionalData: PropTypes.func.isRequired,
};

export default EditLienAdditionalData;
