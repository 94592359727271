import { TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers-pro';
import { format, isWeekend, parseISO } from 'date-fns';
import _ from 'lodash';
import { useMemo } from 'react';

const CustomDateTimeWidget = (props) => {
    const handleChange = (value) => {
        props.onChange(value ? value.toISOString() : null);
    };

    const datePickerOptions = useMemo(() => {
        const fieldSchema = props.schema;
        const minDate = _.get(fieldSchema, 'minDate') || null;
        const maxDate = _.get(fieldSchema, 'maxDate') || null;
        const disableWeekend = _.get(fieldSchema, 'disableWeekend') || false;
        const allowedDates = _.get(fieldSchema, 'allowedDates') || null;
        const options = {
            minDate: minDate ? parseISO(minDate) : null,
            maxDate: maxDate ? parseISO(maxDate) : null,
        };

        // NOTE: allowedDates and disableWeekend are mutually exclusive atm
        if (allowedDates && allowedDates.length > 0) {
            options.shouldDisableDate = (date) => {
                const disableDate = !allowedDates.includes(format(date, 'yyyy-MM-dd'));
                return disableDate;
            };
        } else if (disableWeekend) {
            options.shouldDisableDate = (date) => isWeekend(date);
        }

        return options;
    }, [props.schema]);

    return (
        <DateTimePicker
            variant="inline"
            label={_.get(props, 'schema.title', '')}
            value={props.value}
            onChange={handleChange}
            minutesStep={5}
            renderInput={(params) => <TextField {...params} />}
            disabled={_.get(props, 'readonly', false)}
            {...datePickerOptions}
        />
    );
};

export default CustomDateTimeWidget;
