import _ from 'lodash';
import { useMemo } from 'react';

import MemberSelectField from 'components/Order/Members/components/MemberSelectField';
import { ORDER_EXTERNAL_ROLES } from 'helpers/constants';

const CustomOrderMemberSelectField = (props) => {
    // NOTE: formData provided to this field is not the entire form's data, but just the data for this field
    const { orderMembers } = props.formContext;

    // Defaults to enforce required fields - must be explicitly set to false to disable
    // TODO standardize these across all custom fields
    const uiSchema = _.get(props, 'uiSchema', {});
    const disabled = _.get(props, 'readonly', false);
    const required = _.get(props, 'required', true);

    // Determine the field's label
    const schemaLabel = _.get(props, 'schema.title');
    const uiLabel = _.get(uiSchema, 'ui:title');
    const label = uiLabel || schemaLabel;

    // Custom field specific options
    const orderMemberRoleOptions = _.get(uiSchema, 'ui:role_options', [...ORDER_EXTERNAL_ROLES.values()]);

    const handleUpdateFormData = (newFormData) => {
        console.log('updating order member select data', newFormData);
        props.onChange([...newFormData]);
    };

    const filteredOrderMembers = useMemo(
        () => _.filter(orderMembers, (member) => _.includes(orderMemberRoleOptions, member.role)),
        [orderMembers, orderMemberRoleOptions]
    );

    return (
        <MemberSelectField
            selectedOrderMembers={props.formData}
            setSelectedOrderMembers={handleUpdateFormData}
            orderMembers={filteredOrderMembers}
            label={label}
            TextFieldProps={{
                required,
                disabled,
            }}
        />
    );
};

export default CustomOrderMemberSelectField;
