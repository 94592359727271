import { Stack } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import useTriggers from 'components/common/hooks/useTriggers';
import { DynamicTriggerIconButton } from 'components/Order/Parcel/components/ParcelTriggerList';

const SchedulingListTriggers = ({ parcel, formContext }) => {
    const { triggers, activeTriggers } = useTriggers(parcel);

    if (!triggers || triggers.length === 0 || activeTriggers.length === 0) {
        return null;
    }

    return (
        <Stack spacing={0} direction="row">
            {_.map(triggers, (trigger, triggerKey) => {
                const isActive = _.includes(activeTriggers, triggerKey);
                return (
                    <DynamicTriggerIconButton
                        key={triggerKey}
                        parcel={parcel}
                        trigger={trigger}
                        isActive={isActive}
                        dialogProps={{
                            title: `${_.startCase(triggerKey)}`,
                            body: `Are you sure you want to ${_.lowerCase(triggerKey)} closing members?`,
                        }}
                        buttonProps={{ size: 'medium' }}
                        // icon={PARCEL_TRIGGER_ICON_MAP.restore}
                        formContext={formContext}
                    />
                );
            })}
        </Stack>
    );
};

SchedulingListTriggers.propTypes = {
    parcel: PropTypes.object.isRequired,
};

export default SchedulingListTriggers;
