import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PickersDay, StaticDatePicker } from '@mui/x-date-pickers-pro';
import { isEqual } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

const CustomPickersDay = styled(PickersDay)(({ theme, selected }) => ({
    // '&.MuiDayPicker-weekContainer .MuiPickersDay-root.Mui-selected': {
    // },
    // '&.MuiPickersDay-root.Mui-selected': {
    //     // backgroundColor: theme.palette.primary.main,
    //     // color: theme.palette.common.white,
    //     // '&:hover, &:focus': {
    //     //     backgroundColor: theme.palette.primary.dark,
    //     // },
    //     // borderTopLeftRadius: '50%',
    //     // borderBottomLeftRadius: '50%',
    //     // borderTopRightRadius: '50%',
    //     // borderBottomRightRadius: '50%',
    //     '&:has(+ .MuiPickersDay-root.Mui-selected)': {
    //         borderTopRightRadius: 0,
    //         borderBottomRightRadius: 0,
    //     },
    // },
    // ...(selected && {
    //     backgroundColor: theme.palette.primary.main,
    //     color: theme.palette.common.white,
    //     '&:hover, &:focus': {
    //         backgroundColor: theme.palette.primary.dark,
    //     },
    //     borderTopLeftRadius: '50%',
    //     borderBottomLeftRadius: '50%',
    //     borderTopRightRadius: '50%',
    //     borderBottomRightRadius: '50%',
    // }),
}));

const findDate = (dates, date) => {
    return _.find(dates, (item) => isEqual(item, date));
};

const findDateIndex = (dates, date) => {
    return _.findIndex(dates, (item) => isEqual(item, date));
};

const renderPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!selectedDates) {
        return <PickersDay {...pickersDayProps} />;
    }

    const selected = !!findDate(selectedDates, date);

    return <CustomPickersDay {...pickersDayProps} disableMargin selected={selected} aria-selected={selected} />;
};

function MultiDatePicker({ dates, setDates, datePickerProps = {} }) {
    const wrappedRenderPickerDay = useCallback(
        (date, selectedDates, pickersDayProps) => {
            // Replace with managed selectedDates array
            return renderPickerDay(date, dates, pickersDayProps);
        },
        [dates]
    );

    const handleChange = (newValue) => {
        // Create new array copy
        const array = [...dates];
        const index = findDateIndex(array, newValue);

        if (index >= 0) {
            // Remove date if already exists
            array.splice(index, 1);
        } else {
            // Add date if it doesn't exist
            array.push(newValue);
        }

        setDates(array.sort((a, b) => a - b));
    };

    return (
        <StaticDatePicker
            value={dates}
            onChange={handleChange}
            renderDay={wrappedRenderPickerDay}
            renderInput={(params) => <TextField {...params} />}
            inputFormat="yyyy-MM-dd"
            showToolbar={false}
            displayStaticWrapperAs="desktop"
            {...datePickerProps}
        />
    );
}

MultiDatePicker.propTypes = {
    dates: PropTypes.array.isRequired,
    setDates: PropTypes.func.isRequired,
    datePickerProps: PropTypes.object,
};

export default MultiDatePicker;
