import AddTaskIcon from '@mui/icons-material/AddTask';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import _ from 'lodash';

import { ACTION_STATUS, ACTION_TERMINAL_STATUSES } from 'helpers/constants';


export function determineActionStatusColor(action_status) {
    if (action_status === ACTION_STATUS.complete) {
        return 'success';
    } else if (action_status === ACTION_STATUS.canceled) {
        return 'warning';
    } else {
        return 'default';
    }
};


export function determineActionIsTerminal(action_status) {
    return _.includes(ACTION_TERMINAL_STATUSES, action_status);
}


export function determineActionHasAdditionalData(action) {
    return !_.isEmpty(action.additional_data_schema.json_schema);
}


export function determineActionIcon(action) {
    if (action.status === ACTION_STATUS.complete) {
        return <CheckCircleIcon />;
    } else if (action.status === ACTION_STATUS.canceled) {
        return <NotInterestedIcon />;
    } else if (determineActionHasAdditionalData(action)) {
        return <AddTaskIcon />;
    } else {
        return <TaskAltIcon />;
    }
}