import { Box, Stack } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import { DataGrid } from 'components/common/styled';
import ParcelStatusChip from 'components/Order/Parcel/components/ParcelStatusChip';
import OrderMemberColumn from 'components/Order/Requests/components/OrderMemberColumn';
import { PARCEL_STATUS } from 'helpers/constants';

import SchedulingActionColumn from './SchedulingActionColumn';
import SchedulingDetailsColumn from './SchedulingDetailsColumn';
import SchedulingTriggerColumn from './SchedulingTriggerColumn';

export const SCHEDULING_STATUS_COLOR_MAP = {
    [PARCEL_STATUS.on_hold]: 'warning',
    [PARCEL_STATUS.not_started]: 'default',
    [PARCEL_STATUS.in_progress]: 'default', // 'info',
    [PARCEL_STATUS.waiting]: 'default', // 'info',
    [PARCEL_STATUS.preparing_documents]: 'default', // 'info',
    [PARCEL_STATUS.documents_ready]: 'default', // 'info',
    [PARCEL_STATUS.waiting_for_signed_documents]: 'default', // 'info',
    [PARCEL_STATUS.documents_printed]: 'default', // 'info',
    [PARCEL_STATUS.complete]: 'primary',
    [PARCEL_STATUS.canceled]: 'warning',
};

export const SCHEDULING_LIST_GRID_COLUMN_TYPE = {
    parcelId: 'parcel_id',
    status: 'status',
    orderMember: 'order_member',
    actions: 'actions',
    triggers: 'triggers',
};

const generateSchedulingListColumns = (hideColumns) => {
    return [
        {
            field: SCHEDULING_LIST_GRID_COLUMN_TYPE.parcelId,
            headerName: _.startCase(SCHEDULING_LIST_GRID_COLUMN_TYPE.messageId),
            minWidth: 160,
            hide: true || _.includes(hideColumns, SCHEDULING_LIST_GRID_COLUMN_TYPE.parcelId),
        },
        {
            field: SCHEDULING_LIST_GRID_COLUMN_TYPE.status,
            headerName: _.startCase(SCHEDULING_LIST_GRID_COLUMN_TYPE.status),
            minWidth: 160,
            hide: _.includes(hideColumns, SCHEDULING_LIST_GRID_COLUMN_TYPE.status),
            valueGetter: ({ row }) => _.get(row, 'current_status.status'),
            renderCell: (params) => (
                <ParcelStatusChip
                    status={params.value}
                    statusColorMap={SCHEDULING_STATUS_COLOR_MAP}
                    chipProps={{ size: 'small' }}
                />
            ),
        },
        {
            field: SCHEDULING_LIST_GRID_COLUMN_TYPE.orderMember,
            // headerName: _.startCase(SCHEDULING_LIST_GRID_COLUMN_TYPE.orderMember),
            headerName: 'Member',
            minWidth: 160,
            hide: _.includes(hideColumns, SCHEDULING_LIST_GRID_COLUMN_TYPE.orderMember),
            valueGetter: ({ row }) => _.get(row, 'additional_data.order_member_id'),
            // TODO sort by order member name
            renderCell: (params) => <OrderMemberColumn orderId={params.row.order_id} memberId={params.value} />,
        },
        {
            field: SCHEDULING_LIST_GRID_COLUMN_TYPE.actions,
            headerName: '',
            sortable: false,
            minWidth: 240,
            flex: 1,
            align: 'right',
            hide: _.includes(hideColumns, SCHEDULING_LIST_GRID_COLUMN_TYPE.actions),
            renderCell: (params) => (
                <Stack direction="row" spacing={2} alignItems="center" flex={1}>
                    <SchedulingDetailsColumn orderId={params.row.order_id} parcel={params.row} />

                    <Box sx={{ flexGrow: 1, textAlign: 'right' }}>
                        <SchedulingActionColumn orderId={params.row.order_id} parcel={params.row} />
                    </Box>

                    <SchedulingTriggerColumn parcel={params.row} />
                </Stack>
            ),
        },
    ];
};

function SchedulingListDataGrid({ schedulingMemberParcels, loading, hideColumns = [], refetch }) {
    const [pageSize, setPageSize] = useState(10);

    const schedulingListColumns = useMemo(() => generateSchedulingListColumns(hideColumns), [hideColumns]);

    return (
        <DataGrid
            columns={schedulingListColumns}
            rows={schedulingMemberParcels}
            loading={loading}
            refetch={refetch}
            getRowId={(row) => row.id}
            autoHeight
            disableColumnMenu
            disableColumnResize
            disableSelectionOnClick
            density="comfortable"
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 50, 100]}
            localeText={{
                noRowsLabel: 'No closing members',
            }}
            // components={{
            //     Header: () => (
            //         <Stack spacing={3} sx={{ pl: 1, pr: 1, pt: 2, pb: 2 }}>
            //             <Typography variant="sectionHeader">Members</Typography>
            //         </Stack>
            //     ),
            // }}
            // headerHeight={0}
            pagination
        />
    );
}

SchedulingListDataGrid.propTypes = {
    schedulingMemberParcels: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    hideColumns: PropTypes.arrayOf(PropTypes.string),
    refetch: PropTypes.func,
};

export default SchedulingListDataGrid;
