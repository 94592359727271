import PropTypes from 'prop-types';

import BaseDialog from 'components/common/BaseDialog';
import CreateAssignment from './CreateAssignment';

const CreateAssignmentModal = ({ open, handleClose, order }) => {
    const handleSubmit = () => {
        handleCloseLocal(false);
    };

    const handleCloseLocal = (open) => {
        handleClose(open);
    };

    return (
        <BaseDialog
            open={open}
            setOpen={() => handleCloseLocal(false)}
            DialogProps={{
                maxWidth: 'md',
                fullWidth: false,
            }}
        >
            <CreateAssignment orderId={order.id} handleSubmit={handleSubmit} />
        </BaseDialog>
    );
};

CreateAssignmentModal.propTypes = {
    order: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default CreateAssignmentModal;
