import { useGetOrderMemberQuery } from 'api/order'; // TODO convert to orderMember
import _ from 'lodash';
import { useMemo } from 'react';

function useOrderMemberData(orderId, memberId) {
    const { data: orderMember, isError, isLoading, isFetching } = useGetOrderMemberQuery({
        orderId,
        memberId,
    });

    const { name, initials } = useMemo(() => {
        if (orderMember) {
            const name = _.get(orderMember, 'member.name', 'Unknown');
            const initials = name
                .split(' ')
                .map((n) => n[0])
                .join('');

            // TODO userId

            return {
                name,
                initials,
            };
        }

        return {};
    }, [orderMember]);

    return {
        orderMember,
        name,
        initials,
        isLoading,
        isFetching,
        isError,
    };
}

export default useOrderMemberData;
