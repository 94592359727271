import { ENTITY_TYPE } from '../helpers/constants';
import { DOCUMENT_TAG } from './document';
import { NOTIFICATION_EVENT_LOG_COUNT_TAG, NOTIFICATION_EVENT_LOG_TAG, ORDER_EVENT_LOG_TAG } from './eventLog';
import { LEGAL_ENTITY_TAG } from './legalEntity';
import { ORDER_MEMBER_TAG, ORDER_TAG } from './order';
import { PARCEL_FORM_TAG } from './parcel';
import { PARCEL_MEMBER_TAG } from './parcelMember';
import { rootApi } from './root';
import { USER_TAG } from './user';

// Buffer given to help avoid unnecessary refetches when the origin of the update is the same client
// Hacky solution but it works for now
const SECONDS_LIMIT = 3;

const shouldInvalidateTag = (fulfilledTimeStamp) => {
    // If no timestamp exists, then the data has never been fetched and doesn't need to be invalidated
    if (!fulfilledTimeStamp) {
        return false;
    }

    const secondsDiff = (Date.now() - fulfilledTimeStamp) / 1000;
    return secondsDiff > SECONDS_LIMIT;
};

export const calculateShouldInvalidateTagList = (socketData, currentState) => {
    const { entity_type, entity_id, order_id } = socketData.message;

    // NOTE: cache clearing will only trigger a refetch if the cache is actively being used
    // NOTE: entity_action doesn't matter atm - create, update, delete all require update through cache clearing

    const tagsToInvalidate = [];

    switch (entity_type) {
        // Fallthrough - all of these entities retrieved through the order data
        case ENTITY_TYPE.action:
        case ENTITY_TYPE.lien:
        case ENTITY_TYPE.payoff:
        case ENTITY_TYPE.parcel:
        case ENTITY_TYPE.order: {
            const currentOrderState = rootApi.endpoints.getOrders.select()(currentState);
            const { fulfilledTimeStamp } = currentOrderState;

            if (shouldInvalidateTag(fulfilledTimeStamp)) {
                tagsToInvalidate.push(ORDER_TAG);
            }

            // Invalidate parcel members
            // if (entity_type !== ENTITY_TYPE.action) {
            tagsToInvalidate.push(PARCEL_MEMBER_TAG);
            tagsToInvalidate.push(PARCEL_FORM_TAG);
            // }

            // Invalidate all event log tags
            tagsToInvalidate.push(
                ...[ORDER_EVENT_LOG_TAG, NOTIFICATION_EVENT_LOG_TAG, NOTIFICATION_EVENT_LOG_COUNT_TAG]
            );

            break;
        }

        case ENTITY_TYPE.document: {
            // NOTE: documents are all viewed through specific orders
            // So we check if documents for the specified order are loaded in cache
            const currentOrderDocumentState = rootApi.endpoints.getOrderDocuments.select(order_id)(currentState);
            const { fulfilledTimeStamp } = currentOrderDocumentState;

            if (shouldInvalidateTag(fulfilledTimeStamp)) {
                tagsToInvalidate.push(DOCUMENT_TAG);
            }
            break;
        }

        case ENTITY_TYPE.order_member_relationship:
        case ENTITY_TYPE.order_member: {
            // NOTE: order members are all viewed through specific orders
            // So we check if order members for the specified order are loaded in cache
            const currentOrderMemberState = rootApi.endpoints.getOrderMembers.select(order_id)(currentState);
            const { fulfilledTimeStamp } = currentOrderMemberState;

            if (shouldInvalidateTag(fulfilledTimeStamp)) {
                tagsToInvalidate.push(ORDER_MEMBER_TAG);
            }
            break;
        }

        // TODO - add parcel member dependancies

        case ENTITY_TYPE.user_relationship:
        case ENTITY_TYPE.user: {
            const currentUserState = rootApi.endpoints.getUsers.select()(currentState);
            const { fulfilledTimeStamp } = currentUserState;

            if (shouldInvalidateTag(fulfilledTimeStamp)) {
                tagsToInvalidate.push(USER_TAG);
            }
            break;
        }

        case ENTITY_TYPE.legal_entity_member:
        case ENTITY_TYPE.legal_entity_member_relationship:
        case ENTITY_TYPE.legal_entity_relationship:
        case ENTITY_TYPE.legal_entity: {
            const currentLegalEntityState = rootApi.endpoints.getLegalEntities.select()(currentState);
            const { fulfilledTimeStamp } = currentLegalEntityState;

            if (shouldInvalidateTag(fulfilledTimeStamp)) {
                tagsToInvalidate.push(LEGAL_ENTITY_TAG);
            }
            break;
        }

        // NOTE: all members are currently provided through the legal entity data
        // case ENTITY_TYPE.legal_entity_member: {
        //     // NOTE: getAllLegalEntityMembers is for all members of all legal entities
        //     const currentLegalEntityMemberState = rootApi.endpoints.getAllLegalEntityMembers.select()(currentState);
        //     const { fulfilledTimeStamp } = currentLegalEntityMemberState;

        //     if (shouldInvalidateTag(fulfilledTimeStamp)) {
        //         tagsToInvalidate.push(LEGAL_ENTITY_MEMBER_TAG);
        //     }
        //     break;
        // }

        /*
            TODO: add other entity types here
            case ENTITY_TYPE.document_access - DOCUMENT_TAG
            case ENTITY_TYPE.note - NOTE_TAG
        */

        default:
            break;
    }

    return tagsToInvalidate;
};

const socketListenerEffect = async (action, listenerApi) => {
    const { message: socketPayload } = action;
    // NOTE: cache clearing will only trigger a refetch if the cache is actively being used
    // NOTE: entity_action doesn't matter atm - create, update, delete all require update through cache clearing

    const currentState = listenerApi.getState();
    const tagsToInvalidate = calculateShouldInvalidateTagList(socketPayload, currentState);
    if (tagsToInvalidate.length > 0) {
        // https://redux-toolkit.js.org/rtk-query/api/created-api/api-slice-utils#invalidatetags
        listenerApi.dispatch(rootApi.util.invalidateTags(tagsToInvalidate));
    }
};

export default socketListenerEffect;
