import { Stack } from '@mui/material';

import { Route, Routes, useLocation, useParams } from 'react-router-dom';

import OrderAssignments from 'components/AssignmentList';
import OrderDocumentList from 'components/DocumentList';
import OrderNoteList from 'components/NoteList';
import OrderBalancing from 'components/Order/Balancing';
import OrderLogList from 'components/Order/LogList';
import OrderMembers from 'components/Order/Members';
import OrderDetails from 'components/Order/OrderDetails';
import OrderParcelList from 'components/Order/ParcelList';
import OrderRequests from 'components/Order/Requests';
import OrderTitle from 'components/Order/Title';

// Pages defined to handle mapping additional route params to component args
import OrderAssignment from 'pages/Assignment/AssignmentPage';
import OrderClosingEntity from 'pages/Closing/ClosingEntityPage';
import OrderLien from 'pages/Lien/LienPage';
import OrderParcel from 'pages/Parcel/ParcelPage';

function Order() {
    let { order_id: orderId } = useParams();
    const { pathname } = useLocation();

    const basePath = `/order/${orderId}`;

    return (
        <Stack
            spacing={3}
            sx={{
                padding: (theme) => theme.spacing(3),
            }}
        >
            <OrderDetails orderId={orderId} minimize={basePath !== pathname} />

            <Routes>
                <Route path="/assignments" element={<OrderAssignments orderId={orderId} />} />

                <Route path="/assignment/:assignment_id" element={<OrderAssignment orderId={orderId} />} />

                <Route path="/closing/:parcel_id" element={<OrderClosingEntity orderId={orderId} />} />

                <Route path="/documents" element={<OrderDocumentList orderId={orderId} />} />

                <Route path="/parcel/:parcel_id" element={<OrderParcel orderId={orderId} />} />

                <Route path="/balancing" element={<OrderBalancing orderId={orderId} />} />

                <Route path="/title" element={<OrderTitle orderId={orderId} />} />

                <Route path="/title/:lien_id" element={<OrderLien />} />

                <Route path="/requests" element={<OrderRequests orderId={orderId} />} />

                <Route path="/members" element={<OrderMembers orderId={orderId} />} />

                <Route path="/notes" element={<OrderNoteList orderId={orderId} />} />

                <Route path="/logs" element={<OrderLogList orderId={orderId} />} />

                <Route path="*" element={<OrderParcelList orderId={orderId} />} />
            </Routes>
        </Stack>
    );
}

export default Order;
