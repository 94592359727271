import { Box, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { useGetOrderMembersQuery } from 'api/order';
import { useGetParcelMembersQuery } from 'api/parcelMember';
import useParcelData from 'components/common/hooks/useParcelData';
import ParcelStatusChip from 'components/Order/Parcel/components/ParcelStatusChip';
import { PARCEL_STATUS } from 'helpers/constants';
import SchedulingListDataGrid, { SCHEDULING_STATUS_COLOR_MAP } from './SchedulingListDataGrid';
import SchedulingListTriggers from './SchedulingListTriggers';

const SchedulingList = ({ orderId, parcelId }) => {
    const { order, parcel, parentParcel, childParcels, isFetching } = useParcelData(orderId, parcelId);
    const { data: closingMembers } = useGetParcelMembersQuery({ orderId: orderId, parcelId: parentParcel?.id });
    const { data: orderMembers } = useGetOrderMembersQuery(orderId);

    const filteredChildParcels = useMemo(() => {
        return childParcels.filter((parcel) => parcel.current_status.status !== PARCEL_STATUS.canceled);
    }, [childParcels]);

    const filteredOrderMembers = useMemo(() => {
        if (!orderMembers || !closingMembers) {
            return [];
        }

        const closingOrderMemberIds = _.map(closingMembers, 'order_member_id');
        return _.filter(orderMembers, (orderMember) => {
            return _.includes(closingOrderMemberIds, orderMember.id);
        });
    }, [orderMembers, closingMembers]);

    const formContext = {
        order,
        parcel,
        orderMembers: filteredOrderMembers,
    };

    return (
        <Stack spacing={1}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h4">Scheduling</Typography>

                <Stack direction="row" alignItems="center" spacing={1}>
                    <SchedulingListTriggers parcel={parcel} formContext={formContext} />

                    <ParcelStatusChip
                        status={_.get(parcel, 'current_status.status')}
                        statusColorMap={SCHEDULING_STATUS_COLOR_MAP}
                        // chipProps={{ size: 'small' }}
                    />
                </Stack>
            </Stack>

            <Box>
                <SchedulingListDataGrid
                    schedulingMemberParcels={filteredChildParcels}
                    loading={isFetching}
                    refetch={() => console.log('TODO')}
                />
            </Box>
        </Stack>
    );
};

SchedulingList.propTypes = {
    orderId: PropTypes.string.isRequired,
    parcelId: PropTypes.string.isRequired,
};

export default SchedulingList;
