import CustomAddressField from "./CustomAddressField";
import CustomContactField from "./CustomContactField";
import CustomDocumentMetaField from "./CustomDocumentMetaField";
import CustomMultiDateSelectField from "./CustomMultiDateSelectField";
import CustomOrderMemberSelectField from "./CustomOrderMemberSelectField";
import CustomProfileField from "./CustomProfileField";

export default { 
    AddressField: CustomAddressField,
    ContactField: CustomContactField,
    UserProfileField: CustomProfileField,
    AdminDocumentMetaField: CustomDocumentMetaField,
    OrderMemberSelectField: CustomOrderMemberSelectField,
    DateMultiSelectField: CustomMultiDateSelectField
};