import { Avatar, Box, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useMemo, useState } from 'react';

import { useGetOrdersQuery } from 'api/order';
import OrderListFilters from 'components/OrderList/components/OrderListFilters';
import {
    META_PARCEL_TYPE,
    ORDER_FILTER_TYPE,
    ORDER_STATUS,
    PARCEL_ACTION_FILTER_TYPE,
    PARCEL_TYPE,
} from 'helpers/constants';
import { filterOrders, findOrderParcelByType } from 'helpers/utils';
import ActionListDataGrid from './components/ActionListDataGrid';
import ActionListFilters from './components/ActionListFilters';

const DEFAULT_FILTER_STATE = {
    [ORDER_FILTER_TYPE.search]: '',
    [ORDER_FILTER_TYPE.transactionType]: '',
    [ORDER_FILTER_TYPE.orderType]: [],
    [ORDER_FILTER_TYPE.workflowType]: '',
    [ORDER_FILTER_TYPE.orderStatus]: [ORDER_STATUS.open],
    [ORDER_FILTER_TYPE.closeDateStatus]: '',
    [ORDER_FILTER_TYPE.closeDateRange]: [null, null],
};

const DEFAULT_ACTION_FILTER_STATE = {
    [PARCEL_ACTION_FILTER_TYPE.parcelType]: [],
    [PARCEL_ACTION_FILTER_TYPE.parcelStatus]: '',
    [PARCEL_ACTION_FILTER_TYPE.actionType]: '',
};

// NOTE: pretty manual atm but works for the base use cases
// will need to expand programmatically if we want to support more complex rollups
const PARCEL_ACTION_ROLLUP_MAP = {
    // [META_PARCEL_TYPE.closing]: [
    //     PARCEL_TYPE.closing_borrower,
    //     PARCEL_TYPE.closing_buyer,
    //     PARCEL_TYPE.closing_seller,
    //     PARCEL_TYPE.signing_session_in_house,
    //     PARCEL_TYPE.signing_session_mobile_notary,
    //     PARCEL_TYPE.signing_session_electronic_notary,
    // ],
    [PARCEL_TYPE.signing]: [
        PARCEL_TYPE.signing_session_in_house,
        PARCEL_TYPE.signing_session_mobile_notary,
        PARCEL_TYPE.signing_session_electronic_notary,
    ],
    [META_PARCEL_TYPE.balancing]: [
        PARCEL_TYPE.balancing_contract,
        PARCEL_TYPE.balancing_earnest_money,
        PARCEL_TYPE.balancing_taxes,
        PARCEL_TYPE.balancing_hoa,
        PARCEL_TYPE.balancing_home_warranty,
        PARCEL_TYPE.balancing_commissions,
        PARCEL_TYPE.balancing_deed,
        PARCEL_TYPE.balancing_liens,
        PARCEL_TYPE.balancing_title_fees,
        PARCEL_TYPE.balancing_lender_fees,
        PARCEL_TYPE.balancing_other,
        PARCEL_TYPE.balancing_final_cd_hud,
    ],
};

const filterOrderActions = (filteredOrders, actionFilters) => {
    const orderActionList = [];
    let activeActionCount = 0;
    const combinedParcelTypeList = [...actionFilters[PARCEL_ACTION_FILTER_TYPE.parcelType]];

    _.forEach(actionFilters[PARCEL_ACTION_FILTER_TYPE.parcelType], (parcelType) => {
        const metaParcelRollupList = _.get(PARCEL_ACTION_ROLLUP_MAP, parcelType);
        // Add all rollup types to the combined list
        if (metaParcelRollupList) {
            combinedParcelTypeList.push(...metaParcelRollupList);
        }
    });

    _.forEach(filteredOrders, (order) => {
        const activeParcelActions = [];

        // If only one parcel type is selected, allow parcel status & action type filters to apply
        if (combinedParcelTypeList.length === 1) {
            const parcelType = actionFilters.parcelType[0];

            // Verify that parcel is in order
            const parcel = findOrderParcelByType(order, parcelType);
            if (!parcel) {
                return;
            }

            // Parcel has no actions
            if (parcel.active_actions.length === 0) {
                return;
            }

            // Verify the parcel status matches
            if (actionFilters.parcelStatus !== '' && parcel.current_status.status !== actionFilters.parcelStatus) {
                return;
            }

            // Verify the action type matches
            if (actionFilters.actionType !== '') {
                const matchedActionId = _.find(
                    parcel.active_actions,
                    (actionId) => parcel.action_map[actionId].type === actionFilters.actionType
                );
                if (!matchedActionId) {
                    return;
                } else {
                    // Add the specific active action type action
                    activeParcelActions.push({
                        orderId: order.id,
                        parcelId: parcel.id,
                        parcelName: parcel.name,
                        parcelAction: parcel.action_map[matchedActionId],
                    });
                }
            } else {
                // Add all active actions for the specific parcel type
                const parcelActionObjectMap = _.map(parcel.active_actions, (actionId) => {
                    return {
                        orderId: order.id,
                        parcelName: parcel.name,
                        parcelId: parcel.id,
                        parcelAction: parcel.action_map[actionId],
                    };
                });
                activeParcelActions.push(...parcelActionObjectMap);
            }
        } else {
            // Either no parcel type filter is selected or multiple parcel types are selected
            // No parcel status & action type filters are applied

            // Determine which parcels we should check for active actions
            const orderParcelList =
                combinedParcelTypeList.length > 0
                    ? _.filter(order.parcels, (parcel) => _.includes(combinedParcelTypeList, parcel.type))
                    : order.parcels; // Default to all parcels

            // Add all active actions in each parcel to activeParcelActions list
            _.forEach(orderParcelList, (parcel) => {
                if (parcel.active_actions.length > 0) {
                    const parcelActionObjectMap = _.map(parcel.active_actions, (actionId) => {
                        return {
                            orderId: order.id,
                            parcelName: parcel.name,
                            parcelId: parcel.id,
                            parcelAction: parcel.action_map[actionId],
                        };
                    });
                    activeParcelActions.push(...parcelActionObjectMap);
                }
            });

            // Ignore order if no active actions
            if (activeParcelActions.length === 0) {
                return;
            }
        }

        orderActionList.push({
            ...order,
            activeParcelActions,
        });

        activeActionCount += activeParcelActions.length;
    });

    return {
        orderActionList,
        activeActionCount,
    };
};

function ActionList() {
    // const theme = useTheme();

    // Base filters for orders
    const [filters, setFilters] = useState({
        ...DEFAULT_FILTER_STATE,
    });

    // Filters for parcel & parcel actions
    const [actionFilters, setActionFilters] = useState({
        ...DEFAULT_ACTION_FILTER_STATE,
    });

    const { data: orders, isError: orderError, isLoading: orderLoading } = useGetOrdersQuery();

    // Filter orders based on order-specific filters
    const filteredOrders = useMemo(() => filterOrders(orders, filters), [orders, filters]);

    // Filter orders based on parcel/action-specific filters
    const { orderActionList, activeActionCount } = useMemo(() => filterOrderActions(filteredOrders, actionFilters), [
        filteredOrders,
        actionFilters,
    ]);

    // const backgroundUrl = useMemo(
    //     () => generateBackgroundColorSvgUrl(theme.palette.action.main, theme.palette.background.gray),
    //     [theme]
    // );

    const resetFilters = () => {
        setFilters({
            ...DEFAULT_FILTER_STATE,
        });
    };

    const resetActionFilters = () => {
        setActionFilters({
            ...DEFAULT_ACTION_FILTER_STATE,
        });
    };

    const resetAllFilters = () => {
        resetFilters();
        resetActionFilters();
    };

    return (
        <Stack
            spacing={3}
            sx={{
                height: '100%',
                padding: (theme) => theme.spacing(3),
            }}
        >
            <Stack
                direction="column"
                spacing={3}
                sx={
                    {
                        // padding: (theme) => theme.spacing(3),
                        // paddingBottom: (theme) => theme.spacing(12),
                        // margin: (theme) => `${theme.spacing(-3, -3, 0, -3)} !important`,
                        // background: (theme) => theme.palette.background.dark,
                        // backgroundImage: `url(${backgroundUrl})`,
                        // backgroundPosition: 'top center',
                        // backgroundSize: 'cover',
                    }
                }
            >
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h1">Actions</Typography>

                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Avatar
                            sx={{
                                backgroundColor: (theme) =>
                                    activeActionCount > 0
                                        ? theme.palette.primary.main
                                        : theme.palette.background.default,
                                color: (theme) =>
                                    activeActionCount > 0
                                        ? theme.palette.primary.contrastText
                                        : theme.palette.text.primary,
                                height: (theme) => theme.spacing(4),
                                width: (theme) => theme.spacing(4),
                                fontSize: (theme) => theme.spacing(2),
                                fontWeight: 400,
                            }}
                        >
                            {activeActionCount}
                        </Avatar>
                        <Typography variant="h6" fontWeight="400">
                            Active
                        </Typography>
                    </Stack>
                </Stack>

                <OrderListFilters
                    defaultFilters={DEFAULT_FILTER_STATE}
                    filters={filters}
                    setFilters={setFilters}
                    resetFilters={resetAllFilters}
                    hideFilters={[ORDER_FILTER_TYPE.orderStatus]}
                />

                <ActionListFilters
                    filters={actionFilters}
                    defaultFilters={DEFAULT_ACTION_FILTER_STATE}
                    setFilters={setActionFilters}
                    orderList={orders || []}
                />
            </Stack>

            <Box>
                <Box
                    sx={
                        {
                            // marginTop: (theme) => theme.spacing(-12)
                        }
                    }
                >
                    <ActionListDataGrid orderList={orderActionList} loading={orderLoading} />
                </Box>
            </Box>
        </Stack>
    );
}

export default ActionList;
