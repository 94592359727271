import { Checkbox, FormControlLabel, FormGroup, MenuItem, Stack, TextField, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { generateOrderMemberRoleMap, getOrderMemberName } from 'components/Order/Members/utils';
import { ORDER_ROLE_TYPE } from 'helpers/constants';

export function ParcelMemberItem({
    orderMember,
    selected,
    role,
    // party
    handleSelectChange,
    handleRoleChange,
    parcelMemberRoleOptions = _.values(ORDER_ROLE_TYPE), // TODO
    disabled = false,
}) {
    const handleRoleChangeLocal = (event) => {
        handleRoleChange(orderMember.id, event.target.value);
    };
    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <FormControlLabel
                control={<Checkbox checked={selected} name={orderMember.id} onChange={handleSelectChange} />}
                label={getOrderMemberName(orderMember)}
                sx={{ flexGrow: 1 }}
                disabled={disabled}
            />

            <Stack direction="row" alignItems="center" spacing={1}>
                {selected && false && (
                    <TextField
                        name="role"
                        label="Role"
                        value={role}
                        onChange={handleRoleChangeLocal}
                        style={{ minWidth: '150px' }}
                        size="small"
                        select
                        required
                    >
                        {_.map(parcelMemberRoleOptions, (name, index) => (
                            <MenuItem key={name} value={name}>
                                {_.startCase(name)}
                            </MenuItem>
                        ))}
                    </TextField>
                )}
            </Stack>
        </Stack>
    );
}

ParcelMemberItem.propTypes = {
    orderMember: PropTypes.object.isRequired,
    selected: PropTypes.bool.isRequired,
    handleSelectChange: PropTypes.func.isRequired,
    handleRoleChange: PropTypes.func.isRequired,
    role: PropTypes.string,
    parcelMemberRoleOptions: PropTypes.array,
    disabled: PropTypes.bool,
};

function ParcelMemberRoleGroup({ role, roleOrderMembers, parcelMembers, handleSelectChange, handleRoleChange }) {
    return (
        <Stack direction="column" spacing={1} key={role} sx={{ marginBottom: (theme) => theme.spacing(3) }}>
            <Typography variant="sectionHeader" color="text.primary">
                {_.startCase(role)}
            </Typography>
            {_.map(roleOrderMembers, (orderMember) => {
                const parcelMember = _.find(parcelMembers, { order_member_id: orderMember.id });

                return (
                    <ParcelMemberItem
                        key={orderMember.id}
                        orderMember={orderMember}
                        handleSelectChange={handleSelectChange}
                        handleRoleChange={handleRoleChange}
                        selected={!!parcelMember}
                        role={parcelMember?.role || ''}
                    />
                );
            })}
        </Stack>
    );
}

ParcelMemberRoleGroup.propTypes = {
    role: PropTypes.string.isRequired,
    roleOrderMembers: PropTypes.array.isRequired,
    parcelMembers: PropTypes.array.isRequired,
    handleSelectChange: PropTypes.func.isRequired,
    handleRoleChange: PropTypes.func.isRequired,
};

const DEFAULT_ALLOWED_ORDER_ROLE_TYPES = _.values(ORDER_ROLE_TYPE);

const ParcelMemberCheckboxField = ({
    parcelMembers,
    orderMembers,
    setParcelMembers,
    allowedRoleTypes = [...DEFAULT_ALLOWED_ORDER_ROLE_TYPES],
}) => {
    // TODO verify & handle legal entity member grouping
    // Only show roles that are allowed to be assigned (buyer & assignor)
    const orderMemberRoleMap = useMemo(
        () =>
            generateOrderMemberRoleMap(
                orderMembers,
                [],
                _.filter(ORDER_ROLE_TYPE, (role) => !_.includes(allowedRoleTypes, role))
            ),
        [orderMembers]
    );

    const handleMemberSelectChange = (event) => {
        const { name: orderMemberId, checked } = event.target;

        if (checked) {
            setParcelMembers([
                ...parcelMembers,
                {
                    order_member_id: orderMemberId,
                    // role: '',
                    // party: '', // TODO
                },
            ]);
        } else {
            setParcelMembers(parcelMembers.filter((member) => member.order_member_id !== orderMemberId));
        }
    };

    const handleMemberRoleChange = (orderMemberId, role) => {
        const memberIndex = _.findIndex(parcelMembers, { order_member_id: orderMemberId });

        if (memberIndex === -1) {
            console.warn(`Could not find parcel member with order member id ${orderMemberId}`);
            return;
        }

        const updatedParcelMembers = [...parcelMembers];
        updatedParcelMembers[memberIndex] = {
            ...updatedParcelMembers[memberIndex],
            role,
            // TODO party
        };

        setParcelMembers(updatedParcelMembers);
    };

    return (
        <FormGroup>
            {_.map(orderMemberRoleMap, (roleOrderMembers, role) => {
                return (
                    <ParcelMemberRoleGroup
                        key={role}
                        role={role}
                        roleOrderMembers={roleOrderMembers}
                        parcelMembers={parcelMembers}
                        handleSelectChange={handleMemberSelectChange}
                        handleRoleChange={handleMemberRoleChange}
                    />
                );
            })}
        </FormGroup>
    );
};

ParcelMemberCheckboxField.propTypes = {
    parcelMembers: PropTypes.array.isRequired,
    orderMembers: PropTypes.array.isRequired,
    setParcelMembers: PropTypes.func.isRequired,
    allowedRoleTypes: PropTypes.array,
};

export default ParcelMemberCheckboxField;
