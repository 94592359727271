import { useParams } from 'react-router-dom';

import ParcelClosingParent from 'components/ParcelClosing/ParcelClosingParent';

// TODO move into /Parcel page folder?
function ClosingEntityPage({ orderId }) {
    let { parcel_id: parcelId } = useParams();

    return <ParcelClosingParent orderId={orderId} parcelId={parcelId} />;
}

export default ClosingEntityPage;
