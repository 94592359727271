import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import CreateParcelMemberForm from './CreateParcelMemberForm';

const CreateParcelMember = ({ parcel, parcelMembers, orderMembers, handleSubmit, title = 'Add Parcel Member' }) => {
    return (
        <Stack spacing={4} sx={{ width: '480px' }}>
            <Stack direction="column" spacing={2}>
                <Typography variant="h5">{title}</Typography>
            </Stack>

            <CreateParcelMemberForm
                parcel={parcel}
                parcelMembers={parcelMembers}
                orderMembers={orderMembers}
                handleSubmit={handleSubmit}
            />
        </Stack>
    );
};

CreateParcelMember.propTypes = {
    parcel: PropTypes.object.isRequired,
    parcelMembers: PropTypes.array.isRequired,
    orderMembers: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    titleText: PropTypes.string,
};

export default CreateParcelMember;
