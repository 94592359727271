import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
// import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
// import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CloudUploadIcon from '@mui/icons-material/CloudUpload'; //  electronic notaray
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation'; // mobile notary
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import Groups3Icon from '@mui/icons-material/Groups3'; //  in person
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import HomeWorkIcon from '@mui/icons-material/HomeWork'; // in house
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Icon, Stack, Tooltip, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { useGetOrderQuery } from 'api/order';
import { useGetParcelMembersByOrderMemberIdQuery } from 'api/parcelMember';
import { PARCEL_STATUS, SIGNING_METHOD, SIGNING_METHOD_PARCEL_MAP, SIGNING_PARCEL_TYPES } from 'helpers/constants';
import { formatAddress } from 'helpers/utils';

const SchedulingDetailsIcon = ({ schedulingMemberParcel, signingSessionParcel }) => {
    const schedulingStatus = schedulingMemberParcel.current_status.status;
    const isComplete = schedulingStatus === PARCEL_STATUS.complete;
    const isNotStarted = schedulingStatus === PARCEL_STATUS.not_started;

    if (isNotStarted) {
        return (
            <Tooltip title="Scheduling: Not Started" placement="bottom" enterDelay={300}>
                <EventBusyIcon sx={{ color: 'text.secondary' }} />
            </Tooltip>
        );
    }

    if (isComplete) {
        return (
            <Tooltip title={`Scheduling: ${_.startCase(schedulingStatus)}`} placement="bottom" enterDelay={300}>
                <EventAvailableIcon sx={{ color: 'text.primary' }} />
            </Tooltip>
        );
    }

    return (
        <Tooltip title={`Scheduling: ${_.startCase(schedulingStatus)}`} placement="bottom" enterDelay={300}>
            <EventBusyIcon sx={{ color: 'text.secondary' }} />
        </Tooltip>
    );
};

SchedulingDetailsIcon.propTypes = {
    schedulingMemberParcel: PropTypes.object.isRequired,
    signingSessionParcel: PropTypes.object,
};

const SigningDetailsIcon = ({ signingSessionParcel }) => {
    if (!signingSessionParcel) {
        return (
            <Tooltip title="Signing: Not Scheduled" placement="bottom" enterDelay={300}>
                <HistoryEduIcon sx={{ color: 'text.secondary' }} />
            </Tooltip>
        );
    }

    const signingStatus = signingSessionParcel.current_status.status;
    const isComplete = signingStatus === PARCEL_STATUS.complete;

    if (isComplete) {
        return (
            <Tooltip title="Signing: Complete" placement="bottom" enterDelay={300}>
                <HistoryEduIcon sx={{ color: 'text.primary' }} />
            </Tooltip>
        );
    }

    return (
        <Tooltip title={`Signing: ${_.startCase(signingStatus)}`} placement="bottom" enterDelay={300}>
            <HistoryEduIcon sx={{ color: 'text.secondary' }} />
        </Tooltip>
    );
};

SigningDetailsIcon.propTypes = {
    signingSessionParcel: PropTypes.object,
};

const SIGNING_METHOD_ICON_MAP = {
    [SIGNING_METHOD.electronic_notary]: CloudUploadIcon,
    [SIGNING_METHOD.office]: Groups3Icon,
    [SIGNING_METHOD.in_house]: HomeWorkIcon,
    [SIGNING_METHOD.mobile_notary]: EmojiTransportationIcon,
    // TODO default?
};

const SigningMethodIcon = ({ signingSessionParcel }) => {
    const signingParcelType = signingSessionParcel?.type;
    const signingMethod = useMemo(() => {
        if (!signingParcelType) {
            return null;
        }

        return _.findKey(SIGNING_METHOD_PARCEL_MAP, (value) => value === signingParcelType);
    }, [signingParcelType]);

    if (!signingSessionParcel || !signingMethod) {
        return null;
    }

    return (
        <Tooltip title={signingSessionParcel.name} placement="bottom" enterDelay={300}>
            <Icon component={SIGNING_METHOD_ICON_MAP[signingMethod]} sx={{ color: 'text.primary' }} />
        </Tooltip>
    );
};

SigningMethodIcon.propTypes = {
    signingSessionParcel: PropTypes.object,
};

const SigningTimeIcon = ({ signingSessionParcel }) => {
    if (!signingSessionParcel) {
        return null;
    }

    const additionalData = signingSessionParcel?.additional_data;
    const time = additionalData?.signing_time ? parseISO(additionalData.signing_time) : null;

    if (!time) {
        return null;
    }

    return (
        <Tooltip
            title={
                <Stack spacing={0}>
                    <Box>{format(time, 'p')}</Box>
                    <Box>{format(time, 'P')}</Box>
                </Stack>
            }
            placement="bottom"
            enterDelay={300}
        >
            <AccessTimeFilledIcon sx={{ color: 'text.primary' }} />
        </Tooltip>
    );
};

SigningTimeIcon.propTypes = {
    signingSessionParcel: PropTypes.object,
};

const SigningLocationIcon = ({ signingSessionParcel }) => {
    if (!signingSessionParcel) {
        return null;
    }

    const additionalData = signingSessionParcel?.additional_data;
    const location = additionalData?.signing_location;

    if (!location) {
        return null;
    }

    return (
        <Tooltip
            title={
                <Box sx={{ overflow: 'hidden' }}>
                    <Typography
                        fontWeight="inherit"
                        fontSize="inherit"
                        color="inherit"
                        lineHeight="inherit"
                        sx={{
                            whiteSpace: 'pre-line',
                            textAlign: 'left',
                        }}
                    >
                        {formatAddress(location, true)}
                    </Typography>
                </Box>
            }
            placement="bottom"
            enterDelay={300}
        >
            <LocationOnIcon sx={{ color: 'text.primary' }} />
        </Tooltip>
    );
};

SigningLocationIcon.propTypes = {
    signingSessionParcel: PropTypes.object,
};

// TODO consolidate logic to find signing session by order_member_id
const SchedulingDetailsColumn = ({ orderId, parcel }) => {
    const { data: order } = useGetOrderQuery(orderId);
    const orderMemberId = _.get(parcel, 'additional_data.order_member_id');
    const { data: parcelMembers } = useGetParcelMembersByOrderMemberIdQuery({ orderId, orderMemberId });

    const orderMemberSigningSession = useMemo(() => {
        if (!order || !parcelMembers || parcelMembers.length === 0) {
            return null;
        }

        const parcelMemberMap = _.keyBy(parcelMembers, 'parcel_id');
        let signingSession = null;
        _.forEach(order.parcels, (orderParcel) => {
            if (orderParcel.id in parcelMemberMap && SIGNING_PARCEL_TYPES.includes(orderParcel.type)) {
                signingSession = orderParcel;
                return;
            }
        });
        return signingSession;
    }, [order, parcelMembers]);

    if (!orderMemberId) {
        return false;
    }

    return (
        <Stack direction="row" spacing={3}>
            <Stack direction="row" alignItems="center" spacing={1}>
                <SchedulingDetailsIcon
                    schedulingMemberParcel={parcel}
                    signingSessionParcel={orderMemberSigningSession}
                />

                <SigningDetailsIcon signingSessionParcel={orderMemberSigningSession} />
            </Stack>

            <Stack direction="row" alignItems="center" spacing={1}>
                <SigningMethodIcon signingSessionParcel={orderMemberSigningSession} />

                <SigningTimeIcon signingSessionParcel={orderMemberSigningSession} />

                <SigningLocationIcon signingSessionParcel={orderMemberSigningSession} />
            </Stack>
        </Stack>
    );
};

SchedulingDetailsColumn.propTypes = {
    orderId: PropTypes.string.isRequired,
    parcel: PropTypes.object.isRequired,
};

export default SchedulingDetailsColumn;
