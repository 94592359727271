import _ from 'lodash';
import { ENTITY_TYPE } from '../helpers/constants';
import { ORDER_TAG } from './order';
import { rootApi } from './root';

export const PARCEL_MEMBER_TAG = _.toUpper(ENTITY_TYPE.parcel_member);

export const parcelMemberApi = rootApi.injectEndpoints({
    reducerPath: 'parcelMemberApi',
    endpoints: (builder) => ({
        getOrderParcelMembers: builder.query({
            query: (orderId) => `order/${orderId}/parcels/members`,
            providesTags: [PARCEL_MEMBER_TAG],
        }),
        createParcelMember: builder.mutation({
            query: ({orderId, parcelId, memberData}) => ({
                url: `order/${orderId}/parcel/${parcelId}/members`,
                method: 'POST',
                body: memberData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [ORDER_TAG, PARCEL_MEMBER_TAG],
        }),
        updateParcelMember: builder.mutation({
            query: ({orderId, parcelId, memberId, memberData}) => ({
                url: `order/${orderId}/parcel/${parcelId}/member/${memberId}`,
                method: 'PUT',
                body: memberData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            async onQueryStarted({ orderId, parcelId, memberId, memberData }, { dispatch, queryFulfilled }) {
                const optimisticUpdateResult = dispatch(
                    parcelMemberApi.util.updateQueryData('getParcelMembers', orderId, (draft) => {
                        const member = draft.find((member) => member.id === memberId && member.parcel_id === parcelId);
                        Object.assign(member, {
                            ...member,
                            ...memberData,
                        })
                    })
                )

                try {
                    await queryFulfilled
                } catch {
                    optimisticUpdateResult.undo()
                }
            },
            invalidatesTags: [ORDER_TAG, PARCEL_MEMBER_TAG],
        }),
        deleteParcelMember: builder.mutation({
            query: ({orderId, parcelId, memberId}) => ({
                url: `order/${orderId}/parcel/${parcelId}/member/${memberId}`,
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [ORDER_TAG, PARCEL_MEMBER_TAG],
        }),
    }),
    overrideExisting: false,
})


export function useGetParcelMemberQuery({orderId, memberId}) {
    const { useGetOrderParcelMembersQuery } = parcelMemberApi;
    return useGetOrderParcelMembersQuery(orderId, {
        selectFromResult: ({ data, error, isLoading }) => ({
            data: data?.find((member) => member.id === memberId),
            error,
            isLoading
        }),
    });
}


export function useGetParcelMembersQuery({orderId, parcelId}) {
    const { useGetOrderParcelMembersQuery } = parcelMemberApi;
    return useGetOrderParcelMembersQuery(orderId, {
        selectFromResult: ({ data, error, isLoading }) => ({
            data: data?.filter((member) => member.parcel_id === parcelId),
            error,
            isLoading
        }),
    });
}


export function useGetParcelMembersByOrderMemberIdQuery({orderId, orderMemberId}) {
    const { useGetOrderParcelMembersQuery } = parcelMemberApi;
    return useGetOrderParcelMembersQuery(orderId, {
        selectFromResult: ({ data, error, isLoading }) => ({
            data: data?.filter((member) => member.order_member_id === orderMemberId),
            error,
            isLoading
        }),
    });
}


export const { useGetOrderParcelMembersQuery, useCreateParcelMemberMutation, useUpdateParcelMemberMutation, useDeleteParcelMemberMutation } = parcelMemberApi