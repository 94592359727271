import { TabList } from '@mui/lab';
import { Tabs } from '@mui/material/';
import { styled } from '@mui/material/styles';
import _ from 'lodash';

export function generateCoreTabsStyles({ theme, indicatorColor }) {
    let color = _.get(theme.palette, indicatorColor, theme?.palette?.[indicatorColor]?.main);
    if (typeof color === 'object') {
        // If retrieved color is an object, get the main color
        // ex: primary -> primary.main
        color = color.main;
    }

    return {
        borderBottom: `1px solid ${theme.palette.divider}`,
        '& .MuiTabs-indicator': {
            height: '4px',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
            background: color,
        },
    };
}

export const StyledTabs = styled((props) => <Tabs {...props} />)(generateCoreTabsStyles);

export const StyledTabList = styled((props) => <TabList {...props} />)(generateCoreTabsStyles);

export default StyledTabs;
