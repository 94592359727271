import PropTypes from 'prop-types';

import { useGetOrderQuery } from 'api/order';
import ParcelDocumentList from 'components/Order/Parcel/components/ParcelDocumentList';

const SigningSessionDocumentList = ({ orderId, parcel }) => {
    const { data: order, isLoading: orderLoading } = useGetOrderQuery(orderId);

    return (
        <ParcelDocumentList
            order={order}
            parcel={parcel}
            paperProps={{
                variant: 'elevation',
                elevation: 0,
                sx: {
                    b: 0,
                },
            }}
        />
    );
};

SigningSessionDocumentList.propTypes = {
    orderId: PropTypes.string.isRequired,
    parcel: PropTypes.object.isRequired,
};

export default SigningSessionDocumentList;
