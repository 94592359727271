import { useMemo } from 'react';

import { useGetOrderQuery } from 'api/order';
import { useGetParcelMembersQuery } from 'api/parcelMember';
import { findChildParcelsInOrder, findParcelInOrder } from 'helpers/utils';

function useParcelData(orderId, parcelId) {
    const { data: order, isLoading: isOrderLoading, isFetching: isOrderFetching } = useGetOrderQuery(orderId);
    const {
        data: parcelMembers,
        isLoading: isParcelMembersLoading,
        isFetching: isParcelMembersFetching,
    } = useGetParcelMembersQuery({
        orderId,
        parcelId,
    });

    const { parcel, childParcels, parentParcel } = useMemo(() => {
        // NOTE: each function iterates over all parcels. This could be combined into a single loop,
        // however the list of parcels is small enough that this is not a concern
        if (order && parcelId) {
            const parcelEntityMap = {
                parcel: findParcelInOrder(order, parcelId),
                childParcels: findChildParcelsInOrder(order, parcelId),
                parentParcel: null,
            };

            const { parent_parcel_id } = parcelEntityMap.parcel;

            if (!!parent_parcel_id) {
                parcelEntityMap.parentParcel = findParcelInOrder(order, parent_parcel_id);
            }

            return parcelEntityMap;
        }

        return {};
    }, [order, parcelId]);

    const isLoading = isOrderLoading || isParcelMembersLoading;
    const isFetching = isOrderFetching || isParcelMembersFetching;

    return {
        order,
        parcel,
        parentParcel,
        childParcels,
        parcelMembers,
        isLoading,
        isFetching,
    };
}

export default useParcelData;
