import { Chip } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { PARCEL_STATUS } from 'helpers/constants';

const STATUS_COLOR_MAP = {
    [PARCEL_STATUS.on_hold]: 'warning',
    [PARCEL_STATUS.not_started]: 'default',
    [PARCEL_STATUS.in_progress]: 'info',
    [PARCEL_STATUS.complete]: 'primary',
    [PARCEL_STATUS.canceled]: 'warning',
};

function ParcelStatusChip({
    status,
    statusColorMap = { ...STATUS_COLOR_MAP },
    chipProps = {},
    defaultColor = 'default',
}) {
    const statusPalette = _.get(statusColorMap, status, defaultColor);

    // Color override for default status
    if (statusPalette === 'default') {
        return (
            <Chip
                label={_.startCase(status)}
                color="default"
                {...chipProps}
                sx={{
                    borderRadius: (theme) => theme.spacing(0.5),
                    ..._.get(chipProps, 'sx', {}),
                }}
            />
        );
    }

    return (
        <Chip
            label={_.startCase(status)}
            {...chipProps}
            sx={{
                backgroundColor: (theme) => theme.palette[statusPalette].light,
                color: (theme) => theme.palette[statusPalette].dark,
                borderRadius: (theme) => theme.spacing(0.5),
                ..._.get(chipProps, 'sx', {}),
            }}
        />
    );
}

ParcelStatusChip.propTypes = {
    status: PropTypes.oneOf([..._.values(PARCEL_STATUS)]).isRequired,
    statusColorMap: PropTypes.object,
    chipProps: PropTypes.object,
    defaultColor: PropTypes.string,
};

export default ParcelStatusChip;
