// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import HomeIcon from '@mui/icons-material/Home';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, Breadcrumbs, Divider, LinearProgress, Link as MUILink, Paper, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { MobileDatePicker } from '@mui/x-date-pickers-pro';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useGetOrderQuery, useUpdateOrderMutation } from 'api/order';
import { useGetOrderParcelMembersQuery } from 'api/parcelMember';
import { generateBackgroundColorSvgUrl } from 'components/common/BackgroundColorSvg';
import QuickEditField from 'components/common/form/order/QuickEditField';
import { ORDER_STATUS } from 'helpers/constants';
import { formatAddress } from 'helpers/utils';
import ClosingAvatar from './components/ClosingAvatar';
import EditOrderModal from './components/EditOrderModal';
import OrderMeta from './components/OrderMeta';
import OrderStatusSelect from './components/OrderStatusSelect';
import OrderTagList from './components/OrderTagList';

// TODO
// quick close date component

function OrderDetailsHeaderBar({ order, formattedAddress, handleNavigate, handleQuickEditChange, minimize = false }) {
    const theme = useTheme();

    const backgroundColor = theme.palette[_.get(order, 'transaction_type', 'default')];
    const backgroundUrl = useMemo(() => generateBackgroundColorSvgUrl(backgroundColor.main, backgroundColor.dark), [
        backgroundColor,
    ]);

    if (!order) {
        return null;
    }

    return (
        <Box>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
                sx={{
                    padding: (theme) => theme.spacing(3),
                    paddingBottom: (theme) => theme.spacing(minimize ? 3 : 30),
                    margin: (theme) => `${theme.spacing(-3, -3, 0, -3)} !important`,
                    background: (theme) => theme.palette.background.dark,
                    backgroundImage: `url(${backgroundUrl})`,
                    backgroundPosition: 'top center',
                    backgroundSize: 'cover',
                }}
            >
                {minimize ? (
                    <Stack direction="row" alignItems="center" spacing={4}>
                        <Typography
                            variant="body1"
                            onClick={() => handleNavigate()}
                            sx={{
                                fontWeight: '400',
                                color: (theme) => theme.palette.text.light,
                                '& .back-icon': {
                                    display: 'none',
                                },
                                '&:hover': {
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                    '& .back-icon': {
                                        display: 'inline',
                                    },
                                    '& .home-icon': {
                                        display: 'none',
                                    },
                                },
                            }}
                        >
                            <Stack direction="row" alignItems="center" justifyContent="space-between" component="span">
                                <KeyboardBackspaceOutlinedIcon
                                    className="back-icon"
                                    fontSize="small"
                                    sx={{ marginRight: (theme) => theme.spacing(1) }}
                                />
                                <HomeIcon
                                    className="home-icon"
                                    fontSize="small"
                                    sx={{ marginRight: (theme) => theme.spacing(1) }}
                                />
                                <span>{order.qualia_order_number}</span>
                            </Stack>
                        </Typography>

                        <a
                            href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(formattedAddress)}`}
                            target="_blank"
                            style={{
                                textDecoration: 'none',
                                color: 'inherit',
                            }}
                        >
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <LocationOnIcon
                                    fontSize="small"
                                    sx={{
                                        color: (theme) => theme.palette.text.light,
                                    }}
                                />
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontWeight: '400',
                                        color: (theme) => theme.palette.text.light,
                                        '&:hover': {
                                            textDecoration: 'underline',
                                        },
                                    }}
                                >
                                    {order.property_address.street}
                                </Typography>
                            </Stack>
                        </a>
                    </Stack>
                ) : (
                    <Breadcrumbs
                        aria-label="breadcrumb"
                        sx={{
                            color: (theme) => theme.palette.text.light,
                            '& .MuiBreadcrumbs-separator': {
                                opacity: 0.75,
                            },
                        }}
                    >
                        <MUILink
                            component={Link}
                            underline="hover"
                            to="/orders"
                            color="inherit"
                            sx={{
                                opacity: 0.75,
                            }}
                        >
                            Orders
                        </MUILink>
                        <MUILink
                            component={Link}
                            underline="hover"
                            color="inherit"
                            to={`/orders?transactionType=${order.transaction_type}`}
                            sx={{
                                opacity: 0.75,
                            }}
                        >
                            {_.startCase(order.transaction_type)}s
                        </MUILink>
                        <Typography
                            color="inherit"
                            sx={{
                                color: (theme) => theme.palette.text.light,
                                opacity: 1,
                            }}
                        >
                            {order.qualia_order_number}
                        </Typography>
                    </Breadcrumbs>
                )}

                <Stack direction="row" spacing={2} alignItems="center">
                    {order.status === ORDER_STATUS.open && (
                        <>
                            <ClosingAvatar status={order.status} closeDate={order.close_date} color="#fafafa" />

                            <Divider
                                orientation="vertical"
                                variant="middle"
                                flexItem
                                sx={{
                                    marginTop: (theme) => `${theme.spacing(1)} !important`,
                                    marginBottom: (theme) => `${theme.spacing(1)} !important`,
                                }}
                            />
                        </>
                    )}

                    <MobileDatePicker
                        autoOk
                        closeOnSelect
                        openTo="day"
                        inputFormat="MM/dd/yyyy"
                        value={order.close_date ? parseISO(order.close_date) : null}
                        inputVariant="outlined"
                        onChange={(date) => {
                            // NOTE: not strict comparison due to issues with date error response object
                            if (date && date != 'Invalid Date') {
                                handleQuickEditChange('close_date', format(date, 'yyyy-MM-dd'));
                            } else {
                                handleQuickEditChange('close_date', null);
                            }
                        }}
                        renderInput={(params) => (
                            <QuickEditField
                                name="close_date"
                                size="small"
                                value={params.inputProps.value}
                                onChange={params.inputProps.onChange}
                                type="text"
                                selectOnFocus={false}
                                {...params}
                                sx={{
                                    '& .edit-icon': {
                                        display: 'none',
                                    },
                                    '&:hover': {
                                        '& .edit-icon': {
                                            display: 'inline',
                                        },
                                        '& .base-icon': {
                                            display: 'none',
                                        },
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(0, 0, 0, 0)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: (theme) => `${theme.palette.text.light} !important`,
                                    },
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    sx: {
                                        color: (theme) => theme.palette.text.light,
                                    },
                                    endAdornment: (
                                        <FiberManualRecordIcon
                                            fontSize="small"
                                            sx={{
                                                color: (theme) =>
                                                    order.confirmed_close_date
                                                        ? theme.palette.success.main
                                                        : theme.palette.warning.main,
                                            }}
                                        />
                                    ),
                                    inputProps: {
                                        ...params.InputProps.inputProps,
                                    },
                                }}
                            />
                        )}
                        clearable
                    />

                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{
                            marginTop: (theme) => `${theme.spacing(1)} !important`,
                            marginBottom: (theme) => `${theme.spacing(1)} !important`,
                        }}
                    />

                    <OrderStatusSelect order={order} color="light" />
                </Stack>
            </Stack>
        </Box>
    );
}

function OrderDetails({ orderId, minimize = false }) {
    const navigate = useNavigate();

    const { data: order, isError: orderError, isLoading: orderLoading, isFetching: orderFetching } = useGetOrderQuery(
        orderId
    );
    const [updateOrder, { isLoading: updateOrderLoading }] = useUpdateOrderMutation();
    const {
        data: parcelMembers,
        isError: parcelMembersError,
        isLoading: parcelMembersLoading,
    } = useGetOrderParcelMembersQuery(orderId); // TODO move to higher level component
    // const { data: membersData, isError: membersError, isLoading: membersLoading } = useGetOrderMembersQuery(orderId);

    const [openDialog, setOpenDialog] = useState(false);
    const [formattedAddress, setFormattedAddress] = useState();

    useEffect(() => {
        if (order) {
            const addressStr = formatAddress(order.property_address);
            setFormattedAddress(addressStr);
        }
    }, [order]);

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleNavigate = () => {
        navigate(`/order/${order.id}`);
    };

    const handleQuickEditChange = (name, value) => {
        handleOrderUpdate({
            [name]: value,
        });
    };

    const handleOrderUpdate = async (updatedOrderData) => {
        const updateOrderPayload = {
            orderId: order.id,
            orderData: updatedOrderData,
        };

        const { data } = await updateOrder(updateOrderPayload);
        return !!data;
    };

    if (orderError) {
        return <div>Error loading order</div>;
    }

    const loading = orderLoading || updateOrderLoading || orderFetching;
    const showFullHeader = !minimize && order;

    return (
        <Stack spacing={0}>
            {loading && (
                <LinearProgress
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                    }}
                />
            )}

            <OrderDetailsHeaderBar
                order={order}
                formattedAddress={formattedAddress}
                handleNavigate={handleNavigate}
                handleQuickEditChange={handleQuickEditChange}
                minimize={minimize}
                isFetching={orderFetching}
            />

            {showFullHeader && (
                <Box
                    sx={{
                        paddingTop: (theme) => theme.spacing(3),
                        marginTop: (theme) => (minimize ? theme.spacing(3) : theme.spacing(-25)),
                    }}
                >
                    <Stack spacing={6} alignItems="center">
                        <Stack alignItems="center" spacing={1}>
                            <Typography
                                variant="h1"
                                fontWeight={500}
                                sx={{
                                    color: (theme) => theme.palette.text.light,
                                }}
                            >
                                {order.qualia_order_number}
                            </Typography>

                            <a
                                href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(formattedAddress)}`}
                                target="_blank"
                                style={{
                                    textDecoration: 'none',
                                    color: 'inherit',
                                }}
                            >
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                    sx={{
                                        color: (theme) => theme.palette.text.light,
                                        opacity: 0.75,
                                    }}
                                >
                                    <LocationOnIcon />
                                    <Typography
                                        variant="h4"
                                        align="center"
                                        fontWeight={400}
                                        sx={{
                                            color: (theme) => theme.palette.text.light,
                                            '&:hover': {
                                                textDecoration: 'underline',
                                            },
                                        }}
                                    >
                                        {formattedAddress}
                                    </Typography>
                                </Stack>
                            </a>
                        </Stack>

                        {false && <OrderTagList order={order} />}

                        <Paper
                            variant="outlined"
                            sx={{
                                padding: (theme) => theme.spacing(3),
                                borderRadius: (theme) => theme.spacing(0.5),
                                // width: '100%',
                            }}
                        >
                            <OrderMeta order={order} />
                        </Paper>
                    </Stack>

                    <EditOrderModal order={order} open={openDialog} handleClose={handleClose} />
                </Box>
            )}
        </Stack>
    );
}

OrderDetails.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default OrderDetails;
