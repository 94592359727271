import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import PropTypes from 'prop-types';

import customFields from './fields';
import customWidgets from './widgets';

const DataForm = ({ data, schema, onChange, onSubmit, readOnly = false, children, ...rest }) => {
    const { json_schema: jsonSchema, ui_schema } = schema;

    const parentTheme = useTheme();
    const extendedTheme = createTheme(parentTheme, {
        components: {
            MuiTextField: {
                defaultProps: {
                    size: 'small',
                },
            },
            MuiSelect: {
                defaultProps: {
                    size: 'small',
                },
            },
            MuiCheckbox: {
                defaultProps: {
                    size: 'small',
                },
            },
            // Add other MUI components as needed
        },
    });

    const uiSchema = {
        'ui:options': {
            size: 'small',
        },
        ...ui_schema,
        'ui:readonly': readOnly,
    };

    return (
        <ThemeProvider theme={extendedTheme}>
            <Form
                schema={jsonSchema}
                uiSchema={uiSchema}
                validator={validator}
                fields={customFields}
                widgets={customWidgets}
                formData={data || {}}
                onChange={onChange}
                onSubmit={onSubmit}
                onError={(e) => console.log('errors', e)}
                omitExtraData={true}
                liveOmit={false}
                {...rest}
            >
                {children && children}
            </Form>
        </ThemeProvider>
    );
};

DataForm.propTypes = {
    data: PropTypes.object.isRequired,
    schema: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
};

export default DataForm;
