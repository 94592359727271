import { Stack } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import useOrderMemberData from 'components/common/hooks/useOrderMemberData';
import useTriggers from 'components/common/hooks/useTriggers';
import { ConfirmTriggerIconButton } from 'components/Order/Parcel/components/ParcelTriggerList';

const SchedulingTriggerColumn = ({ parcel }) => {
    const { triggers, activeTriggers } = useTriggers(parcel);
    const { name } = useOrderMemberData(parcel?.order_id, _.get(parcel, 'additional_data.order_member_id'));

    if (!triggers || triggers.length === 0 || activeTriggers.length === 0) {
        return null;
    }

    return (
        <Stack spacing={2} direction="row">
            {_.map(triggers, (trigger, triggerKey) => {
                const isActive = _.includes(activeTriggers, triggerKey);
                return (
                    <ConfirmTriggerIconButton
                        key={triggerKey}
                        parcel={parcel}
                        trigger={trigger}
                        isActive={isActive}
                        dialogProps={{
                            title: `${_.startCase(triggerKey)} ${name}`,
                            body: `Are you sure you want to ${_.lowerCase(triggerKey)} this member?`,
                        }}
                        buttonProps={{ edge: 'end' }}
                    />
                );
            })}
        </Stack>
    );
};

SchedulingTriggerColumn.propTypes = {
    parcel: PropTypes.object.isRequired,
};

export default SchedulingTriggerColumn;
