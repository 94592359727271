import { Tab } from '@mui/material/';
import { styled } from '@mui/material/styles';
import _ from 'lodash';

const determineThemeColor = (theme, colorPath, fallback = 'inherit') => {
    let color = _.get(theme.palette, colorPath, fallback);
    if (typeof color === 'object') {
        // If retrieved color is an object, get the main color
        // ex: primary -> primary.main
        color = color.main;
    }
    return color;
};

const StyledTab = styled((props) => <Tab disableRipple {...props} />, {
    shouldForwardProp: (prop) => !_.includes(['textColor', 'secondaryColor'], prop),
})(({ theme, textColor, secondaryColor }) => {
    const color = determineThemeColor(theme, textColor, 'inherit');
    const unselectedColor = determineThemeColor(theme, secondaryColor, 'inherit');

    return {
        textTransform: 'uppercase',
        fontWeight: 600,
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        marginRight: theme.spacing(3),
        minWidth: 'auto',
        color: unselectedColor,
        // opacity: secondaryColor ? 1 : 0.6,

        '&.Mui-selected': {
            color,
        },
        '&:hover': {
            color,
            opacity: 1,
        },
    };
});

export default StyledTab;
