import { Checkbox, FormControlLabel, FormGroup, MenuItem, Stack, TextField, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { generateOrderMemberRoleMap, getOrderMemberName } from 'components/Order/Members/utils';
import { ASSIGNMENT_MEMBER_ALLOWED_ORDER_ROLES, ASSIGNMENT_MEMBER_ROLE, ORDER_ROLE_TYPE } from 'helpers/constants';

export function AssignmentMemberItem({
    orderMember,
    selected,
    role,
    // party
    handleSelectChange,
    handleRoleChange,
    assignmentMemberRoleOptions = _.values(ASSIGNMENT_MEMBER_ROLE),
    disabled = false,
}) {
    const handleRoleChangeLocal = (event) => {
        handleRoleChange(orderMember.id, event.target.value);
    };
    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
            <FormControlLabel
                control={<Checkbox checked={selected} name={orderMember.id} onChange={handleSelectChange} />}
                label={getOrderMemberName(orderMember)}
                sx={{ flexGrow: 1 }}
                disabled={disabled}
            />

            <Stack direction="row" alignItems="center" spacing={1}>
                {selected && (
                    <TextField
                        name="role"
                        label="Role"
                        value={role}
                        onChange={handleRoleChangeLocal}
                        style={{ minWidth: '150px' }}
                        size="small"
                        select
                        required
                    >
                        {_.map(assignmentMemberRoleOptions, (name, index) => (
                            <MenuItem key={name} value={name}>
                                {_.startCase(name)}
                            </MenuItem>
                        ))}
                    </TextField>
                )}
            </Stack>
        </Stack>
    );
}

AssignmentMemberItem.propTypes = {
    orderMember: PropTypes.object.isRequired,
    selected: PropTypes.bool.isRequired,
    handleSelectChange: PropTypes.func.isRequired,
    handleRoleChange: PropTypes.func.isRequired,
    role: PropTypes.string,
    assignmentMemberRoleOptions: PropTypes.array,
    disabled: PropTypes.bool,
};

function AssignmentMemberRoleGroup({
    role,
    roleOrderMembers,
    assignmentMembers,
    handleSelectChange,
    handleRoleChange,
}) {
    return (
        <Stack direction="column" spacing={1} key={role} sx={{ marginBottom: (theme) => theme.spacing(3) }}>
            <Typography variant="sectionHeader" color="text.primary">
                {_.startCase(role)}
            </Typography>
            {_.map(roleOrderMembers, (orderMember) => {
                const assignmentMember = _.find(assignmentMembers, { order_member_id: orderMember.id });

                return (
                    <AssignmentMemberItem
                        key={orderMember.id}
                        orderMember={orderMember}
                        handleSelectChange={handleSelectChange}
                        handleRoleChange={handleRoleChange}
                        selected={!!assignmentMember}
                        role={assignmentMember?.role || ''}
                    />
                );
            })}
        </Stack>
    );
}

AssignmentMemberRoleGroup.propTypes = {
    role: PropTypes.string.isRequired,
    roleOrderMembers: PropTypes.array.isRequired,
    assignmentMembers: PropTypes.array.isRequired,
    handleSelectChange: PropTypes.func.isRequired,
    handleRoleChange: PropTypes.func.isRequired,
};

const AssignmentMemberSelectField = ({ assignmentMembers, orderMembers, setAssignmentMembers }) => {
    // TODO verify & handle legal entity member grouping
    // Only show roles that are allowed to be assigned (buyer & assignor)
    const orderMemberRoleMap = useMemo(
        () =>
            generateOrderMemberRoleMap(
                orderMembers,
                [],
                _.filter(ORDER_ROLE_TYPE, (role) => !_.includes(ASSIGNMENT_MEMBER_ALLOWED_ORDER_ROLES, role))
            ),
        [orderMembers]
    );

    const handleMemberSelectChange = (event) => {
        const { name: orderMemberId, checked } = event.target;

        if (checked) {
            setAssignmentMembers([
                ...assignmentMembers,
                {
                    order_member_id: orderMemberId,
                    role: '',
                    party: '', // TODO
                },
            ]);
        } else {
            setAssignmentMembers(assignmentMembers.filter((member) => member.order_member_id !== orderMemberId));
        }
    };

    const handleMemberRoleChange = (orderMemberId, role) => {
        const memberIndex = _.findIndex(assignmentMembers, { order_member_id: orderMemberId });

        if (memberIndex === -1) {
            console.warn(`Could not find assignment member with order member id ${orderMemberId}`);
            return;
        }

        const updatedAssignmentMembers = [...assignmentMembers];
        updatedAssignmentMembers[memberIndex] = {
            ...updatedAssignmentMembers[memberIndex],
            role,
            // TODO party
        };

        setAssignmentMembers(updatedAssignmentMembers);
    };

    return (
        <FormGroup>
            {_.map(orderMemberRoleMap, (roleOrderMembers, role) => {
                return (
                    <AssignmentMemberRoleGroup
                        key={role}
                        role={role}
                        roleOrderMembers={roleOrderMembers}
                        assignmentMembers={assignmentMembers}
                        handleSelectChange={handleMemberSelectChange}
                        handleRoleChange={handleMemberRoleChange}
                    />
                );
            })}
        </FormGroup>
    );
};

AssignmentMemberSelectField.propTypes = {
    assignmentMembers: PropTypes.array.isRequired,
    orderMembers: PropTypes.array.isRequired,
    setAssignmentMembers: PropTypes.func.isRequired,
};

export default AssignmentMemberSelectField;
