import AddIcon from '@mui/icons-material/Add';
import {
    Box,
    FormGroup,
    IconButton,
    Stack,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Tooltip,
    Typography,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCreateAssignmentMutation } from 'api/assignment';
import { AssignmentMemberItem } from 'components/Assignment/components/AssignmentMemberSelectField';

import { Button, LoadingButton } from 'components/common/styled';
import {
    ASSIGNMENT_MEMBER_ALLOWED_ORDER_ROLES,
    ASSIGNMENT_MEMBER_PARTY,
    ASSIGNMENT_MEMBER_PARTY_ROLE_MAP,
    ASSIGNMENT_MEMBER_ROLE,
    PARCEL_TYPE,
} from 'helpers/constants';

const AssignmentMemberSelectFormGroup = ({ orderMembers, assignmentMembers, setAssignmentMembers, party }) => {
    const allowedAssignmentMemberRoles = ASSIGNMENT_MEMBER_PARTY_ROLE_MAP[party];

    const orderMemberFilteredList = useMemo(
        () =>
            _.filter(orderMembers, (orderMember) =>
                _.includes(ASSIGNMENT_MEMBER_ALLOWED_ORDER_ROLES, orderMember.role)
            ),
        [orderMembers]
    );

    const handleMemberSelectChange = (event) => {
        const { name: orderMemberId, checked } = event.target;

        if (checked) {
            const defaultPartyRole =
                party === ASSIGNMENT_MEMBER_PARTY.assignor
                    ? ASSIGNMENT_MEMBER_ROLE.assignor
                    : ASSIGNMENT_MEMBER_ROLE.assignee;

            setAssignmentMembers([
                ...assignmentMembers,
                {
                    order_member_id: orderMemberId,
                    role: defaultPartyRole,
                    party,
                },
            ]);
        } else {
            setAssignmentMembers(assignmentMembers.filter((member) => member.order_member_id !== orderMemberId));
        }
    };

    const handleMemberRoleChange = (orderMemberId, role) => {
        const memberIndex = _.findIndex(assignmentMembers, { order_member_id: orderMemberId });

        if (memberIndex === -1) {
            console.warn(`Could not find assignment member with order member id ${orderMemberId}`);
            return;
        }

        const updatedAssignmentMembers = [...assignmentMembers];
        updatedAssignmentMembers[memberIndex] = {
            ...updatedAssignmentMembers[memberIndex],
            role,
            party,
        };

        setAssignmentMembers(updatedAssignmentMembers);
    };

    if (orderMemberFilteredList.length === 0) {
        return (
            <Stack>
                <Typography variant="body1" color="text.secondary" fontStyle="italic">
                    No order members available to select
                </Typography>
            </Stack>
        );
    }

    return (
        <FormGroup>
            <Stack direction="column" spacing={1}>
                {_.map(orderMemberFilteredList, (orderMember) => {
                    const assignmentMember = _.find(assignmentMembers, { order_member_id: orderMember.id });
                    const isPartyMember = assignmentMember?.party === party;
                    const selected = !!assignmentMember && isPartyMember;
                    const disabled = !!assignmentMember && !isPartyMember;

                    return (
                        <AssignmentMemberItem
                            key={orderMember.id}
                            orderMember={orderMember}
                            handleSelectChange={handleMemberSelectChange}
                            handleRoleChange={handleMemberRoleChange}
                            selected={selected}
                            disabled={disabled}
                            role={assignmentMember?.role || ''}
                            assignmentMemberRoleOptions={allowedAssignmentMemberRoles}
                        />
                    );
                })}
            </Stack>
        </FormGroup>
    );
};

AssignmentMemberSelectFormGroup.propTypes = {
    orderMembers: PropTypes.array.isRequired,
    assignmentMembers: PropTypes.array.isRequired,
    setAssignmentMembers: PropTypes.func.isRequired,
    party: PropTypes.oneOf(_.values(ASSIGNMENT_MEMBER_PARTY)).isRequired,
};

const MemberReviewFormStage = ({ orderMembers, assignmentMembers }) => {
    const assignmentMemberPartyMap = _.groupBy(assignmentMembers, 'party');

    // console.log('Assignment Member Party Map:', assignmentMemberPartyMap);

    return (
        <Stack direction="row" spacing={4} sx={{ pt: 2, pb: 2 }}>
            <Stack spacing={1} flexGrow={1}>
                <Typography variant="sectionHeader">Assignors</Typography>

                {_.get(assignmentMemberPartyMap, ASSIGNMENT_MEMBER_PARTY.assignor, []).length === 0 && (
                    <Typography variant="body2" color="error">
                        No Assignors
                    </Typography>
                )}

                {_.map(assignmentMemberPartyMap[ASSIGNMENT_MEMBER_PARTY.assignor], (assignor) => {
                    const orderMember = _.find(orderMembers, { id: assignor.order_member_id });
                    return (
                        <Stack key={orderMember.id}>
                            <Typography variant="body1">{orderMember.member.name}</Typography>
                            <Typography variant="body2">{_.startCase(assignor.role)}</Typography>
                        </Stack>
                    );
                })}
            </Stack>

            <Stack spacing={1} flexGrow={1}>
                <Typography variant="sectionHeader">Assignees</Typography>

                {_.get(assignmentMemberPartyMap, ASSIGNMENT_MEMBER_PARTY.assignee, []).length === 0 && (
                    <Typography variant="body2" color="error">
                        No Assignees
                    </Typography>
                )}

                {_.map(assignmentMemberPartyMap[ASSIGNMENT_MEMBER_PARTY.assignee], (assignee) => {
                    const orderMember = _.find(orderMembers, { id: assignee.order_member_id });
                    return (
                        <Stack key={orderMember.id}>
                            <Typography variant="body1">{orderMember.member.name}</Typography>
                            <Typography variant="body2">{_.startCase(assignee.role)}</Typography>
                        </Stack>
                    );
                })}
            </Stack>
        </Stack>
    );
};

const assignmentFormStages = [
    {
        label: 'Select Assignors',
        component: (params) => <AssignmentMemberSelectFormGroup {...params} party={ASSIGNMENT_MEMBER_PARTY.assignor} />,
        isAssignorStage: true,
        isAssigneeStage: false,
    },
    {
        label: 'Select Assignees',
        component: (params) => <AssignmentMemberSelectFormGroup {...params} party={ASSIGNMENT_MEMBER_PARTY.assignee} />,
        isAssignorStage: false,
        isAssigneeStage: true,
    },
    {
        label: 'Review & Submit',
        component: MemberReviewFormStage,
        isAssignorStage: false,
        isAssigneeStage: false,
    },
];

const generateInitialAssignmentData = (associatedAssignmentContractParcel) => {
    const initialData = {
        members: [],
        previous_assignment_contract_id: null,
    };

    if (!associatedAssignmentContractParcel) {
        return initialData;
    }

    // Seed assignor list with assignee from previous assignment
    initialData.previous_assignment_contract_id = associatedAssignmentContractParcel.id;
    _.forEach(associatedAssignmentContractParcel.members, (member) => {
        if (member.role === ASSIGNMENT_MEMBER_ROLE.assignee) {
            initialData.members.push({
                order_member_id: member.order_member_id,
                role: ASSIGNMENT_MEMBER_ROLE.assignor,
                party: ASSIGNMENT_MEMBER_PARTY.assignor,
            });
        }
    });

    return initialData;
};

const NewAssignmentForm = ({ order, orderMembers, associatedAssignmentContractParcel, handleSubmit }) => {
    // TODO get all existing assignments for this order and filter out members that are already assigned (except assignees on latest assignment)
    // TODO visualize the assignment chain in the UI
    const navigate = useNavigate();

    const [createAssignment, { isLoading }] = useCreateAssignmentMutation();

    const [assignmentData, setAssignmentData] = useState(
        generateInitialAssignmentData(associatedAssignmentContractParcel)
    );

    const [currentStage, setCurrentStage] = useState(0);

    const assignorMembers = useMemo(() => {
        return assignmentData.members.filter((member) => member.role === ASSIGNMENT_MEMBER_ROLE.assignor);
    }, [assignmentData.members]);

    const assigneeMembers = useMemo(() => {
        return assignmentData.members.filter((member) => member.role === ASSIGNMENT_MEMBER_ROLE.assignee);
    }, [assignmentData.members]);

    const hasAssignor = assignorMembers.length > 0;
    const hasAssignee = assigneeMembers.length > 0;
    const isValid = hasAssignor && hasAssignee;
    const isLastStage = currentStage === assignmentFormStages.length - 1;

    const handleAssignmentMembersChange = (members) => {
        setAssignmentData((prevData) => {
            return {
                ...prevData,
                members: [...members],
            };
        });
    };

    const handleCreateAssignment = (event) => {
        event.preventDefault();

        if (!isValid) {
            console.warn('Cannot create assignment with invalid data');
            return;
        }

        return submitCreateAssignment(assignmentData).then((response) => {
            if (response) {
                handleSubmit();
            } else {
                // TODO handle error state
            }
        });
    };

    const submitCreateAssignment = async (createAssignmentData) => {
        // Reformat and add any additional data needed for the assignment
        const formattedAssignmentData = {
            parcel_seed_map: {
                [PARCEL_TYPE.assignment_contract]: {
                    ...createAssignmentData,
                },
            },
        };

        const { data } = await createAssignment({
            orderId: order.id,
            assignmentData: formattedAssignmentData,
        });

        if (data) {
            return true;
        } else {
            console.warn(`Failed to create assignment for order ${order.id}`);
            return false;
        }
    };

    const handleNext = () => {
        setCurrentStage((activeStep) => activeStep + 1);
    };

    const handleBack = () => {
        setCurrentStage((activeStep) => activeStep - 1);
    };

    const handleNavigate = () => {
        // if (true) {
        //     console.log('Navigate to add members');
        //     return;
        // }
        navigate(`/order/${order.id}/members`);
    };

    // console.log('Assignment Data:', assignmentData);
    // console.log('Associated assignment:', associatedAssignmentContractParcel);

    return (
        <form onSubmit={handleCreateAssignment}>
            <Stack spacing={4}>
                <Stepper activeStep={currentStage} orientation="vertical">
                    {assignmentFormStages.map((stageMap, index) => {
                        const { label, component: StageComponent, isAssigneeStage, isAssignorStage } = stageMap;

                        // TODO way too hacky
                        const isCurrentStage = index === currentStage;
                        const currentStageMembers = isAssignorStage
                            ? assignorMembers
                            : isAssigneeStage
                            ? assigneeMembers
                            : [];

                        const hasRequiredMembers = isAssignorStage
                            ? hasAssignor
                            : isAssigneeStage
                            ? hasAssignee
                            : isValid;

                        const currentStageMemberNames = [];
                        _.forEach(currentStageMembers, (stageMember) => {
                            const stageOrderMember = _.find(orderMembers, (orderMember) => {
                                return orderMember.id === stageMember.order_member_id;
                            });
                            if (stageOrderMember) {
                                currentStageMemberNames.push(stageOrderMember.member.name);
                            }
                        });

                        return (
                            <Step key={index}>
                                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                                    <StepLabel
                                        onClick={() => setCurrentStage(index)}
                                        optional={
                                            !isCurrentStage && (
                                                <Typography variant="body2">
                                                    {currentStageMemberNames.join(', ')}
                                                </Typography>
                                            )
                                        }
                                        error={isLastStage && !isCurrentStage && !hasRequiredMembers}
                                    >
                                        {label}
                                    </StepLabel>

                                    {isCurrentStage && !isLastStage && (
                                        <Tooltip title={'Add new order member'} placement="bottom" enterDelay={300}>
                                            <IconButton color="default" onClick={handleNavigate}>
                                                <AddIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Stack>

                                <StepContent>
                                    <Stack direction="column" spacing={2}>
                                        <StageComponent
                                            orderMembers={orderMembers}
                                            assignmentMembers={assignmentData.members}
                                            setAssignmentMembers={handleAssignmentMembersChange}
                                        />

                                        <Stack direction="row" alignItems="center">
                                            <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                                                Back
                                            </Button>

                                            <Box flexGrow={1} />

                                            {index === assignmentFormStages.length - 1 ? (
                                                <LoadingButton
                                                    type="submit"
                                                    color="primary"
                                                    variant="contained"
                                                    loading={isLoading}
                                                    disabled={!isValid}
                                                    disableElevation
                                                    sx={{
                                                        minWidth: '160px',
                                                    }}
                                                >
                                                    Submit
                                                </LoadingButton>
                                            ) : (
                                                <Button
                                                    variant="outlined"
                                                    onClick={handleNext}
                                                    sx={{ mt: 1, mr: 1 }}
                                                    disableElevation
                                                >
                                                    Next
                                                </Button>
                                            )}
                                        </Stack>
                                    </Stack>
                                </StepContent>
                            </Step>
                        );
                    })}
                </Stepper>
            </Stack>
        </form>
    );
};

NewAssignmentForm.propTypes = {
    order: PropTypes.object.isRequired,
    orderMembers: PropTypes.array.isRequired,
    associatedAssignmentContractParcel: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
};

export default NewAssignmentForm;
