import { Box, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { useGetOrderQuery } from 'api/order';
import { Tab, Tabs } from 'components/common/styled';
import OrderBreadcrumbs from 'components/Order/OrderDetails/components/OrderBreadcrumbs';
import { CLOSING_PARCEL_TYPES } from 'helpers/constants';
import { findParcelInOrder } from 'helpers/utils';

import ParcelClosing from './ParcelClosing';

function ParcelClosingTabs({ order, parcel }) {
    const navigate = useNavigate();
    const closingParcels = _.filter(order.parcels, (p) => _.includes(CLOSING_PARCEL_TYPES, p.type));
    const sortedClosingParcels = _.sortBy(closingParcels, 'name');

    const [activeParcelId, setActiveParcelId] = useState(parcel.id);

    const handleChange = (event, newValue) => {
        setActiveParcelId(newValue);
        navigate(`/order/${order.id}/closing/${newValue}`);
    };

    return (
        <Tabs value={activeParcelId} onChange={handleChange} textColor="primary" indicatorColor="primary">
            {_.map(sortedClosingParcels, (parcel) => (
                <Tab
                    key={parcel.id}
                    value={parcel.id}
                    label={_.replace(parcel.name, 'Closing', '')}
                    // secondaryColor="text.secondary"
                />
            ))}
        </Tabs>
    );
}

function ParcelClosingToggle({ order, parcel }) {
    const navigate = useNavigate();
    const closingParcels = _.filter(order.parcels, (p) => _.includes(CLOSING_PARCEL_TYPES, p.type));
    const sortedClosingParcels = _.sortBy(closingParcels, 'name');

    const [activeParcelId, setActiveParcelId] = useState(parcel.id);

    const handleChange = (event, newValue) => {
        setActiveParcelId(newValue);
        navigate(`/order/${order.id}/closing/${newValue}`);
    };

    if (sortedClosingParcels.length === 1) {
        return null;
    }

    return (
        <ToggleButtonGroup color="default" value={activeParcelId} exclusive onChange={handleChange} size="small">
            {_.map(sortedClosingParcels, (parcel) => {
                const party = _.toLower(_.trim(_.replace(parcel.name, 'Closing', '')));
                return (
                    <ToggleButton
                        key={parcel.id}
                        value={parcel.id}
                        sx={{
                            minWidth: '160px',
                            bgcolor: 'rgba(69, 69, 69, 0.08)',

                            '&:hover': {
                                bgcolor: 'rgba(69, 69, 69, 0.12)',
                            },

                            '&.Mui-selected': {
                                bgcolor: 'purchase.dark',
                                // bgcolor: getPartyColor(party),
                                color: 'white',
                                borderColor: 'purchase.dark',

                                '&:hover': {
                                    bgcolor: 'purchase.dark',
                                    // borderColor: 'purchase.dark',
                                    // bgcolor: getPartyColor(party, 'dark'),
                                },
                            },
                        }}
                    >
                        {_.startCase(party)}
                    </ToggleButton>
                );
            })}
        </ToggleButtonGroup>
    );
}

function ParcelClosingParent({ orderId, parcelId }) {
    // Grab order info and match to parcel id
    const { data: order, isLoading: isOrderLoading, isFetching: isOrderFetching } = useGetOrderQuery(orderId);

    const { parcel, parentParcel } = useMemo(() => {
        if (order && parcelId) {
            const parcelEntityMap = {
                parcel: findParcelInOrder(order, parcelId),
                parentParcel: null,
            };

            const { parent_parcel_id } = parcelEntityMap.parcel;

            if (!!parent_parcel_id) {
                parcelEntityMap.parentParcel = findParcelInOrder(order, parent_parcel_id);
            }

            return parcelEntityMap;
        }

        return {};
    }, [order, parcelId]);

    // Hard redirects for non-closing parcel types
    if (parcel && !_.includes(CLOSING_PARCEL_TYPES, parcel.type)) {
        return <Navigate to={`/order/${orderId}/parcel/${parcelId}`} />;
    }

    return (
        <Stack spacing={3}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <OrderBreadcrumbs orderId={orderId} />

                {order && parcel && <ParcelClosingToggle order={order} parcel={parcel} />}
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h1">{parcel?.name}</Typography>

                {false && order && parcel && <ParcelClosingToggle order={order} parcel={parcel} />}
            </Stack>

            {false && (
                <Box sx={{ width: '100%' }}>
                    {order && parcel && <ParcelClosingTabs order={order} parcel={parcel} />}
                </Box>
            )}

            <ParcelClosing orderId={orderId} parcelId={parcelId} />
        </Stack>
    );
}

ParcelClosingParent.propTypes = {
    orderId: PropTypes.string.isRequired,
    parcelId: PropTypes.string.isRequired,
};

export default ParcelClosingParent;
