import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, ListItem, ListItemText, Tooltip } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { useDeleteAssignmentMemberMutation } from 'api/assignment';

const AssignmentMemberListItem = ({ assignment, assignmentMember, orderMembers }) => {
    const [
        deleteAssignmentMember,
        { isError: deleteError, isLoading: deleteLoading },
    ] = useDeleteAssignmentMemberMutation();

    const orderMember = useMemo(() => {
        return _.find(orderMembers, { id: assignmentMember.order_member_id });
    }, [assignmentMember, orderMembers]);

    const handleDeleteAssignmentMember = async () => {
        const deleteAssignmentMemberPayload = {
            orderId: assignment.order_id,
            assignmentId: assignment.id,
            assignmentMemberId: assignmentMember.id,
        };

        const { data } = await deleteAssignmentMember(deleteAssignmentMemberPayload);
        return !!data;
    };

    const handleDelete = (event) => {
        event.preventDefault();

        return handleDeleteAssignmentMember().then(
            (success) => {
                if (success) {
                    console.log('Successfully deleted assignment member', assignmentMember);
                } else {
                    console.warn('Failed to delete assignment member', assignmentMember);
                }
            },
            (error) => {
                console.error('Error while deleting assignment member', error, assignmentMember);
            }
        );
    };

    const name = orderMember ? _.get(orderMember, 'member.name') : null;

    return (
        <ListItem
            secondaryAction={
                <Tooltip title="Remove Member" enterDelay={300} placement="bottom">
                    <span>
                        <IconButton onClick={handleDelete} aria-label="delete" disabled={deleteLoading} size="small">
                            <DeleteIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            }
            disableGutters
        >
            <ListItemText primary={name} secondary={_.startCase(assignmentMember.role)} />
        </ListItem>
    );
};

AssignmentMemberListItem.propTypes = {
    assignment: PropTypes.object.isRequired,
    assignmentMember: PropTypes.object.isRequired,
    orderMembers: PropTypes.array.isRequired,
};

export default AssignmentMemberListItem;
