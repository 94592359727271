import _ from 'lodash';
import PropTypes from 'prop-types';

import BaseDialog from 'components/common/BaseDialog';
import ConfirmDialog from 'components/common/ConfirmDialog';
import { ACTION_STATUS } from 'helpers/constants';
import ActionForm from './ActionForm';

const ActionModal = ({ action, orderId, isOpen, isSubmitting, handleClose, handleUpdateAction }) => {
    // TODO centralize this logic in hook like useActionData
    const handleSubmit = (formData) => {
        const updateActionData = {
            status: ACTION_STATUS.complete,
        };

        if (!_.isEmpty(formData)) {
            // Only add formData if it is not empty
            // This allows us to support 'mark' as done actions with no form fields in the modal
            // And allows parent component to decide whether or not to launch modal in this scenario
            updateActionData['additional_data'] = formData;
        }

        return handleUpdateAction(updateActionData);
    };

    if (!action) {
        return null;
    }

    // Determine if action is a simple 'mark as done' action or if it requires additional data
    // TODO pivot to 'mark as done' action form
    const hasAdditionalDataRequirement = !_.isEmpty(action.additional_data_schema.json_schema);
    if (!hasAdditionalDataRequirement) {
        return (
            <ConfirmDialog
                open={isOpen}
                handleConfirm={() => handleSubmit()}
                handleClose={() => handleClose()}
                {...{
                    title: action.friendly_name,
                    subTitle: action.description,
                    body: `Are you sure you want to mark this action as complete?`,
                }}
            />
        );
    }

    return (
        <BaseDialog open={isOpen} setOpen={() => handleClose()} DialogProps={{ maxWidth: 'sm', fullWidth: true }}>
            {isOpen && (
                <ActionForm orderId={orderId} action={action} handleUpdate={handleSubmit} isLoading={isSubmitting} />
            )}
        </BaseDialog>
    );
};

ActionModal.propTypes = {
    action: PropTypes.object.isRequired,
    orderId: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleUpdateAction: PropTypes.func.isRequired,
};

export default ActionModal;
