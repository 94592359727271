import _ from 'lodash';
import { ENTITY_TYPE } from '../helpers/constants';
import { ORDER_TAG } from './order';
import { PARCEL_STATUS_HISTORY_TAG } from './parcel';
import { rootApi } from './root';

export const ACTION_TAG = _.toUpper(ENTITY_TYPE.action);

export const actionApi = rootApi.injectEndpoints({
    reducerPath: 'actionApi',
    endpoints: (builder) => ({
        updateAction: builder.mutation({
            // TODO post endpoint to create actions
            query: ({actionId, actionData}) => ({
                url: `action/${actionId}`,
                method: 'PUT',
                body: actionData,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: [ACTION_TAG, ORDER_TAG, PARCEL_STATUS_HISTORY_TAG], // TODO rethink this as this triggers full reload of all orders
        }),
        sendActionNotification: builder.mutation({
            query: ({actionId}) => ({
                url: `action/${actionId}/notification`,
                method: 'POST',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
        }),
        getActionForm: builder.query({
            query: ({actionId, formType }) => `action/${actionId}/form/${formType}`,
        }),
    }),
    overrideExisting: false,
})



export const { useUpdateActionMutation, useSendActionNotificationMutation, useGetActionFormQuery } = actionApi