import { Alert, Avatar, Stack, Typography } from '@mui/material';
import { getPartyColor } from 'helpers/utils';
import _ from 'lodash';
import PropTypes from 'prop-types';

const ActionOrderMember = ({ orderMember, user }) => {
    const name = user.name;
    const role = orderMember.role;
    const initials = user.name
        .split(' ')
        .map((n) => n[0])
        .join('');
    return (
        <Alert
            severity="info"
            sx={{ borderColor: (theme) => theme.palette.info.main, borderWidth: '1px', borderStyle: 'solid' }}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{ width: '100%' }}
            >
                <Typography variant="body2" color="inherit">
                    Assigned to <b>{name}</b>
                </Typography>

                <Typography variant="body2" color="inherit">
                    ({_.startCase(role)})
                </Typography>
            </Stack>

            {false && (
                <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Avatar
                            key={orderMember.id}
                            sx={{
                                // width: '36px',
                                // height: '36px',
                                // fontSize: '16px',
                                color: 'lightWhite',
                                bgcolor: getPartyColor(orderMember.party),
                            }}
                        >
                            {initials}
                        </Avatar>

                        <Typography variant="body1" color="inherit">
                            Assigned to {_.startCase(role)}: {name}
                        </Typography>

                        <Stack spacing={0}>
                            <Typography variant="body2" color="text.secondary">
                                Assigned to {_.startCase(role)}
                            </Typography>
                            <Typography variant="body1">{name}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </Alert>
    );
};

ActionOrderMember.propTypes = {
    orderMember: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};

export default ActionOrderMember;
