import { Box, Grid, Stack } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import useParcelData from 'components/common/hooks/useParcelData';
import { CLOSING_PARCEL_TYPES, PARCEL_TYPE } from 'helpers/constants';

import SchedulingList from './components/SchedulingList';
import SigningList from './components/SigningList';

function ParcelClosing({ orderId, parcelId }) {
    const { parcel, childParcels, isLoading } = useParcelData(orderId, parcelId);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!parcel) {
        return (
            <div>
                Closing parcel ({parcelId}) not found in order ({orderId})
            </div>
        );
    }

    // Hard redirects for non-closing parcel types
    if (!_.includes(CLOSING_PARCEL_TYPES, parcel.type)) {
        return <Navigate to={`/order/${orderId}/parcel/${parcelId}`} />;
    }

    const schedulingParcel = _.find(childParcels, { type: PARCEL_TYPE.scheduling });
    const signingParcel = _.find(childParcels, { type: PARCEL_TYPE.signing });

    return (
        <Stack spacing={3}>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12} xl={8}>
                        <Stack spacing={3}>
                            <SchedulingList orderId={orderId} parcelId={schedulingParcel.id} />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={4}>
                        <Stack spacing={3}>
                            <SigningList orderId={orderId} parcelId={signingParcel.id} />
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </Stack>
    );
}

ParcelClosing.propTypes = {
    orderId: PropTypes.string.isRequired,
    parcelId: PropTypes.string.isRequired,
};

export default ParcelClosing;
