import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Avatar,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import { useGetOrderMemberQuery, useGetOrderMembersQuery } from 'api/order';
import { useDeleteParcelMemberMutation } from 'api/parcelMember';
import ConfirmDialog from 'components/common/ConfirmDialog';
import CreateParcelMemberModal from 'components/Order/Parcel/components/CreateParcelMemberModal';
import { getPartyColor } from 'helpers/utils';

const SigningSessionMemberListItem = ({ orderId, parcel, member }) => {
    const [deleteParcelMember, { isLoading: deleteParcelMemberLoading }] = useDeleteParcelMemberMutation();

    const { data: orderMember, isError: orderMemberError, isLoading: orderMemberLoading } = useGetOrderMemberQuery({
        orderId,
        memberId: member.order_member_id,
    });

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const handleDeleteMember = async () => {
        const deleteMemberPayload = {
            orderId,
            parcelId: parcel.id,
            memberId: member.id,
        };

        const { data } = await deleteParcelMember(deleteMemberPayload);
        return !!data;
    };

    const handleDelete = () => {
        return handleDeleteMember().then(
            (success) => {
                if (success) {
                    console.log('Successfully deleted parcel member', member);
                } else {
                    console.warn('Failed to delete parcel member', member);
                }
            },
            (error) => {
                console.error('Error while deleting parcel member', error, member);
            }
        );
    };

    if (!orderMember) {
        return false;
    }

    const name = _.get(orderMember, 'member.name', 'Unknown');
    const role = orderMember.role;
    const initials = name
        .split(' ')
        .map((n) => n[0])
        .join('');

    return (
        <ListItem
            secondaryAction={
                <Tooltip title="Remove Member" enterDelay={300} placement="bottom">
                    <span>
                        <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={() => setOpenConfirmDialog(true)}
                            disabled={deleteParcelMemberLoading}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            }
            disableGutters
        >
            <ListItemAvatar>
                <Avatar
                    sx={{
                        width: '36px',
                        height: '36px',
                        fontSize: '16px',
                        bgcolor: getPartyColor(orderMember.party),
                        color: 'white',
                    }}
                >
                    {initials}
                </Avatar>
            </ListItemAvatar>

            <ListItemText primary={name} secondary={_.startCase(role)} />

            <ConfirmDialog
                open={!!openConfirmDialog}
                handleConfirm={handleDelete}
                handleClose={() => setOpenConfirmDialog(false)}
                {...{
                    title: `Remove ${name}`,
                    subTitle: null,
                    body: `Are you sure you want to remove ${name} from this ${parcel.name}?`,
                }}
            />
        </ListItem>
    );
};

SigningSessionMemberListItem.propTypes = {
    orderId: PropTypes.string.isRequired,
    parcel: PropTypes.object.isRequired,
    member: PropTypes.object.isRequired,
};

// TODO genericize this component to be used in other places
const SigningSessionMemberList = ({ orderId, parcel, members, closingMembers }) => {
    const { data: orderMembers, isLoading: orderMembersLoading } = useGetOrderMembersQuery(orderId);

    const [openDialog, setOpenDialog] = useState(false);

    const availableOrderMembers = useMemo(() => {
        // NOTE: members should always be a subset of closingMembers
        const closingMemberIds = _.map(closingMembers, 'order_member_id');
        const memberIds = _.map(members, 'order_member_id');
        const availableOrderMemberIds = _.difference(closingMemberIds, memberIds);

        return _.filter(orderMembers, (member) => _.includes(availableOrderMemberIds, member.id));
    }, [members, closingMembers, orderMembers]);

    return (
        <Stack spacing={1}>
            <Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between">
                <Typography variant="sectionHeader" color="text.primary">
                    Members
                </Typography>

                <Tooltip title="Add Member" enterDelay={300} placement="bottom">
                    <span>
                        <IconButton onClick={() => setOpenDialog(true)} size="small">
                            <AddIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            </Stack>

            <List disablePadding>
                {(!members || members.length === 0) && (
                    <Typography variant="body1" color="text.secondary" fontStyle="italic">
                        No associated members
                    </Typography>
                )}

                {_.map(members, (member) => {
                    return (
                        <SigningSessionMemberListItem
                            key={member.id}
                            orderId={orderId}
                            parcel={parcel}
                            member={member}
                        />
                    );
                })}
            </List>

            <CreateParcelMemberModal
                open={openDialog}
                handleClose={() => setOpenDialog(false)}
                parcel={parcel}
                parcelMembers={members}
                orderMembers={availableOrderMembers}
                title={`Add Member to ${_.get(parcel, 'name')}`}
            />
        </Stack>
    );
};

SigningSessionMemberList.propTypes = {
    orderId: PropTypes.string.isRequired,
    parcel: PropTypes.object.isRequired,
    members: PropTypes.array.isRequired,
    closingMembers: PropTypes.array.isRequired,
};

export default SigningSessionMemberList;
