import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import DataForm from 'components/common/form/rjsf/DataForm';
import useActionFormData from 'components/common/form/rjsf/useActionFormData';
import useActionData from 'components/common/hooks/useActionData';
import LoadingButton from 'components/common/styled/LoadingButton';
import ActionOrderMember from './ActionOrderMember';

const ActionForm = ({ orderId, action, handleUpdate, isLoading, formType = 'update' }) => {
    // TODO merge in current action additional data or assume backend will handle this?
    const { order, parcel, parentParcel, orderMember, orderMembers, user } = useActionData(orderId, action);
    const {
        data: formData,
        setData: setFormData,
        schema: formSchema,
        isLoading: isLoadingFormData,
        isError: isLoadingFormError,
        isReady: isFormReady,
    } = useActionFormData(action.id, formType);

    const handleSubmit = () => {
        return handleUpdate(formData);
    };

    const handleFormChange = (data) => {
        setFormData({ ...data.formData });
    };

    if (!action) {
        return null;
    }

    if (isLoadingFormError) {
        return <div>Error loading form data</div>;
    }

    const showOrderMemberBanner = orderMember && user;

    return (
        <Stack
            spacing={2}
            sx={{
                width: '100%',
                '& .form-group-label': {
                    // fontSize: '12px',
                    // fontWeight: '600',
                    // textTransform: 'uppercase',
                },
            }}
        >
            <Stack spacing={1}>
                <Typography variant="h5" color="text.primary">
                    {action.friendly_name}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    {action.description}
                </Typography>
            </Stack>

            {showOrderMemberBanner && (
                <Box sx={{ '& > *': { mt: 2 } }}>
                    <ActionOrderMember orderMember={orderMember} user={user} />
                </Box>
            )}

            {isLoadingFormData || !isFormReady ? (
                <Box>Loading ...</Box>
            ) : (
                <DataForm
                    data={formData || {}}
                    schema={formSchema || {}}
                    onChange={handleFormChange}
                    onSubmit={handleSubmit}
                    formContext={{
                        order,
                        parcel,
                        parentParcel,
                        orderMember,
                        orderMembers,
                        user,
                        rootFormData: null,
                    }}
                >
                    <Stack
                        direction="row-reverse"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        sx={{ pt: 6 }}
                    >
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            loading={isLoading}
                            disableElevation
                            sx={{
                                minWidth: '160px',
                            }}
                        >
                            Submit
                        </LoadingButton>
                    </Stack>
                </DataForm>
            )}
        </Stack>
    );
};

ActionForm.propTypes = {
    orderId: PropTypes.string.isRequired,
    action: PropTypes.object.isRequired,
    handleUpdate: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    formType: PropTypes.string,
};

export default ActionForm;
