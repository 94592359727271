import AddTaskIcon from '@mui/icons-material/AddTask';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
// import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Box, Tooltip } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUpdateActionMutation } from 'api/action';
import ActionButton from 'components/Action/ActionButton';
import ActionModal from 'components/Action/ActionModal';
import { ParcelTriggerButton } from 'components/Order/Parcel/components/ParcelTriggerList';
import { useTriggers } from 'components/common/hooks/useTriggers';
import { Button, LoadingButton } from 'components/common/styled';
import { ACTION_STATUS, ACTION_TERMINAL_STATUSES, PARCEL_STATUS, PARCEL_TYPE } from 'helpers/constants';

function ParcelLinkAction({ to, label, fullWidth = false }) {
    const navigate = useNavigate();

    const handleNavigate = (event) => {
        event.preventDefault();

        navigate(to);
    };

    if (fullWidth) {
        return (
            <Box sx={{ width: '100%', textAlign: 'right' }}>
                <Button
                    variant="outlined"
                    color="default"
                    onClick={handleNavigate}
                    endIcon={<EastRoundedIcon />}
                    disableElevation
                    fullWidth
                >
                    View {label}
                </Button>
            </Box>
        );
    }

    return (
        <Box sx={{ textAlign: 'right' }}>
            <Tooltip title={`View ${label}`} placement="left" enterDelay={300}>
                <Button variant="contained" color="inherit" onClick={handleNavigate} disableElevation>
                    <EastRoundedIcon />
                </Button>
            </Tooltip>
        </Box>
    );
}

const parcelNavActionMap = {
    [PARCEL_TYPE.title_clearing]: {
        to: '/title',
        label: 'Title Clearing',
    },
    [PARCEL_TYPE.balancing]: {
        to: '/balancing',
        label: 'Balancing',
    },
};

function ParcelCardAction({ order, parcel }) {
    const { triggers, triggerOrder } = useTriggers(parcel);

    // check if there is a trigger action
    const showPrimaryBeginAction =
        _.includes(triggerOrder, 'begin') && _.includes([PARCEL_STATUS.not_started], parcel.current_status.status);
    if (showPrimaryBeginAction) {
        return (
            <Box sx={{ width: '100%', textAlign: 'right' }}>
                <ParcelTriggerButton
                    parcel={parcel}
                    trigger={triggers.begin}
                    buttonProps={{
                        variant: 'outlined',
                        color: 'default',
                        fullWidth: true,
                        startIcon: <PlayArrowRoundedIcon />,
                        sx: {
                            borderRadius: (theme) => theme.spacing(3),
                        },
                    }}
                />
            </Box>
        );
    }

    // Determine if parcel only shows navigation action
    if (_.includes(_.keys(parcelNavActionMap), parcel.type)) {
        const parcelNavAction = parcelNavActionMap[parcel.type];
        return (
            <ParcelLinkAction
                to={`/order/${order.id}${parcelNavAction.to}`}
                label={parcelNavAction.label}
                fullWidth={true}
            />
        );
    }

    if (parcel.active_actions.length > 0) {
        const action = parcel.action_map[parcel.active_actions[0]];
        return (
            <ActionButton
                type="contained"
                action={action}
                orderId={order.id}
                loadingButtonProps={{ fullWidth: true }}
            />
        );
    }

    // TODO closing parcel:
    // find the scheduling parcel - if not complete use the action
    // find the relevant signing parcel - if not complete use the action

    // Fallback 'goto' navigation action
    // return <ParcelLinkAction to={`/order/${order.id}/parcel/${parcel.id}`} label={parcel.name} />

    // No action
    return null;
}

ParcelCardAction.propTypes = {
    order: PropTypes.object.isRequired,
    parcel: PropTypes.object.isRequired,
};

export default ParcelCardAction;
