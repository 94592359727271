import PropTypes from 'prop-types';
import { useMemo } from 'react';

import ActionButton, { ACTION_BUTTON_TYPES } from 'components/Action/ActionButton';

const SchedulingActionColumn = ({ orderId, parcel }) => {
    const action = useMemo(() => {
        if (parcel.active_actions.length > 0) {
            return parcel.action_map[parcel.active_actions[0]];
        }

        return null;
    });

    if (!action) {
        return false;
    }

    return <ActionButton action={action} orderId={orderId} type={ACTION_BUTTON_TYPES.contained} />;
};

SchedulingActionColumn.propTypes = {
    orderId: PropTypes.string.isRequired,
    parcel: PropTypes.object.isRequired,
};

export default SchedulingActionColumn;
