import PropTypes from 'prop-types';

import BaseDialog from 'components/common/BaseDialog';

import CreateParcelMember from './CreateParcelMember';

const CreateParcelMemberModal = ({ parcel, parcelMembers, orderMembers, open, handleClose, ...rest }) => {
    const handleSubmit = () => {
        handleCloseLocal(false);
    };

    const handleCloseLocal = (open) => {
        handleClose(open);
    };

    return (
        <BaseDialog
            open={open}
            setOpen={() => handleCloseLocal(false)}
            DialogProps={{
                maxWidth: 'md',
                fullWidth: false,
            }}
        >
            <CreateParcelMember
                parcel={parcel}
                parcelMembers={parcelMembers}
                orderMembers={orderMembers}
                handleSubmit={handleSubmit}
                {...rest}
            />
        </BaseDialog>
    );
};

CreateParcelMemberModal.propTypes = {
    parcel: PropTypes.object.isRequired,
    parcelMembers: PropTypes.array.isRequired,
    orderMembers: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default CreateParcelMemberModal;
