import _ from "lodash";

export const QUALIA_ORDER_BASE = {
    AUS: "AUS",
    HOU: "HOU",
    DAL: "DAL",
}

export const TITLE_WORKFLOW = {
    hudly_purchase: "hudly_purchase",
    hudly_refinance: "hudly_refinance",
};

export const TRANSACTION_TYPE = {
    purchase: "purchase",
    refinance: "refinance",
};

export const WORKFLOW_TRANSACTION_MAP = {
    [TRANSACTION_TYPE.purchase]: TITLE_WORKFLOW.hudly_purchase,
    [TRANSACTION_TYPE.refinance]: TITLE_WORKFLOW.hudly_refinance,
};

export const TRANSACTION_ORDER_TYPE = {
    [TRANSACTION_TYPE.purchase]: {
        residential: "residential",
        wholesale: "wholesale",
        commercial: "commercial",
    },
    [TRANSACTION_TYPE.refinance]: {
        residential: "residential",
        cash_out: "cash_out",
        investment: "investment",
        commercial: "commercial",
    }
}

export const TRANSACTION_PARTY_TYPE = {
    borrower: "borrower",
    buyer: "buyer",
    seller: "seller",
    independent: "independent",
}

export const ORDER_ROLE_TYPE = {
    borrower: "borrower",
    buyer: "buyer",
    seller: "seller",
    listing_agent: "listing_agent",
    selling_agent: "selling_agent",
    loan_officer: "loan_officer",
    transaction_coordinator: "transaction_coordinator",
    escrow_officer: "escrow_officer",
    escrow_assistant: "escrow_assistant",
    lender: "lender",
    assignor: "assignor",
};

export const ORDER_INTERNAL_ROLES = [ORDER_ROLE_TYPE.escrow_officer, ORDER_ROLE_TYPE.escrow_assistant];

// All roles that are not internal
export const ORDER_EXTERNAL_ROLES = _.filter(ORDER_ROLE_TYPE, (role) => !_.includes(ORDER_INTERNAL_ROLES, role));

export const BUYER_PARTY_ROLES = [
    ORDER_ROLE_TYPE.buyer,
    ORDER_ROLE_TYPE.selling_agent,
    ORDER_ROLE_TYPE.lender,
    ORDER_ROLE_TYPE.assignor,
]

export const SELLER_PARTY_ROLES = [
    ORDER_ROLE_TYPE.seller,
    ORDER_ROLE_TYPE.listing_agent,
]

export const BORROWER_PARTY_ROLES = [
    ORDER_ROLE_TYPE.borrower,
    ORDER_ROLE_TYPE.lender,
    ORDER_ROLE_TYPE.assignor,
]

export const PURCHASE_ORDER_ROLES = [
    ...BUYER_PARTY_ROLES,
    ...SELLER_PARTY_ROLES,
]

export const REFINANCE_ORDER_ROLES = [
    ...BORROWER_PARTY_ROLES,
]

export const TRANSACTION_TYPE_PARTY_MAP = {
    [TRANSACTION_TYPE.purchase]: {
        [TRANSACTION_PARTY_TYPE.buyer]: BUYER_PARTY_ROLES,
        [TRANSACTION_PARTY_TYPE.seller]: SELLER_PARTY_ROLES,
        // TRANSACTION_PARTY_TYPE.independent,
    },
    [TRANSACTION_TYPE.refinance]: {
        [TRANSACTION_PARTY_TYPE.borrower]: BORROWER_PARTY_ROLES,
        // TRANSACTION_PARTY_TYPE.independent,
    }
}

export const USER_TYPE = {
    admin: "admin",
    frontend: "frontend",
};

export const META_PARCEL_TYPE = {
    opening: "opening",
    title: "title",
    closing: "closing",
    balancing: "balancing"
};

export const PARCEL_TYPE = {
    [META_PARCEL_TYPE.opening]: META_PARCEL_TYPE.opening,
    earnest_money: "earnest_money",
    tax_cert: "tax_cert",
    hoa: "hoa",
    assignment: "assignment",
    assignment_contract: "assignment_contract",

    [META_PARCEL_TYPE.title]: META_PARCEL_TYPE.title,
    title_exam: "title_exam",
    title_clearing: "title_clearing",

    // META_PARCEL_TYPE.supplemental
    survey: "survey",
    home_warranty: "home_warranty",
    cda: "cda",
    legal_docs: "legal_docs",

    [META_PARCEL_TYPE.closing]: META_PARCEL_TYPE.closing,
    closing_buyer: "closing_buyer",
    closing_seller: "closing_seller",
    closing_borrower: "closing_borrower",
    scheduling: "scheduling",
    scheduling_member: "scheduling_member",
    signing: "signing",
    signing_session: "signing_session",
    signing_session_electronic_notary: "signing_session_electronic_notary",
    signing_session_mobile_notary: "signing_session_mobile_notary",
    signing_session_office: "signing_session_office",
    signing_session_in_house: "signing_session_in_house",

    [META_PARCEL_TYPE.balancing]: META_PARCEL_TYPE.balancing,
    balancing_contract: "balancing_contract",
    balancing_earnest_money: "balancing_earnest_money",
    balancing_taxes: "balancing_taxes",
    balancing_hoa: "balancing_hoa",
    balancing_home_warranty: "balancing_home_warranty",
    balancing_commissions: "balancing_commissions",
    balancing_deed: "balancing_deed",
    balancing_liens: "balancing_liens",
    balancing_title_fees: "balancing_title_fees",
    balancing_lender_fees: "balancing_lender_fees",
    balancing_other: "balancing_other",
    balancing_final_cd_hud: "balancing_final_cd_hud",

    funding: "funding",
    keys: "keys",
    post_closing: "post_closing",
    order_member_data_collection: "order_member_data_collection",
};

export const PARCEL_NAME_OVERRIDE = {
    [PARCEL_TYPE.hoa]: "HOA",
    [PARCEL_TYPE.cda]: "CDA",
};

export const PARCEL_ORDER = Object.keys(PARCEL_TYPE);

export const META_PARCEL_ORDER = Object.keys(META_PARCEL_TYPE);

export const BALANCING_CHILD_PARCEL_TYPES = [
    PARCEL_TYPE.balancing_contract,
    PARCEL_TYPE.balancing_earnest_money,
    PARCEL_TYPE.balancing_taxes,
    PARCEL_TYPE.balancing_hoa,
    PARCEL_TYPE.balancing_home_warranty,
    PARCEL_TYPE.balancing_commissions,
    PARCEL_TYPE.balancing_deed,
    PARCEL_TYPE.balancing_liens,
    PARCEL_TYPE.balancing_title_fees,
    PARCEL_TYPE.balancing_lender_fees,
    PARCEL_TYPE.balancing_other,
    PARCEL_TYPE.balancing_final_cd_hud,
]

export const SIGNING_PARCEL_TYPES = [
    PARCEL_TYPE.signing_session_electronic_notary,
    PARCEL_TYPE.signing_session_mobile_notary,
    PARCEL_TYPE.signing_session_office,
    PARCEL_TYPE.signing_session_in_house,
]

export const CLOSING_PARCEL_TYPES = [
    PARCEL_TYPE.closing_buyer,
    PARCEL_TYPE.closing_seller,
    PARCEL_TYPE.closing_borrower,
]

export const CLOSING_PARCEL_TYPE_PARTY_MAP = {
    [PARCEL_TYPE.closing_buyer]: TRANSACTION_PARTY_TYPE.buyer,
    [PARCEL_TYPE.closing_seller]: TRANSACTION_PARTY_TYPE.seller,
    [PARCEL_TYPE.closing_borrower]: TRANSACTION_PARTY_TYPE.borrower,
}

export const ACTION_STATUS = {
    not_started: "NotStarted",
    in_progress: "InProgress",
    canceled: "Canceled",
    complete: "Complete",
};

export const ACTION_TERMINAL_STATUSES = [
    ACTION_STATUS.complete,
    ACTION_STATUS.canceled,
];

export const PARCEL_STATUS = {
    on_hold: "OnHold",
    not_started: "NotStarted",
    in_progress: "InProgress",
    ordered: "Ordered",
    received: "Received",
    waiting: "Waiting",
    // TODO need to be able to dynamically request status options for each parcel
    waiting_for_tax_cert: "WaitingForTaxCert", // hoa parcel specific
    need_to_order: "NeedToOrder", // hoa parcel specific
    quotes_requested: "QuotesRequested", // survey parcel specific
    waiting_for_existing_survey: "WaitingForExistingSurvey", // survey parcel specific
    issues_found: "IssuesFound", // title parcel specific
    waiting_for_funding_approval: "WaitingForFundingApproval", // funding parcel specific
    funding: "Funding", // funding parcel specific
    waiting_for_post_funding: "WaitingForPostFunding", // post closing parcel specific
    waiting_for_contract: "WaitingForContract", // assignment-specific
    waiting_for_earnest_money: "WaitingForEarnestMoney", // assignment-specific
    needs_receipt: "NeedsReceipt", // assignment-specific
    preparing_documents: "PreparingDocuments", // signing session specific
    documents_ready: "DocumentsReady", // signing session specific
    waiting_for_signed_documents: "WaitingForSignedDocuments", // signing session specific
    documents_printed: "DocumentsPrinted", // signing session specific
    complete: "Complete",
    canceled: "Canceled",
}

export const PARCEL_TERMINAL_STATUSES = [
    PARCEL_STATUS.complete,
    PARCEL_STATUS.canceled,
]

export const LIEN_STATUS = {
    researching: "Researching",
    not_started: PARCEL_STATUS.not_started,
    in_progress: PARCEL_STATUS.in_progress,
    ordered: PARCEL_STATUS.ordered,
    in_review: "InReview",
    waiting: "WaitingForPayoff",
    closing: "Closing",
    needs_ROL: "NeedsReleaseOfLien",
    ordered_ROL: "OrderedReleaseOfLien",
    receive_ROL: "ReceivedReleaseOfLien",
    waiting_signing: "WaitingOnSigning",
    ready: "ReadyToSign",
    cleared: "Cleared",
    record: "Record",
    payoff: "Payoff",
}

export const LIEN_TERMINAL_STATUSES = [
    LIEN_STATUS.cleared,
    LIEN_STATUS.ready,
    LIEN_STATUS.record,
    LIEN_STATUS.payoff,
]

export const PAYOFF_STATUS = {
    not_started: "NotStarted",
    waiting: "Waiting",
    complete: "Complete",
};

export const LIEN_TYPE = {
    generic: "generic",
    bankruptcy: "bankruptcy",
    divorce: "divorce",
    MUD: "MUD",
    tax_suit: "tax_suit",
    mortgage: "mortgage",
    HELOC: "HELOC",
    UCC: "UCC",
    mechanics_lien: "mechanics_lien",
    abstract_of_judgement: "abstract_of_judgement",
    marital_status: "marital_status",
    survey: "survey",
    entity_docs: "entity_docs",
    deed_transfer: "deed_transfer",
    missing_vested_owner: "missing_vested_owner",
    missing_deed: "missing_deed",
    not_acceptable_deed: "not_acceptable_deed",
    POA: "POA",
    probate: "probate",
    AOH: "AOH",
};

export const LIEN_CLEARED_STATUS = {
    cleared: "cleared",
    not_cleared: "not_cleared",
}

export const PAYOFF_TYPE = {
    generic: "generic",
};

export const ORDER_STATUS = {
    pre_open: "PreOpen",
    open: "Open",
    on_hold: "OnHold",
    canceled: "Canceled",
    closed: "Closed",
};

export const ORDER_TERMINAL_STATUSES = [
    ORDER_STATUS.canceled, 
    ORDER_STATUS.closed
]

export const DOCUMENT_TYPE = {
    assignment_contract: "assignment_contract",
    assignment_EM_check: "assignment_EM_check",
    assignment_contract_receipted: "assignment_contract_receipted",
    bankruptcy: "bankruptcy",
    CD_HUD: "CD_HUD",
    CDA: "CDA",
    closing_documents: "closing_documents",
    contract: "contract",
    contract_receipted: "contract_receipted",
    contract_termination: "contract_termination",
    divorce_decree: "divorce_decree",
    EM_OF_check: "EM_OF_check",
    government_ID: "government_ID",
    HOA_resale_certificate: "HOA_resale_certificate",
    HOA_statement_of_account: "HOA_statement_of_account",
    HOA_supporting_documents: "HOA_supporting_documents",
    home_warranty: "home_warranty",
    insurance_policy: "insurance_policy",
    invoice: "invoice",
    legal_documents: "legal_documents",
    lender_documents: "lender_documents",
    lien: "lien",
    metes_and_bounds: "metes_and_bounds",
    mortgage_statement: "mortgage_statement",
    MUD_notice: "MUD_notice",
    payoff: "payoff",
    shipping_label: "shipping_label",
    signed_documents: "signed_documents",
    survey: "survey",
    survey_invoice: "survey_invoice",
    tax_certificate: "tax_certificate",
    tax_bill: "tax_bill",
    title_commitment: "title_commitment",
    title_documents: "title_documents",
    title_request: "title_request",
    vesting_deed: "vesting_deed",
    wire_instructions: "wire_instructions",
    other: "other",
};

export const DOCUMENT_TYPE_NAME_OVERRIDE = {
    [DOCUMENT_TYPE.assignment_contract_receipted]: "Assignment Contract (Receipted)",
    [DOCUMENT_TYPE.CD_HUD]: "CD/HUD",
    [DOCUMENT_TYPE.CDA]: "CDA",
    [DOCUMENT_TYPE.contract_receipted]: "Contract (Receipted)",
    [DOCUMENT_TYPE.contract_termination]: "Contract (Termination)",
    [DOCUMENT_TYPE.EM_OF_check]: "EM/OF Check",
    [DOCUMENT_TYPE.government_ID]: "Government ID",
    [DOCUMENT_TYPE.HOA_resale_certificate]: "HOA Resale Certificate",
    [DOCUMENT_TYPE.HOA_statement_of_account]: "HOA Statement of Account",
    [DOCUMENT_TYPE.HOA_supporting_documents]: "HOA Supporting Documents",
    [DOCUMENT_TYPE.MUD_notice]: "MUD Notice",
    [DOCUMENT_TYPE.metes_and_bounds]: "Metes & Bounds",
}

// TODO set in document enums on backend and request from API here
export const DOCUMENT_TYPE_DEFAULT_META_DATA = {
    [DOCUMENT_TYPE.contract]: {
        "name": null, // null, string, or true (auto generate)
        "access": [...ORDER_EXTERNAL_ROLES], // All roles
        "send_access_email": false,
    },  
    [DOCUMENT_TYPE.contract_receipted]: {
        "name": null,
        "access": [ORDER_ROLE_TYPE.buyer, ORDER_ROLE_TYPE.seller, ORDER_ROLE_TYPE.selling_agent, ORDER_ROLE_TYPE.listing_agent],
        "send_access_email": true,
    },  
    [DOCUMENT_TYPE.bankruptcy]: {
        "name": null,
        "access": [ORDER_ROLE_TYPE.borrower, ORDER_ROLE_TYPE.seller],
        "send_access_email": false,
    },
    [DOCUMENT_TYPE.tax_certificate]: {
        "name": null,
        // TODO assignors as well?
        "access": [ORDER_ROLE_TYPE.borrower, ORDER_ROLE_TYPE.buyer, ORDER_ROLE_TYPE.seller, ORDER_ROLE_TYPE.selling_agent, ORDER_ROLE_TYPE.listing_agent],
        "send_access_email": false,
    },
    [DOCUMENT_TYPE.HOA_resale_certificate]: {
        "name": null, 
        // TODO assignors as well?
        "access": [ORDER_ROLE_TYPE.buyer, ORDER_ROLE_TYPE.seller, ORDER_ROLE_TYPE.selling_agent, ORDER_ROLE_TYPE.listing_agent],
        "send_access_email": true,
    },
    [DOCUMENT_TYPE.HOA_statement_of_account]: {
        "name": null, 
        // TODO ask Palak about notes for default access. Currently listing purchase roles but statement of account is refinance only
        "access": [ORDER_ROLE_TYPE.borrower],
        "send_access_email": false,
    },
    [DOCUMENT_TYPE.HOA_supporting_documents]: {
        "name": true, 
        // TODO ask Palak about notes for default access. Currently listing purchase roles but statement of account is refinance only
        "access": [ORDER_ROLE_TYPE.borrower, ORDER_ROLE_TYPE.buyer, ORDER_ROLE_TYPE.seller, ORDER_ROLE_TYPE.selling_agent, ORDER_ROLE_TYPE.listing_agent],
        "send_access_email": false,
    },
    [DOCUMENT_TYPE.assignment_contract]: {
        "name": null, 
        "access": [ORDER_ROLE_TYPE.buyer, ORDER_ROLE_TYPE.assignor], // TODO limit more to only members of specific assignment
        "send_access_email": false,
    },
    [DOCUMENT_TYPE.assignment_contract_receipted]: {
        "name": null,
        "access": [ORDER_ROLE_TYPE.buyer, ORDER_ROLE_TYPE.assignor],    // TODO limit more to only members of specific assignment
        "send_access_email": true,
    },
    [DOCUMENT_TYPE.title_commitment]: {
        "name": null,
        "access": [ORDER_ROLE_TYPE.borrower, ORDER_ROLE_TYPE.buyer, ORDER_ROLE_TYPE.seller, ORDER_ROLE_TYPE.selling_agent, ORDER_ROLE_TYPE.listing_agent],
        "send_access_email": true,
    },
    [DOCUMENT_TYPE.title_documents]: {
        "name": true,
        "access": [ORDER_ROLE_TYPE.borrower, ORDER_ROLE_TYPE.buyer, ORDER_ROLE_TYPE.seller, ORDER_ROLE_TYPE.selling_agent, ORDER_ROLE_TYPE.listing_agent],
        "send_access_email": false,
    },
    [DOCUMENT_TYPE.mortgage_statement]: {
        "name": null,
        "access": [ORDER_ROLE_TYPE.borrower, ORDER_ROLE_TYPE.buyer, ORDER_ROLE_TYPE.seller, ORDER_ROLE_TYPE.selling_agent, ORDER_ROLE_TYPE.listing_agent],
        "send_access_email": false,
    },
    [DOCUMENT_TYPE.insurance_policy]: {
        "name": null,
        "access": [ORDER_ROLE_TYPE.borrower, ORDER_ROLE_TYPE.buyer, ORDER_ROLE_TYPE.seller, ORDER_ROLE_TYPE.selling_agent, ORDER_ROLE_TYPE.listing_agent],
        "send_access_email": false,
    },
    [DOCUMENT_TYPE.government_ID]: {
        "name": null,
        "access": [],
        "send_access_email": false,
    },
    [DOCUMENT_TYPE.legal_documents]: {
        "name": null,
        "access": [ORDER_ROLE_TYPE.borrower, ORDER_ROLE_TYPE.buyer, ORDER_ROLE_TYPE.seller, ORDER_ROLE_TYPE.selling_agent, ORDER_ROLE_TYPE.listing_agent],
        "send_access_email": true,
    },
}

// TODO remove
export const DOCUMENT_TYPE_DEFAULT_ROLE_ACCESS = {
    [DOCUMENT_TYPE.contract]: [...ORDER_EXTERNAL_ROLES],  // All roles
    [DOCUMENT_TYPE.bankruptcy]: [ORDER_ROLE_TYPE.borrower, ORDER_ROLE_TYPE.seller],
}


export const PARCEL_TABLE_DEFAULT_OPTIONS = {
    any: "any",
    all: "all",
};

export const ENTITY_TYPE = {
    order: "order",
    order_member: "order_member",
    order_member_relationship: "order_member_relationship",
    parcel: "parcel",
    parcel_member: "parcel_member",
    lien: "lien",
    lien_member: "lien_member",
    payoff: "payoff",
    document: "document",
    document_access: "document_access",
    note: "note",
    action: "action",
    assignment_member: "assignment_member", // TODO convert to parcel_member
    
    user: "user",
    user_relationship: "user_relationship",

    legal_entity: "legal_entity",
    legal_entity_relationship: "legal_entity_relationship",
    legal_entity_member: "legal_entity_member",
    legal_entity_member_relationship: "legal_entity_member_relationship",

    email_log: "email_log",
    access_log: "access_log",
    event_log: "event_log",
}
    
export const ENTITY_ACTION = {
    "create": "create",
    "update": "update",
    "delete": "delete"
}

export const SIGNING_METHOD = {
    "electronic_notary": "electronic_notary",
    "mobile_notary": "mobile_notary",
    "office": "office",
    "in_house": "in_house"
}

export const SIGNING_LOCATION = {
    "AUS_NORTH": "AUS_NORTH",
    "AUS_DOMAIN": "AUS_DOMAIN",
    "AUS_SOUTH": "AUS_SOUTH",
    "DAL": "DAL",
    "SAT": "SAT",
}

export const SIGNING_LOCATION_NAME_MAP = {
    [SIGNING_LOCATION.AUS_NORTH]: "Austin (North)",
    [SIGNING_LOCATION.AUS_DOMAIN]: "Austin (Domain)",
    [SIGNING_LOCATION.AUS_SOUTH]: "Austin (South)",
    [SIGNING_LOCATION.DAL]: "Dallas",
    [SIGNING_LOCATION.SAT]: "San Antonio",

}

export const SIGNING_METHOD_PARCEL_MAP = {
    [SIGNING_METHOD.electronic_notary]: PARCEL_TYPE.signing_session_electronic_notary,
    [SIGNING_METHOD.mobile_notary]: PARCEL_TYPE.signing_session_mobile_notary,
    [SIGNING_METHOD.office]: PARCEL_TYPE.signing_session_office,
    [SIGNING_METHOD.in_house]: PARCEL_TYPE.signing_session_in_house,
}

export const DATE_RANGE_MAP = {
    "this_week":  "this_week",
    "next_week":  "next_week",
    "last_week":  "last_week",
    "this_month": "this_month",
    "next_month": "next_month",
    "last_month": "last_month",
}


export const MARITAL_STATUS = {
    married: "married",
    single: "single",
    widowed:"widowed",
    divorced: "divorced",
    other: "other"
}

export const GENDER = {
    male: "male",
    female: "female",
    other: "other"
}


export const US_STATES = [
    "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut",
    "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas",
    "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", 
    "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", 
    "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon",
    "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", 
    "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"
];

export const CONTACT_TYPE = {
    [ENTITY_TYPE.user]: ENTITY_TYPE.user,
    [ENTITY_TYPE.legal_entity]: ENTITY_TYPE.legal_entity,
}

export const LEGAL_ENTITY_TYPE = {
    corporation: "corporation",
    LLC: "LLC",
    trust: "trust",
    estate: "estate",
    guardianship: "guardianship",
    partnership: "partnership",
    other: "other",
}

export const COMMERCIAL_LEGAL_ENTITIES = [
    LEGAL_ENTITY_TYPE.corporation,
    LEGAL_ENTITY_TYPE.LLC,
    LEGAL_ENTITY_TYPE.partnership,
    LEGAL_ENTITY_TYPE.other,
]

export const COMMERCIAL_ENTITY_TYPE = {
    brokerage: "brokerage",
    wholesaler: "wholesaler",
    lender: "lender",
    iBuyer: "iBuyer",
    builder: "builder",
    hard_money_lender: "hard_money_lender",
    investor: "investor",
    power_buyer: "power_buyer",
    private_money: "private_money",
    other: "other"
}

export const USER_RELATIONSHIP_TYPE = {
    parent: "parent",
    child: "child",
    grandparent: "grandparent",
    grandchild: "grandchild",
    sibling: "sibling",
    spouse: "spouse",
    guardian: "guardian",
    dependent: "dependent",
    significant_other: "significant_other",
    other: "other",
}

export const USER_RELATIONSHIP_TYPE_INVERSE_MAP = {
    [USER_RELATIONSHIP_TYPE.parent]:  USER_RELATIONSHIP_TYPE.child,
    [USER_RELATIONSHIP_TYPE.child]: USER_RELATIONSHIP_TYPE.parent,
    [USER_RELATIONSHIP_TYPE.grandparent]: USER_RELATIONSHIP_TYPE.grandchild,
    [USER_RELATIONSHIP_TYPE.grandchild]: USER_RELATIONSHIP_TYPE.grandparent,
    [USER_RELATIONSHIP_TYPE.sibling]: USER_RELATIONSHIP_TYPE.sibling,
    [USER_RELATIONSHIP_TYPE.spouse]: USER_RELATIONSHIP_TYPE.spouse,
    [USER_RELATIONSHIP_TYPE.guardian]: USER_RELATIONSHIP_TYPE.dependent,
    [USER_RELATIONSHIP_TYPE.dependent]: USER_RELATIONSHIP_TYPE.guardian,
    [USER_RELATIONSHIP_TYPE.significant_other]: USER_RELATIONSHIP_TYPE.significant_other,
    [USER_RELATIONSHIP_TYPE.other]: USER_RELATIONSHIP_TYPE.other
}

export const LEGAL_ENTITY_MEMBER_TYPE = {
    executor: "executor",
    trustee: "trustee",
    guardian: "guardian",
    agent_for_guardian: "agent_for_guardian",
    real_estate_agent: "real_estate_agent",
    transaction_coordinator: "transaction_coordinator",
    broker: "broker",
    owner: "owner",
    sales_associate: "sales_associate",
    assistant: "assistant",
    representative: "representative",
    managing_member: "managing_member",
    loan_officer: "loan_officer",
    loan_processor: "loan_processor",
    closer: "closer",
    funder: "funder",
    attorney: "attorney",
    other: "other",
}

export const COMMON_LEGAL_ENTITY_MEMBER_ROLES = [
    LEGAL_ENTITY_MEMBER_TYPE.attorney,
    LEGAL_ENTITY_MEMBER_TYPE.other
]

export const BROKERAGE_LEGAL_ENTITY_MEMBER_ROLES = [
    LEGAL_ENTITY_MEMBER_TYPE.real_estate_agent,
    LEGAL_ENTITY_MEMBER_TYPE.transaction_coordinator,
    LEGAL_ENTITY_MEMBER_TYPE.broker,
]

export const BUYER_LEGAL_ENTITY_MEMBER_ROLES = [
    LEGAL_ENTITY_MEMBER_TYPE.owner,
    LEGAL_ENTITY_MEMBER_TYPE.sales_associate,
    LEGAL_ENTITY_MEMBER_TYPE.assistant,
    LEGAL_ENTITY_MEMBER_TYPE.representative,
    LEGAL_ENTITY_MEMBER_TYPE.managing_member,
]

export const LENDER_LEGAL_ENTITY_MEMBER_ROLES = [
    LEGAL_ENTITY_MEMBER_TYPE.loan_officer,
    LEGAL_ENTITY_MEMBER_TYPE.loan_processor,
    LEGAL_ENTITY_MEMBER_TYPE.closer,
    LEGAL_ENTITY_MEMBER_TYPE.funder,
]

export const COMMERCIAL_LEGAL_ENTITY_MEMBER_ROLES = [
    ...BROKERAGE_LEGAL_ENTITY_MEMBER_ROLES,
    ...BUYER_LEGAL_ENTITY_MEMBER_ROLES,
    ...LENDER_LEGAL_ENTITY_MEMBER_ROLES,
    ...COMMON_LEGAL_ENTITY_MEMBER_ROLES
]

// Map of legal entity type to legal entity member roles options
// NOTE: commercial type will further restrict potential role options
export const LEGAL_ENTITY_TYPE_MEMBER_ROLE_MAP = {
    [LEGAL_ENTITY_TYPE.estate]: [
        LEGAL_ENTITY_MEMBER_TYPE.executor,
        ...COMMON_LEGAL_ENTITY_MEMBER_ROLES
    ],
    [LEGAL_ENTITY_TYPE.trust]: [
        LEGAL_ENTITY_MEMBER_TYPE.trustee,
        ...COMMON_LEGAL_ENTITY_MEMBER_ROLES
    ],
    [LEGAL_ENTITY_TYPE.guardianship]: [
        LEGAL_ENTITY_MEMBER_TYPE.guardian,
        LEGAL_ENTITY_MEMBER_TYPE.agent_for_guardian,
        ...COMMON_LEGAL_ENTITY_MEMBER_ROLES
    ],
    [LEGAL_ENTITY_TYPE.corporation]: COMMERCIAL_LEGAL_ENTITY_MEMBER_ROLES,
    [LEGAL_ENTITY_TYPE.LLC]: COMMERCIAL_LEGAL_ENTITY_MEMBER_ROLES,
    [LEGAL_ENTITY_TYPE.partnership]: COMMERCIAL_LEGAL_ENTITY_MEMBER_ROLES,
    [LEGAL_ENTITY_TYPE.other]: COMMERCIAL_LEGAL_ENTITY_MEMBER_ROLES,
}

export const COMMERCIAL_TYPE_MEMBER_ROLE_MAP = {
    [COMMERCIAL_ENTITY_TYPE.brokerage]: [
        ...BROKERAGE_LEGAL_ENTITY_MEMBER_ROLES,
        ...COMMON_LEGAL_ENTITY_MEMBER_ROLES
    ],
    [COMMERCIAL_ENTITY_TYPE.builder]: [
        ...BUYER_LEGAL_ENTITY_MEMBER_ROLES,
        ...COMMON_LEGAL_ENTITY_MEMBER_ROLES
    ],
    [COMMERCIAL_ENTITY_TYPE.iBuyer]: [
        ...BUYER_LEGAL_ENTITY_MEMBER_ROLES,
        ...COMMON_LEGAL_ENTITY_MEMBER_ROLES
    ],
    [COMMERCIAL_ENTITY_TYPE.investor]: [
        ...BUYER_LEGAL_ENTITY_MEMBER_ROLES,
        ...COMMON_LEGAL_ENTITY_MEMBER_ROLES
    ],
    [COMMERCIAL_ENTITY_TYPE.power_buyer]: [
        ...BUYER_LEGAL_ENTITY_MEMBER_ROLES,
        ...COMMON_LEGAL_ENTITY_MEMBER_ROLES
    ],
    [COMMERCIAL_ENTITY_TYPE.wholesaler]: [
        ...BUYER_LEGAL_ENTITY_MEMBER_ROLES,
        ...COMMON_LEGAL_ENTITY_MEMBER_ROLES
    ],
        
    [COMMERCIAL_ENTITY_TYPE.lender]: [
        ...LENDER_LEGAL_ENTITY_MEMBER_ROLES,
        ...COMMON_LEGAL_ENTITY_MEMBER_ROLES
    ],
    [COMMERCIAL_ENTITY_TYPE.hard_money_lender]: [
        ...LENDER_LEGAL_ENTITY_MEMBER_ROLES,
        ...COMMON_LEGAL_ENTITY_MEMBER_ROLES
    ],
    [COMMERCIAL_ENTITY_TYPE.private_money]: [
        ...LENDER_LEGAL_ENTITY_MEMBER_ROLES,
        ...COMMON_LEGAL_ENTITY_MEMBER_ROLES
    ],
    [COMMERCIAL_ENTITY_TYPE.other]: [...COMMON_LEGAL_ENTITY_MEMBER_ROLES],
}

export const ORDER_FILTER_TYPE = {
    search: "search",
    transactionType: "transactionType",
    orderType: "orderType",
    workflowType: "workflowType",
    orderStatus: "orderStatus",
    closeDateStatus: "closeDateStatus",
    closeDateGreaterThan: "closeDateGreaterThan",
    closeDateRange: "closeDateRange",
    disbursementDateRange: "disbursementDateRange"
};

export const PARCEL_ACTION_FILTER_TYPE = {
    parcelType: "parcelType",
    parcelStatus: "parcelStatus",
    actionType: "actionType",
}

export const ASSIGNED_ACTION_FILTER_TYPE = {
    actionType: "actionType",
    actionStatus: "actionStatus",
    orderMemberId: "orderMemberId",
    linkedId: "linkedId",
}

export const ORDER_TITLE_FILTER_TYPE = {
    lienType: "lienType",
    lienStatus: "lienStatus",
    clearedStatus: "clearedStatus",
    payoffStatus: "payoffStatus",
}

export const DOCUMENT_FILTER_TYPE = {
    search: "search",
    documentType: "documentType",
    access: "access",
}

export const NOTE_FILTER_TYPE = {
    search: "search",
    entityType: "entityType",
}

export const CONTACT_FILTER_TYPE = {
    search: "search",
    contactType: "contactType", // user or legal entity
}

export const EMAIL_LOG_FILTER_TYPE = {
    search: "search",
    orderId: "orderId",
    userId: "userId",
    messageId: "messageId",
    recipient: "recipient",
    emailType: "emailType",
    eventType: "eventType",
}

export const ACCESS_LOG_FILTER_TYPE = {
    search: "search",
    userId: "userId",
    domainType: "domainType",
    eventType: "eventType",
}

export const EVENT_LOG_FILTER_TYPE = {
    search: "search",
    orderId: "orderId",
    entityId: "entityId",
    entityType: "entityType",
    eventType: "eventType",
    adminId: "adminId",
    userId: "userId",
    transactionId: "transactionId",
}

export const FUNDING_PARCEL_ACTION_TYPE = {
    "receive_lender_funds": "receive_lender_funds",
    "receive_buyer_funds": "receive_buyer_funds",
    "send_for_funding_approval": "send_for_funding_approval",
    "funding_approval": "funding_approval",
    "setup_wires": "setup_wires",
    "approve_wires": "approve_wires",
    "send_checks": "send_checks",
}

export const POST_CLOSING_PARCEL_ACTION_TYPE = {
    "finalize_title_policy": "finalize_title_policy",
    "send_westcor_policy": "send_westcor_policy",
    "send_lender_title_documents": "send_lender_title_documents",
    "refund_recording": "refund_recording",
    "receive_signed_westcor_policy": "receive_signed_westcor_policy",
    "record_documents": "record_documents",
    "lender_package": "lender_package",
    "confirm_1099_status": "confirm_1099_status", // conditional - purchase only
}


export const SNACKBAR_SEVERITY = {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
}

// TODO provide through api
export const EMAIL_TYPE = {
    document_access: "document_access",
}

// TODO provide through api
export const EMAIL_EVENT_TYPE = {
    send: "Send",
    delivery: "Delivery",
    reject: "Reject",
    open: "Open",
    click: "Click",
    renderingFailure: "Rendering Failure",
    deliveryDelay: "DeliveryDelay",
    bounce: "Bounce",
    complaint: "Complaint",
}

// TODO provide through api
export const ACCESS_DOMAIN_TYPE = {
    auth: "auth",
    document: "document"
}

// TODO provide through api
export const ACCESS_EVENT_TYPE = {
    sign_in: "sign_in",
    sign_in_failed: "sign_in_failed",
    sign_out: "sign_out",
    OTP_email_sent: "OTP_email_sent",
    OTP_exchange_failed: "OTP_exchange_failed",
    document_viewed: "document_viewed",
    document_downloaded: "document_downloaded",
    document_access_failed: "document_access_failed",
}

export const CONTACT_TIER = {
    all: "all",
    mandatory: "mandatory",
    executive: "executive",
    none: "none",
}

export const ASSIGNMENT_MEMBER_ROLE = {
    "assignor": "assignor",
    "assignee": "assignee",
    "joint_venture": "joint_venture",
    "transaction_coordinator": "transaction_coordinator",
}

export const ASSIGNMENT_MEMBER_PARTY = {
    "assignor": "assignor",
    "assignee": "assignee",
}

export const ASSIGNMENT_MEMBER_ROLE_PARTY_MAP = {
    [ASSIGNMENT_MEMBER_ROLE.assignor]: [ASSIGNMENT_MEMBER_PARTY.assignor],
    [ASSIGNMENT_MEMBER_ROLE.assignee]: [ASSIGNMENT_MEMBER_PARTY.assignee],
    [ASSIGNMENT_MEMBER_ROLE.joint_venture]: [ASSIGNMENT_MEMBER_PARTY.assignor],
    [ASSIGNMENT_MEMBER_ROLE.transaction_coordinator]: [
        ASSIGNMENT_MEMBER_PARTY.assignor,
        ASSIGNMENT_MEMBER_PARTY.assignee
    ]
}


// TODO generate off of ASSIGNMENT_MEMBER_ROLE_PARTY_MAP
export const ASSIGNMENT_MEMBER_PARTY_ROLE_MAP = {
    [ASSIGNMENT_MEMBER_PARTY.assignor]: [
        ASSIGNMENT_MEMBER_ROLE.assignor,
        ASSIGNMENT_MEMBER_ROLE.joint_venture,
        ASSIGNMENT_MEMBER_ROLE.transaction_coordinator
    ],
    [ASSIGNMENT_MEMBER_PARTY.assignee]: [
        ASSIGNMENT_MEMBER_ROLE.assignee,
        ASSIGNMENT_MEMBER_ROLE.transaction_coordinator
    ],
}

export const ASSIGNMENT_MEMBER_ALLOWED_ORDER_ROLES = [
    ORDER_ROLE_TYPE.buyer,
    ORDER_ROLE_TYPE.assignor
];
