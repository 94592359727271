import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BoltIcon from '@mui/icons-material/Bolt';
import ChecklistIcon from '@mui/icons-material/Checklist';
import { Avatar, Badge, Chip, ListItemAvatar, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import OrderAvatar from 'components/Order/components/OrderAvatar';
import {
    CLOSING_PARCEL_TYPE_PARTY_MAP,
    ENTITY_ACTION,
    ENTITY_TYPE,
    PARCEL_TYPE,
    SIGNING_PARCEL_TYPES,
} from 'helpers/constants';
import { findParcelInOrder, formatAddress, generateRelativeTime } from 'helpers/utils';
import useEventLogData from './useEventLogEntityData';

const generateEventLogEntityName = (eventLog, eventLogEntityData) => {
    const { entity_type } = eventLog;
    switch (entity_type) {
        case ENTITY_TYPE.order:
            return eventLogEntityData.order.qualia_order_number;
        case ENTITY_TYPE.parcel:
            const { name, type } = eventLogEntityData.entity;

            if (_.includes([PARCEL_TYPE.scheduling, PARCEL_TYPE.signing], type)) {
                // Parcel is a child of a party-specific closing parcel
                const party_type = CLOSING_PARCEL_TYPE_PARTY_MAP[eventLogEntityData.parent.type];
                return `${_.startCase(party_type)} ${name}`;
            } else if (_.includes([PARCEL_TYPE.scheduling_member, ...SIGNING_PARCEL_TYPES], type)) {
                // Parcel is a grandchild of a party-specific closing parcel
                const grandparentParcelId = _.get(eventLogEntityData, 'parent.parent_parcel_id');
                const grandparentParcel = findParcelInOrder(eventLogEntityData.order, grandparentParcelId);
                const party_type = CLOSING_PARCEL_TYPE_PARTY_MAP[grandparentParcel.type];
                return `${_.startCase(party_type)} ${name}`;
            }

            return name;
        case ENTITY_TYPE.lien:
            return eventLogEntityData.entity.name;
        case ENTITY_TYPE.payoff:
            const lienName = eventLogEntityData.relatedEntityMap[ENTITY_TYPE.lien].name;
            return `for ${lienName}`;
        case ENTITY_TYPE.action:
            return eventLogEntityData.entity.friendly_name;
        // TODO document
        // TODO order member
        default:
            return null;
    }
};

const generateEventLogEntityAvatar = (eventLog, eventLogEntityData) => {
    const { entity_type } = eventLog;
    switch (entity_type) {
        case ENTITY_TYPE.order:
            return (props) => (
                <OrderAvatar transactionType={eventLogEntityData.order.transaction_type} avatarProps={props} />
            );
        case ENTITY_TYPE.parcel:
            return (props) => (
                <Avatar sx={{ bgcolor: 'info.light', color: 'info.dark' }}>
                    <ChecklistIcon {...props} />
                </Avatar>
            );
        case ENTITY_TYPE.lien:
            // TODO determine colors
            return (props) => (
                <Avatar>
                    <AccountBalanceIcon {...props} />
                </Avatar>
            );
        case ENTITY_TYPE.payoff:
            // TODO determine colors
            return (props) => (
                <Avatar>
                    <AttachMoneyIcon {...props} />
                </Avatar>
            );
        case ENTITY_TYPE.action:
            return (props) => (
                <Avatar sx={{ bgcolor: 'action.light', color: 'action.main' }}>
                    <BoltIcon {...props} />
                </Avatar>
            );
        // TODO document
        // TODO order member
        default:
            return null;
    }
};

const generateEntityOrderText = (eventLog, eventLogEntityData) => {
    const { entity_type: entityType, event_type: eventType } = eventLog;

    const { qualia_order_number, property_address, transaction_type } = eventLogEntityData.order;
    const orderAddress = formatAddress(property_address);

    if (eventType === ENTITY_ACTION.create) {
        if (entityType === ENTITY_TYPE.order) {
            return `${_.startCase(transaction_type)} • ${orderAddress}`;
        }
    }

    return `${qualia_order_number} • ${orderAddress}`;
};

const generateEntityActionText = (eventLog) => {
    const { entity_type: entityType, event_type: eventType, event_data: eventData } = eventLog;

    if (eventType === ENTITY_ACTION.create) {
        if (entityType === ENTITY_TYPE.order) {
            const status = _.get(eventData, 'status');
            return _.startCase(status);
        } else if (entityType === ENTITY_TYPE.document) {
            return 'Uploaded';
        } else if (entityType === ENTITY_TYPE.action) {
            return _.startCase(eventData.status);
        }

        return 'Created';
    } else if (eventType === ENTITY_ACTION.update) {
        if (entityType === ENTITY_TYPE.order && !!eventData.status) {
            return _.startCase(eventData.status);
        } else if (entityType === ENTITY_TYPE.parcel && !!eventData.current_status) {
            return _.startCase(_.get(eventData, 'current_status.status'));
        } else if (entityType === ENTITY_TYPE.action && !!eventData.status) {
            return _.startCase(eventData.status);
        }

        return 'Updated';
    }
};

const generateEventLogSecondaryText = (eventLog, eventLogEntityData) => {
    const orderText = generateEntityOrderText(eventLog, eventLogEntityData);

    return orderText;
};

const generateEventLogLinkUrl = (eventLog, eventLogEntityData) => {
    const { entity_type } = eventLog;
    switch (entity_type) {
        case ENTITY_TYPE.order:
            return `/order/${eventLog.order_id}`;
        case ENTITY_TYPE.parcel:
            return `/order/${eventLog.order_id}/parcel/${eventLog.entity_id}`;
        case ENTITY_TYPE.lien:
            return `/order/${eventLog.order_id}/title/${eventLog.entity_id}`;
        case ENTITY_TYPE.payoff:
            const { lien_id } = eventLogEntityData.entity;
            return `/order/${eventLog.order_id}/title/${lien_id}`;
        case ENTITY_TYPE.action:
            const { parcel_id } = eventLogEntityData.entity;
            return `/order/${eventLog.order_id}/parcel/${parcel_id}`;
        // TODO document
        // TODO order member
        default:
            return null;
    }
};

const EventLogPrimaryText = ({ eventLog, eventLogEntityData }) => {
    const entityName = generateEventLogEntityName(eventLog, eventLogEntityData);
    const entityActionText = generateEntityActionText(eventLog);

    return (
        <Stack direction="row" alignItems="center" spacing={1} sx={{ flexGrow: 1, width: '100%', minWidth: 0 }}>
            <Typography variant="body1" noWrap sx={{ flexGrow: 1 }}>
                {entityName}
            </Typography>

            <Chip
                label={entityActionText}
                size="small"
                variant="filled"
                sx={{
                    borderRadius: '4px',
                    height: '20px',
                    '& .MuiChip-label': {
                        fontSize: '0.7rem',
                        paddingLeft: '6px',
                        paddingRight: '6px',
                    },
                }}
            />
        </Stack>
    );
};

const EventLogSecondaryText = ({ eventLog, eventLogEntityData }) => {
    const secondaryText = generateEventLogSecondaryText(eventLog, eventLogEntityData);
    const relativeTime = useMemo(() => generateRelativeTime(eventLog.event_datetime), [eventLog.event_datetime]);

    return (
        <Stack direction="row" alignItems="center" justify-content="space-between" spacing={2}>
            <Typography variant="body2" sx={{ flexGrow: 1 }} noWrap>
                {secondaryText}
            </Typography>

            <Typography variant="caption" sx={{ minWidth: '112px', textAlign: 'right' }}>
                {relativeTime}
            </Typography>
        </Stack>
    );
};

const NotificationListItem = ({ notificationEventLog, handleNavigation, unread }) => {
    const eventLogEntityData = useEventLogData(notificationEventLog);

    const linkUrl = generateEventLogLinkUrl(notificationEventLog, eventLogEntityData);
    const EventLogAvatar = generateEventLogEntityAvatar(notificationEventLog, eventLogEntityData);

    return (
        <ListItemButton onClick={() => handleNavigation(linkUrl)}>
            <ListItemAvatar>
                <Badge badgeContent={unread ? 1 : 0} color="secondary" variant="dot" overlap="circular">
                    <EventLogAvatar />
                </Badge>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <EventLogPrimaryText eventLog={notificationEventLog} eventLogEntityData={eventLogEntityData} />
                }
                secondary={
                    <EventLogSecondaryText eventLog={notificationEventLog} eventLogEntityData={eventLogEntityData} />
                }
                disableTypography
            />
        </ListItemButton>
    );
};

NotificationListItem.propTypes = {
    notificationEventLog: PropTypes.object.isRequired,
    handleNavigation: PropTypes.func.isRequired,
    unread: PropTypes.bool,
};

export default NotificationListItem;
