import MultiDateSelect from 'components/common/MultiDateSelect';
import { addBusinessDays, isWeekend, parseISO } from 'date-fns';
import _ from 'lodash';

const CustomMultiDateSelectField = (props) => {
    // NOTE: formData provided to this field is not the entire form's data, but just the data for this field
    const { order } = props.formContext;

    // Defaults to enforce required fields - must be explicitly set to false to disable
    // TODO standardize these across all custom fields
    const uiSchema = _.get(props, 'uiSchema', {});
    const disabled = _.get(props, 'readonly', false);
    const required = _.get(props, 'required', true);

    // Determine the field's label
    const schemaLabel = _.get(props, 'schema.title');
    const uiLabel = _.get(uiSchema, 'ui:title');
    const label = uiLabel || schemaLabel;

    // Custom field specific options
    // TODO determine if date <-> string conversion should occur here or in the MultiDateSelect component
    // TODO enable form options to be passed in

    return (
        <MultiDateSelect
            dates={props.formData || []}
            setDates={props.onChange}
            selectProps={{
                label,
                required,
                disabled,
            }}
            // TODO make dynamic
            datePickerProps={{
                maxDate: order ? parseISO(order.close_date) : null,
                minDate: order ? addBusinessDays(parseISO(order.close_date), -9) : null,
                shouldDisableDate: (date) => isWeekend(date),
            }}
        />
    );
};

export default CustomMultiDateSelectField;
