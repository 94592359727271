import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { useGetActionFormQuery } from 'api/action';

function useActionFormData(actionId, formType = 'update') {
    const [schema, setSchema] = useState({});
    const [data, setData] = useState({});

    // Skip request if all params are not set
    const skip = !actionId || !formType;

    const { data: formConfig, isLoading, isSuccess, isError } = useGetActionFormQuery(
        {
            actionId,
            formType,
        },
        {
            skip,
        }
    );

    useEffect(() => {
        // Update data if form config is not empty and request is successful
        if (!_.isEmpty(formConfig) && isSuccess) {
            setSchema(formConfig.schema);
            setData({ ..._.get(formConfig, 'seed', {}) });
        }
    }, [formConfig, isSuccess]);

    const isReady = useMemo(() => {
        return !_.isEmpty(schema) && isSuccess;
    }, [schema, data, isSuccess]);

    return {
        data,
        setData,
        schema,
        setSchema,
        isLoading,
        isSuccess,
        isError,
        isReady,
        isSkipped: skip,
    };
}

export default useActionFormData;
