import DateRangeIcon from '@mui/icons-material/DateRange';
import { Box, Chip, FormControl, InputLabel, Select } from '@mui/material';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import MultiDatePicker from './MultiDatePicker';

function MultiDateSelect({ dates, setDates, selectProps = {}, datePickerProps = {} }) {
    const handleChange = (newDates) => {
        // Convert internal date objects to external date strings
        setDates(_.map(newDates, (date) => format(date, 'yyyy-MM-dd')));
    };

    const handleChangeSelect = (event) => {
        handleChange(event.target.value);
    };

    const internalDates = useMemo(() => {
        // Convert external date strings to internal date objects
        return _.map(dates, (date) => parseISO(date));
    }, [dates]);

    const size = _.get(selectProps, 'size', 'small');
    const required = _.get(selectProps, 'required', false);
    const fullWidth = _.get(selectProps, 'fullWidth', true);

    return (
        <FormControl size={size} fullWidth={fullWidth} required={required}>
            <InputLabel>{selectProps.label || ''}</InputLabel>
            <Select
                value={internalDates}
                onChange={handleChangeSelect}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {!_.isEmpty(selected) &&
                            selected.map((value) => <Chip key={value} label={format(value, 'EEE M/d')} size="small" />)}
                    </Box>
                )}
                IconComponent={DateRangeIcon}
                multiple
                {...selectProps}
                MenuProps={{
                    ..._.get(selectProps, 'MenuProps', {}),
                }}
                sx={{
                    '& .MuiSelect-iconOpen': {
                        transform: 'none',
                    },
                    ..._.get(selectProps, 'sx', {}),
                }}
            >
                <MultiDatePicker dates={internalDates} setDates={handleChange} datePickerProps={datePickerProps} />
            </Select>
        </FormControl>
    );
}

MultiDateSelect.propTypes = {
    dates: PropTypes.array.isRequired,
    setDates: PropTypes.func.isRequired,
    selectProps: PropTypes.object,
    datePickerProps: PropTypes.object,
};

export default MultiDateSelect;
