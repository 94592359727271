import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { useGetOrderMembersQuery } from 'api/order';
import { useGetParcelMembersQuery } from 'api/parcelMember';
import useParcelData from 'components/common/hooks/useParcelData';
import ParcelStatusChip from 'components/Order/Parcel/components/ParcelStatusChip';
import { PARCEL_STATUS } from 'helpers/constants';
import { SCHEDULING_STATUS_COLOR_MAP } from './SchedulingListDataGrid';
import SigningListItem from './SigningListItem';
import SigningListTriggers from './SigningListTriggers';

const SigningList = ({ orderId, parcelId }) => {
    const theme = useTheme();
    const isScreenXl = useMediaQuery(theme.breakpoints.up('xl'));
    const { order, parcel, childParcels, isFetching } = useParcelData(orderId, parcelId);
    const { data: closingMembers } = useGetParcelMembersQuery({ orderId: orderId, parcelId: parcel?.parent_parcel_id });
    const { data: orderMembers } = useGetOrderMembersQuery(orderId);

    const filteredOrderMembers = useMemo(() => {
        if (!orderMembers || !closingMembers) {
            return [];
        }

        const closingOrderMemberIds = _.map(closingMembers, 'order_member_id');
        return _.filter(orderMembers, (orderMember) => {
            return _.includes(closingOrderMemberIds, orderMember.id);
        });
    }, [orderMembers, closingMembers]);

    // TODO: eventually we might want to order by signing date/time
    const sortedChildParcels = useMemo(() => {
        return _.orderBy(
            childParcels,
            [(childParcel) => childParcel.current_status.status !== PARCEL_STATUS.canceled, 'created_datetime'],
            ['desc', 'desc']
        );
    }, [childParcels]);

    const formContext = {
        order,
        parcel,
        orderMembers: filteredOrderMembers,
    };

    return (
        <Stack spacing={1}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h4">Signing</Typography>

                <Stack direction="row" alignItems="center" spacing={1}>
                    <SigningListTriggers parcel={parcel} formContext={formContext} />

                    <ParcelStatusChip
                        status={_.get(parcel, 'current_status.status')}
                        statusColorMap={SCHEDULING_STATUS_COLOR_MAP}
                        // chipProps={{ size: 'small' }}
                    />
                </Stack>
            </Stack>

            <Box>
                <Stack
                    direction={isScreenXl ? 'column' : 'row'}
                    spacing={3}
                    sx={
                        isScreenXl
                            ? {}
                            : {
                                  ml: -3,
                                  pl: 3,
                                  pr: 3,
                                  mr: -3,
                                  pb: 3,
                                  mb: -3,
                                  overflow: 'auto',
                              }
                    }
                >
                    {_.map(sortedChildParcels, (signingSessionParcel) => {
                        return (
                            <Box
                                key={signingSessionParcel.id}
                                sx={{
                                    minWidth: isScreenXl ? '400px' : '480px',
                                    maxWidth: isScreenXl ? 'none' : '560px',
                                    flexGrow: 1,
                                }}
                            >
                                <SigningListItem orderId={orderId} parcelId={signingSessionParcel.id} />
                            </Box>
                        );
                    })}
                </Stack>
            </Box>
        </Stack>
    );
};

SigningList.propTypes = {
    orderId: PropTypes.string.isRequired,
    parcelId: PropTypes.string.isRequired,
};

export default SigningList;
