import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Paper, Stack, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { useGetParcelMembersQuery } from 'api/parcelMember';
import ActionButton, { ACTION_BUTTON_TYPES } from 'components/Action/ActionButton';
import useParcelData from 'components/common/hooks/useParcelData';
import useTriggers from 'components/common/hooks/useTriggers';
import ParcelStatusChip from 'components/Order/Parcel/components/ParcelStatusChip';
import {
    ConfirmTriggerIconButton,
    PARCEL_TRIGGER_ICON_MAP,
} from 'components/Order/Parcel/components/ParcelTriggerList';
import { PARCEL_STATUS } from 'helpers/constants';
import { formatAddress } from 'helpers/utils';
import SigningSessionContentTabs from './SigningSessionContentTabs';

// TODO signing session details

// TODO add member modal
// TODO remove member button

const SIGNING_SESSION_STATUS_COLOR_MAP = {
    [PARCEL_STATUS.on_hold]: 'warning',
    [PARCEL_STATUS.not_started]: 'default',
    [PARCEL_STATUS.in_progress]: 'default', // 'info',
    [PARCEL_STATUS.waiting]: 'default', // 'info',
    [PARCEL_STATUS.preparing_documents]: 'default', // 'info',
    [PARCEL_STATUS.documents_ready]: 'default', // 'info',
    [PARCEL_STATUS.waiting_for_signed_documents]: 'default', // 'info',
    [PARCEL_STATUS.documents_printed]: 'default', // 'info',
    [PARCEL_STATUS.complete]: 'primary',
    [PARCEL_STATUS.canceled]: 'warning',
};

const SigningSessionDetails = ({ parcel }) => {
    const { party, method, time, location } = useMemo(() => {
        if (!parcel) {
            return {};
        }

        const additionalData = parcel.additional_data;

        return {
            party: additionalData.signing_party_type,
            method: additionalData.signing_method,
            time: additionalData.signing_time ? parseISO(additionalData.signing_time) : null,
            location: additionalData.signing_location,
        };
    }, [parcel]);

    if (!parcel || (!location && !time)) {
        return null;
    }

    return (
        <Stack spacing={3} direction="row" alignItems="flex-start">
            <Stack direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={2} sx={{ flex: 1 }}>
                <AccessTimeFilledIcon sx={{ color: 'text.primary' }} />

                <Typography
                    variant="body1"
                    sx={{
                        whiteSpace: 'pre-line',
                        textAlign: 'left',
                    }}
                >
                    {format(time, 'p')}
                    <br />
                    {format(time, 'P')}
                </Typography>
            </Stack>

            <Stack direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={2} sx={{ flex: 1 }}>
                <LocationOnIcon sx={{ color: 'text.primary' }} />

                <Typography
                    variant="body1"
                    sx={{
                        whiteSpace: 'pre-line',
                        textAlign: 'left',
                    }}
                >
                    {formatAddress(location, true)}
                </Typography>
            </Stack>
        </Stack>
    );
};

SigningSessionDetails.propTypes = {
    parcel: PropTypes.object.isRequired,
};

const SigningSessionAction = ({ orderId, parcel }) => {
    if (!parcel) {
        return null;
    }

    const activeActions = parcel.active_actions;
    if (!activeActions || activeActions.length === 0) {
        return null;
    }

    const action = parcel.action_map[parcel.active_actions[0]];

    return (
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ pt: 3 }}>
            <ActionButton
                orderId={orderId}
                action={action}
                type={ACTION_BUTTON_TYPES.contained}
                loadingButtonProps={{ fullWidth: true }}
            />
        </Stack>
    );
};

SigningSessionAction.propTypes = {
    orderId: PropTypes.string.isRequired,
    parcel: PropTypes.object.isRequired,
};

const SigningListItem = ({ orderId, parcelId }) => {
    const { parcel, parentParcel, parcelMembers } = useParcelData(orderId, parcelId);
    const { triggers, activeTriggers } = useTriggers(parcel);

    // Get closing parcel members (grandparent parcel)
    const closingParcelId = _.get(parentParcel, 'parent_parcel_id');
    const {
        data: closingParcelMembers,
        isLoading: isClosingParcelMembersLoading,
        isFetching: isClosingParcelMembersFetching,
    } = useGetParcelMembersQuery({
        orderId,
        parcelId: closingParcelId,
    });

    const showCancelTrigger = _.includes(activeTriggers, 'cancel');

    return (
        <Paper
            variant="outlined"
            sx={{
                padding: (theme) => theme.spacing(3),
                flexGrow: 1,
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{ paddingBottom: (theme) => theme.spacing(3) }}
            >
                <Typography variant="h5">{parcel.name}</Typography>

                <Stack direction="row" alignItems="center" spacing={1}>
                    <ParcelStatusChip
                        status={parcel.current_status.status}
                        statusColorMap={SIGNING_SESSION_STATUS_COLOR_MAP}
                        chipProps={{
                            size: 'small',
                        }}
                    />

                    {showCancelTrigger && (
                        <ConfirmTriggerIconButton
                            parcel={parcel}
                            trigger={triggers.cancel}
                            isActive={showCancelTrigger}
                            icon={PARCEL_TRIGGER_ICON_MAP.cancel}
                            dialogProps={{
                                title: `Cancel ${_.get(parcel, 'name')}`,
                                body: `Are you sure you want to cancel this signing session?`,
                            }}
                        />
                    )}
                </Stack>
            </Stack>

            {/* <Divider /> */}

            <Stack spacing={3}>
                <SigningSessionDetails parcel={parcel} />

                <SigningSessionContentTabs
                    orderId={orderId}
                    parcel={parcel}
                    members={parcelMembers}
                    closingMembers={closingParcelMembers}
                />

                <SigningSessionAction orderId={orderId} parcel={parcel} />
            </Stack>
        </Paper>
    );
};

SigningListItem.propTypes = {
    orderId: PropTypes.string.isRequired,
    parcelId: PropTypes.string.isRequired,
};

export default SigningListItem;
