import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import { Box, ListItem, ListItemButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetAssignmentMembersQuery } from 'api/assignment';
import ActionButton, { ACTION_BUTTON_TYPES } from 'components/Action/ActionButton';
import { ASSIGNMENT_MEMBER_PARTY, ASSIGNMENT_MEMBER_ROLE } from 'helpers/constants';
import { pluralize } from 'helpers/utils';
import AssignmentContractIconList from './AssignmentContractIconList';
import AssignmentStatusChip from './AssignmentStatusChip';

const AssignmentLatestAction = ({ assignment, type = ACTION_BUTTON_TYPES.icon }) => {
    if (!assignment || !assignment.active_actions.length > 0) {
        return null;
    }

    const latestActionId = assignment.active_actions[0];
    const latestAction = assignment.action_map[latestActionId];

    return <ActionButton action={latestAction} orderId={assignment.order_id} type={type} />;
};

const AssignmentItemMemberRoleContent = ({ assignmentMembers, orderMemberMap, role, party, align = 'left' }) => {
    const assignmentRoleMembers = useMemo(() => _.filter(assignmentMembers, { role, party }), [assignmentMembers]);

    const primaryOrderMemberId = _.get(assignmentRoleMembers, '[0].order_member_id');
    const primaryOrderMember = primaryOrderMemberId ? orderMemberMap[primaryOrderMemberId] : null;

    if (!primaryOrderMember) {
        return (
            <Stack direction="column" spacing={0} sx={{ textAlign: align }}>
                <Typography variant="body2" color="text.secondary" fontStyle="italic">
                    No {_.startCase(role)}
                </Typography>
            </Stack>
        );
    }

    return (
        <Stack direction="column" spacing={0} sx={{ textAlign: align }}>
            <Typography variant="body1">{_.get(primaryOrderMember, 'member.name', 'TBD')}</Typography>

            <Typography variant="body2" color="text.secondary">
                {assignmentRoleMembers.length > 1 && assignmentRoleMembers.length}{' '}
                {pluralize(_.startCase(role), assignmentRoleMembers.length)}
            </Typography>
        </Stack>
    );
};

AssignmentItemMemberRoleContent.propTypes = {
    assignmentMembers: PropTypes.array.isRequired,
    orderMemberMap: PropTypes.object.isRequired,
    role: PropTypes.string.isRequired,
    party: PropTypes.string.isRequired,
    align: PropTypes.oneOf(['left', 'right']),
};

export const AssignmentMemberRoleSnapshot = ({ assignmentMembers, orderMemberMap, invertAlignment = false }) => {
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
                flex: 2,
                maxWidth: '400px',
            }}
        >
            <Stack
                direction="row"
                justifyContent="flex-end"
                flex={1}
                sx={{
                    '& > *': {
                        marginLeft: invertAlignment ? 'inherit' : 'auto',
                        marginRight: invertAlignment ? 'auto' : 'inherit',
                    },
                }}
            >
                <AssignmentItemMemberRoleContent
                    assignmentMembers={assignmentMembers}
                    orderMemberMap={orderMemberMap}
                    role={ASSIGNMENT_MEMBER_ROLE.assignor}
                    party={ASSIGNMENT_MEMBER_PARTY.assignor}
                    align={invertAlignment ? 'left' : 'right'}
                />
            </Stack>

            <Stack direction="row" justifyContent="center" sx={{ flexShrink: 1 }}>
                <ArrowRightAltIcon sx={{ ml: 3, mr: 3 }} />
            </Stack>

            <Stack
                direction="row"
                justifyContent="flex-start"
                flex={1}
                sx={{
                    '& > *': {
                        marginLeft: invertAlignment ? 'auto' : 'inherit',
                        marginRight: invertAlignment ? 'inherit' : 'auto',
                    },
                }}
            >
                <AssignmentItemMemberRoleContent
                    assignmentMembers={assignmentMembers}
                    orderMemberMap={orderMemberMap}
                    role={ASSIGNMENT_MEMBER_ROLE.assignee}
                    party={ASSIGNMENT_MEMBER_PARTY.assignee}
                    align={invertAlignment ? 'right' : 'left'}
                />
            </Stack>
        </Stack>
    );
};

const AssignmentContractListItem = ({ orderId, assignment, orderMemberMap }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('lg'));

    const { data: assignmentMembers } = useGetAssignmentMembersQuery({ orderId, assignmentId: assignment.id });

    const handleNavigate = () => {
        navigate(`/order/${orderId}/assignment/${assignment.id}`);
    };

    // Wait for assignment members to load before rendering
    if (!assignmentMembers) {
        return null;
    }

    const actionButtonType = matches ? ACTION_BUTTON_TYPES.contained : ACTION_BUTTON_TYPES.icon;
    const actionButtonBuffer = matches ? '348px' : '88px'; // (24 + 300 + 24) vs. (24 + 40 + 24)

    return (
        <ListItem
            secondaryAction={<AssignmentLatestAction assignment={assignment} type={actionButtonType} />}
            sx={{
                '& .MuiListItemSecondaryAction-root': {
                    right: '24px',
                },
            }}
            disablePadding
        >
            <ListItemButton
                className="assignment-list-item"
                role={undefined}
                onClick={handleNavigate}
                sx={{
                    '&.assignment-list-item': {
                        paddingRight: actionButtonBuffer,
                    },
                }}
            >
                <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={3} sx={{ flex: 1 }}>
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        <Box sx={{ flex: 1, minWidth: '200px' }}>
                            <AssignmentStatusChip
                                status={assignment.current_status.status}
                                size="small"
                                // sx={{
                                //     height: '20px',
                                //     '& .MuiChip-label': {
                                //         fontSize: '0.7rem',
                                //         paddingLeft: '6px',
                                //         paddingRight: '6px',
                                //     },
                                // }}
                            />
                        </Box>
                    </Stack>

                    <AssignmentMemberRoleSnapshot
                        assignmentMembers={assignmentMembers}
                        orderMemberMap={orderMemberMap}
                    />

                    <AssignmentContractIconList assignment={assignment} assignmentMembers={assignmentMembers} />
                </Stack>
            </ListItemButton>
        </ListItem>
    );
};

AssignmentContractListItem.propTypes = {
    orderId: PropTypes.string.isRequired,
    assignment: PropTypes.object.isRequired,
    orderMemberMap: PropTypes.object.isRequired,
};

export default AssignmentContractListItem;
