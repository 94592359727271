import CommentIcon from '@mui/icons-material/Comment';
import DescriptionIcon from '@mui/icons-material/Description';
import PeopleIcon from '@mui/icons-material/People';
import TimelineIcon from '@mui/icons-material/Timeline';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Stack } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';

import Tab from 'components/common/styled/Tab';
import { StyledTabList as TabList } from 'components/common/styled/Tabs';
import SigningSessionDocumentList from './SigningSessionDocumentList';
import SigningSessionMemberList from './SigningSessionMemberList';
import SigningSessionNoteList from './SigningSessionNoteList';
import SigningSessionTimeline from './SigningSessionTimeline';

const SIGNING_SESSION_TAB_KEYS = {
    members: 'members',
    documents: 'documents',
    notes: 'notes',
    timeline: 'timeline',
};

const SIGNING_SESSION_TAB_MAP = {
    [SIGNING_SESSION_TAB_KEYS.members]: {
        title: 'Members',
        icon: PeopleIcon,
    },
    [SIGNING_SESSION_TAB_KEYS.documents]: {
        title: 'Documents',
        icon: DescriptionIcon,
    },
    [SIGNING_SESSION_TAB_KEYS.notes]: {
        title: 'Notes',
        icon: CommentIcon,
    },
    [SIGNING_SESSION_TAB_KEYS.timeline]: {
        title: 'Timeline',
        icon: TimelineIcon,
    },
};

const SigningSessionContentTabs = ({ orderId, parcel, members, closingMembers }) => {
    const [value, setValue] = useState(SIGNING_SESSION_TAB_KEYS.members);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box>
            <TabContext value={value}>
                <Stack direction="column" alignItems="flex-start" spacing={0}>
                    <Box sx={{ ml: 0, flex: 1, width: '100%', color: 'text.primary' }}>
                        <TabList
                            onChange={handleChange}
                            aria-label="icon tabs example"
                            orientation="horizontal"
                            textColor="inherit"
                            indicatorColor="text.primary"
                        >
                            {_.map(SIGNING_SESSION_TAB_MAP, (tab, key) => {
                                return (
                                    <Tab
                                        key={key}
                                        icon={<tab.icon />}
                                        value={key}
                                        aria-label={tab.title}
                                        secondaryColor="text.secondary"
                                    />
                                );
                            })}
                        </TabList>
                    </Box>
                    <Box sx={{ flex: 1, pt: 2, width: '100%' }}>
                        <TabPanel value={SIGNING_SESSION_TAB_KEYS.members} sx={{ p: 0 }}>
                            <SigningSessionMemberList
                                orderId={orderId}
                                parcel={parcel}
                                members={members}
                                closingMembers={closingMembers}
                            />
                        </TabPanel>
                        <TabPanel value={SIGNING_SESSION_TAB_KEYS.documents} sx={{ p: 0 }}>
                            <SigningSessionDocumentList orderId={orderId} parcel={parcel} />
                        </TabPanel>
                        <TabPanel value={SIGNING_SESSION_TAB_KEYS.notes} sx={{ p: 0 }}>
                            <SigningSessionNoteList orderId={orderId} parcel={parcel} />
                        </TabPanel>

                        <TabPanel value={SIGNING_SESSION_TAB_KEYS.timeline} sx={{ p: 0 }}>
                            <SigningSessionTimeline orderId={orderId} parcel={parcel} />
                        </TabPanel>
                    </Box>
                </Stack>
            </TabContext>
        </Box>
    );
};

SigningSessionContentTabs.propTypes = {
    orderId: PropTypes.string.isRequired,
    parcel: PropTypes.object.isRequired,
    members: PropTypes.array.isRequired,
    closingMembers: PropTypes.array.isRequired,
};

export default SigningSessionContentTabs;
